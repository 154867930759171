import React, { useEffect, useState } from "react";
import "../../css/orgadmin.css";
import "../../css/HRPortal.css";
import host from "../../global";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate } from "react-router-dom";


function App() {
  const [jobrole, setjobrole] = useState("");
  const [questions, setQuestions] = useState([]);
  const [showInterviewConfig, setShowInterviewConfig] = useState(false);
  const [rolesResponsibilities, setRolesResponsibilities] = useState("");
  const [skills, setSkills] = useState([""]);
  const [generatedJD, setGeneratedJD] = useState("");
  const [suggestedSkills, setSuggestedSkills] = useState([]); // eslint-disable-next-line
  const [analyzed, setAnalyzed] = useState(false); // eslint-disable-next-line
  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [yearsOfExperience, setYearsOfExperience] = useState("");
  const [jdSkills, setJdSkills] = useState([]);
  const [skillPriorities, setSkillPriorities] = useState({});
  const [easySkill, setEasySkill] = useState("");
  const [mediumSkill, setMediumSkill] = useState("");
  const [hardSkill, setHardSkill] = useState("");
  const [interview_timelimit, setInterviewtimelimit] = useState(0);
  const [isMCQIncluded, setIsMCQIncluded] = useState(false);
  const [isCodingIncluded, setIsCodingIncluded] = useState(false);
  const [MCQTimeLimit, setMCQTimeLimit] = useState(0);
  const [CodingTimeLimit, setCodingTimeLimit] = useState(0);
  const [CodingJudgingCriteria, setCodingJudgingCriteria] = useState("NA");
  const [selectedSet, setSelectedSet] = useState("");
  const [selectedCodingSet, setSelectedCodingSet] = useState("");
  const [sets, setSets] = useState([]);
  const [codingSets, setCodingSets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const accessToken = Cookies.get("accessToken");
  const [isCustomQuestionsIncluded, setIsCustomQuestionsIncluded] =
    useState(false);
  const [customQuestions, setCustomQuestions] = useState("");
  const [editedQuestions, setEditedQuestions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCombinedData = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(`${host}/orgadmin/get_jd_data`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setSets(data.set_names);
        setCodingSets(Object.keys(data.coding_sets));
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove("accessToken");
              resolve();
            });
            toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
          } else {
            toast.error(`Error: ${detail}`);
          }
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
        setIsLoading(false);
      }
    };

    fetchCombinedData();
  }, [accessToken]);

  const handleNumberChange = (e) => {
    setInterviewtimelimit(e.target.value);
  };

  const handleIncludeMCQ = () => {
    if (isMCQIncluded) {
      setIsMCQIncluded(false);
      setSelectedSet("NA");
      setMCQTimeLimit(0);
    } else {
      setIsMCQIncluded(true);
    }
  };

  const handleSetChange = (e) => {
    setSelectedSet(e.target.value);
  };

  const handleMCQTimeLimitChange = (e) => {
    setMCQTimeLimit(e.target.value);
  };

  const handleIncludeCoding = () => {
    if (isCodingIncluded) {
      setIsCodingIncluded(false);
      setSelectedCodingSet("NA");
      setCodingJudgingCriteria("NA");
      setCodingTimeLimit(0);
    } else {
      setIsCodingIncluded(true);
    }
  };

  const handleShowInterviewConfig = () => {
    setShowInterviewConfig(true);
  };

  const handleCodingTimeLimitChange = (e) => {
    setCodingTimeLimit(e.target.value);
  };

  const handleCodingJudgingCriteriaChange = (e) => {
    setCodingJudgingCriteria(e.target.value);
  };

  const handleAddSkills = () => {
    if (easySkill) {
      generatedJD.Required_Skills.Easy.push(easySkill);
      setEasySkill(""); // Clear the input
    }

    if (mediumSkill) {
      generatedJD.Required_Skills.Medium.push(mediumSkill);
      setMediumSkill(""); // Clear the input
    }

    if (hardSkill) {
      generatedJD.Required_Skills.Hard.push(hardSkill);
      setHardSkill(""); // Clear the input
    }
  };

  const handlePriorityChange = (skill, priority) => {
    setSkillPriorities((prev) => ({
      ...prev,
      [skill]: priority,
    }));
  };

  const handleIncludeCustomQuestions = () => {
    setIsCustomQuestionsIncluded(!isCustomQuestionsIncluded);
  };

  const handleCustomQuestionsChange = (event) => {
    setCustomQuestions(event.target.value);
  };

  const handleQuestionChange = (index, newValue) => {
    const updatedQuestions = [...editedQuestions];
    updatedQuestions[index] = newValue;
    setEditedQuestions(updatedQuestions);

    const updatedOriginalQuestions = [...questions];
    updatedOriginalQuestions[index] = newValue;
    setQuestions(updatedOriginalQuestions);
  };

  const handleSaveCustomQuestions = async () => {
    try {
      const response = await axios.post(
        `${host}/upload-custom-questions/`,
        {
          questions: customQuestions,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data.message);
      setQuestions(response.data.questions);
      setEditedQuestions(response.data.questions);
      setQuestions(response.data.questions);
    } catch (error) {
      console.error("Error saving custom questions:", error);
      alert("Failed to save custom questions. Please try again.");
    } finally {
    }
  };

  const handleAnalyze = async () => {
    console.log(jobrole);
    console.log(yearsOfExperience);
    setLoading(true);
    const dataToSend = {
      jobrole,
      yearsOfExperience,
      skills: [...skills],
      topics,
      rolesResponsibilities,
    };

    try {
      const response = await fetch(`${host}/analyze`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend),
      });

      const result = await response.json();
      console.log(result);
      if (response.ok) {
        setSuggestedSkills(result.skills);
        setJdSkills(result.skills_mentioned_in_JD);
        const defaultPriorities = result.skills_mentioned_in_JD.reduce(
          (acc, skill) => {
            acc[skill] = "Required";
            return acc;
          },
          {}
        );

        setSkillPriorities(defaultPriorities);
        setAnalyzed(true);
      } else {
        console.error("Error in analyzing data:", result);
        toast.success(
          result.message || "Something went wrong while analyzing."
        );
        setAnalyzed(false);
      }
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
      console.error("Error sending data to backend:", error);
      toast.success("Failed to connect to the server. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateJD = async () => {
    const skills_list = {
      Hard: [],
      Medium: [],
      Easy: [],
    };

    const experienceThreshold = 4;
    const isFresher = yearsOfExperience === 0;
    console.log("Skill Priorities:", skillPriorities);
    console.log("Years of Experience:", yearsOfExperience);
    Object.entries(skillPriorities).forEach(([skill, priority]) => {
      console.log(`Processing skill: ${skill}, priority: ${priority}`); // Debugging line
      if (isFresher) {
        // Treat as a fresher: all required skills will go to Easy
        if (priority.toLowerCase() === "required") {
          skills_list.Easy.push(skill);
        }
      } else if (yearsOfExperience < experienceThreshold) {
        if (priority.toLowerCase() === "required") {
          skills_list.Medium.push(skill);
        } else if (priority.toLowerCase() === "optional") {
          skills_list.Easy.push(skill);
        }
      } else {
        if (priority.toLowerCase() === "required") {
          skills_list.Hard.push(skill);
        } else if (priority.toLowerCase() === "optional") {
          skills_list.Medium.push(skill);
        }
      }
    });

    const skills_required = {
      Easy: skills_list.Easy,
      Medium: skills_list.Medium,
      Hard: skills_list.Hard,
    };

    const jd = `
  jobrole: ${jobrole}
  Years of Experience: ${yearsOfExperience}
  Skills Required:${JSON.stringify(skills_required)}
  `;

    const dataToSend = {
      description: jd,
    };
    try {
      toast.success("Job description successfully sent for fine-tuning!");
      setIsLoading(true);
      const response = await fetch(`${host}/orgadmin/fineTuneJD`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend),
      });
      const result = await response.json();
      console.log(result.fineTunedJD);
      setGeneratedJD(result.fineTunedJD);
      setIsLoading(false);
      if (!response.ok) {
        toast.error(
          result.message || "Something went wrong with the fine-tuning process."
        );
      }
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }

      console.error("Error connecting to the server:", error);
      toast.success("Failed to connect to the server. Please try again.");
    }
  };

  const handleDeleteSuggestedSkill = (skillToDelete) => {
    setSuggestedSkills(
      suggestedSkills.filter((skill) => skill !== skillToDelete)
    );
  };
  const handleAcceptSuggestedSkill = (skill) => {
    setSkills([...skills, skill]);
    setJdSkills([...jdSkills, skill]);
    handleDeleteSuggestedSkill(skill);
    handlePriorityChange(skill, "Required");
  };

  const handleUpdateJD = async () => {
    const dataToSend = {
      description: {
        jobrole: generatedJD.jobrole || "pls add jobrole",
        Experience_Level:
          generatedJD.Experience_Level || "Experience level not specified",
        Required_Skills: {
          Easy: generatedJD.Required_Skills?.Easy || [],
          Medium: generatedJD.Required_Skills?.Medium || [],
          Hard: generatedJD.Required_Skills?.Hard || [],
        },
        GoodtoHave: generatedJD.GoodtoHave || [],
        Tools_and_Technologies: generatedJD["Tools & Technologies"] || [],
        selectedInterviewType: "Technical Interview",
        interview_timelimit: interview_timelimit || 0,
        selectedSet: selectedSet || "NA",
        MCQTimeLimit: MCQTimeLimit || 0,
        selectedCodingSet: selectedCodingSet || "NA",
        CodingTimeLimit: CodingTimeLimit || 0,
        CodingJudgingCriteria: CodingJudgingCriteria || "NA",
        customQuestions: questions || [],  
      },
    };

    try {
      setIsLoading(true);
      const response = await fetch(`${host}/StoreIntervieConfig-SkillTopics`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend),
      });
      setIsLoading(false);
      const result = await response.json();
      if (!response.ok) {
        const errorMessage = result.message || (await response.text());
        console.error(
          "Error updating JD:",
          errorMessage,
          "Status Code:",
          response.status
        );

        alert(
          errorMessage ||
            "Something went wrong while updating the job description."
        );
      } else {
        console.log("Success response:", result);
        toast.success("Job description updated successfully.");
        setTimeout(() => {
          window.location.reload();
          navigate(`/org-admin/created-jds`)
        }, 2000);
      }
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
      console.error("Error connecting to the server:", error);
      toast.success("Failed to connect to the server. Please try again.");
    }
  };

  if (isLoading) {
    return <div className="loader"></div>;
  }

  const handleUpdateAllQuestions = () => {
    const updatedQuestions = [...editedQuestions];
    setQuestions(updatedQuestions); 
    console.log("Updated Questions:", questions);
    alert("All questions updated successfully!"); 
  };


  return (
    <div className="JDApp">
      <h1>Set Up Job Opening</h1>
      <div className="main-container">
          <div className="input-container">
            <div className="input-group">
              <label>Role:</label>
              <input
                type="text"
                value={jobrole}
                onChange={(e) => setjobrole(e.target.value)}
                placeholder="Enter jobrole..."
              />
            </div>
            <div className="input-group">
              <label htmlFor="years-experience">Years of Experience:</label>
              <input
                id="years-experience"
                type="number"
                value={yearsOfExperience}
                onChange={(e) => setYearsOfExperience(e.target.value)}
                placeholder="Enter years of experience..."
              />
            </div>
            <div className="input-group">
              <label>Upload Job Description:</label>
              <textarea
                className="roles-input"
                value={rolesResponsibilities}
                onChange={(e) => setRolesResponsibilities(e.target.value)}
                placeholder="Type roles and responsibilities..."
              />
            </div>

            <div className="action-buttons">
              <button
                className="submit-button"
                onClick={handleAnalyze}
                disabled={loading}
              >
                {loading ? "Analyzing..." : "Analyze"}
              </button>
              {loading && <div className="loading-spinner"></div>}
            </div>
          </div>
      
        {Array.isArray(jdSkills) && jdSkills.length > 0  && (
          <div className="suggested-skills">
            <h2>Skills Highlighted in Job Posting:</h2>
            {jdSkills.length > 0 ? (
              <div className="skillsJD-container">
                {jdSkills.map((skill, index) => (
                  <div key={index} className="skill-JD-badge">
                    <span>{skill}</span>
                    <div className="priority-radio-group">
                      <label>
                        <input
                          type="radio"
                          name={`priority-${skill}`}
                          value="Optional"
                          checked={skillPriorities[skill] === "Optional"}
                          onChange={() =>
                            handlePriorityChange(skill, "Optional")
                          }
                        />
                        Optional
                      </label>
                      <label>
                        <input
                          type="radio"
                          name={`priority-${skill}`}
                          value="Required"
                          checked={skillPriorities[skill] === "Required"}
                          onChange={() =>
                            handlePriorityChange(skill, "Required")
                          }
                        />
                        Required
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p>No skills were mentioned in the job description.</p>
            )}
            <button className="generate-button" onClick={handleGenerateJD}>
              View Job Description
            </button>
          </div>
        )}
      </div>
      <div>
        {suggestedSkills.length > 0 && (
          <div className="skills-container">
            <h2>Recommended Skills:</h2>
            {suggestedSkills.map((skill, index) => (
              <div key={index} className="skill-badge">
                {skill}
                <button
                  className="skill-badge-btn accept-btn"
                  onClick={() => handleAcceptSuggestedSkill(skill)}
                >
                  ✔️
                </button>
                <button
                  className="skill-badge-btn delete-btn"
                  onClick={() => handleDeleteSuggestedSkill(skill)}
                >
                  ❌
                </button>
              </div>
            ))}
          </div>
        )}
        {generatedJD && (
          <div className="generated-jd">
            <h2>Quick JD Preview:</h2>
            <div className="jd-container">
              <pre className="non-editable-jd">
                {`jobrole: ${
                  generatedJD.jobrole || "jobrole not specified"
                }\n\n` +
                  `Experience Level: ${
                    generatedJD.Experience_Level ||
                    "Experience level not specified"
                  } ${yearsOfExperience === 0 ? "(Fresher)" : ""}\n\n` +
                  `Required Skills:\n` +
                  `  Easy: ${
                    Array.isArray(generatedJD.Required_Skills?.Easy)
                      ? generatedJD.Required_Skills.Easy.join(", ")
                      : "None"
                  }\n` +
                  `  Medium: ${
                    Array.isArray(generatedJD.Required_Skills?.Medium)
                      ? generatedJD.Required_Skills.Medium.join(", ")
                      : "None"
                  }\n` +
                  `  Hard: ${
                    Array.isArray(generatedJD.Required_Skills?.Hard)
                      ? generatedJD.Required_Skills.Hard.join(", ")
                      : "None"
                  }\n\n` +
                  `Good to Have:\n` +
                  `  ${
                    Array.isArray(generatedJD.GoodtoHave)
                      ? generatedJD.GoodtoHave.join(", ")
                      : "None"
                  }\n\n` +
                  `Tools & Technologies:\n` +
                  `  ${
                    Array.isArray(generatedJD["Tools & Technologies"])
                      ? generatedJD["Tools & Technologies"].join(", ")
                      : "None"
                  }`}
              </pre>
            </div>
            <h3>Add New Skills:</h3>
            <div>
              <pre className="input-container">
                <input
                  type="text"
                  placeholder="Add Easy Skill"
                  value={easySkill}
                  onChange={(e) => setEasySkill(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Add Medium Skill"
                  value={mediumSkill}
                  onChange={(e) => setMediumSkill(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Add Hard Skill"
                  value={hardSkill}
                  onChange={(e) => setHardSkill(e.target.value)}
                />
                <button onClick={handleAddSkills}>Add Skills</button>
              </pre>
            </div>

            <button
              className="configure-button"
              onClick={handleShowInterviewConfig}
            >
              Configure Interview
            </button>
            {showInterviewConfig && (
              <div className="interview-config-container">
                <h3>Interview Configuration:</h3>
                <div className="config-section">
                  <label>Type of Interview: </label>
                  <span className="interview-type-display">
                    Technical Interview
                  </span>
                </div>
                {/* Interview Time Limit Section */}
                <div className="config-section">
                  <label>Interview Time Limit (Mins):</label>
                  <input
                    type="number"
                    min="1"
                    placeholder="0"
                    value={interview_timelimit}
                    onChange={handleNumberChange}
                    required
                  />
                </div>

                {/* Upload Custom Interview Questions Section */}
                <div className="config-section checkbox-container">
                  <div>
                    <input
                      type="checkbox"
                      checked={isCustomQuestionsIncluded}
                      onChange={handleIncludeCustomQuestions}
                    />
                    <label>Upload Custom Interview Questions</label>
                  </div>

                  {isCustomQuestionsIncluded && (
                    <div className="custom-questions-container">
                      <textarea
                        placeholder="Enter custom interview questions here..."
                        value={customQuestions}
                        onChange={handleCustomQuestionsChange}
                        rows="5"
                        className="custom-questions-textarea"
                      ></textarea>
                      <button
                        onClick={handleSaveCustomQuestions}
                        className="save-questions-button"
                      >
                        Save Questions
                      </button>
                      {isLoading && <p>Loading...</p>}

                      <ul style={{ listStyleType: "none", padding: 0 }}>
                        {questions.map((question, index) => (
                          <li key={index} style={{ marginBottom: "10px" }}>
                            <input
                              value={question}
                              onChange={(e) =>
                                handleQuestionChange(index, e.target.value)
                              }
                              rows={3}
                              style={{
                                width: "100%",
                                padding: "5px",
                                borderRadius: "5px",
                                border: "1px solid #ddd",
                                fontSize: "16px",
                              }}
                            />
                          </li>
                        ))}
                      </ul>
                      <button
                        onClick={handleUpdateAllQuestions} 
                        className="update-questions-button"
                      >
                        Update All Questions
                      </button>
                    </div>
                  )}
                </div>

                <hr />
                <div className="checkbox-container">
                  {/* Include MCQ Test Section */}
                  <div className="config-section checkbox-container">
                    <div>
                      <input
                        type="checkbox"
                        checked={isMCQIncluded}
                        onChange={handleIncludeMCQ}
                      />
                      <label>Include MCQ Test</label>
                    </div>
                    {isMCQIncluded && (
                      <>
                        <div className="mcq-config">
                          <div className="config-section">
                            <label>Select Set For MCQ Test:</label>
                            <select
                              value={selectedSet}
                              onChange={handleSetChange}
                              required
                            >
                              <option>Select a set</option>
                              {sets.map((setName, index) => (
                                <option key={index}>{setName}</option>
                              ))}
                            </select>
                          </div>
                          <div className="config-section">
                            <label>Set MCQ Time Limit (Mins):</label>
                            <input
                              type="number"
                              value={MCQTimeLimit}
                              onChange={handleMCQTimeLimitChange}
                              required
                            />
                          </div>
                        </div>
                        <hr />
                      </>
                    )}{" "}
                  </div>

                  {/* Include Coding Test Section */}
                  <div className="config-section checkbox-container">
                    <div>
                      <input
                        type="checkbox"
                        checked={isCodingIncluded}
                        onChange={handleIncludeCoding}
                      />
                      <label>Include Coding Test</label>
                    </div>
                    {isCodingIncluded && (
                      <>
                        <div className="coding-config">
                          <div className="config-section">
                            <label>Select Coding Set:</label>
                            <select
                              value={selectedCodingSet}
                              onChange={(e) =>
                                setSelectedCodingSet(e.target.value)
                              }
                              required
                            >
                              <option value="">Select Set</option>
                              {codingSets.map((setName, index) => (
                                <option key={index} value={setName}>
                                  {setName}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="config-section">
                            <label>Set Coding Time Limit (Mins):</label>
                            <input
                              type="number"
                              min="1"
                              placeholder="0"
                              value={CodingTimeLimit}
                              onChange={handleCodingTimeLimitChange}
                              required
                            />
                          </div>{" "}
                          <div className="config-section">
                            <label>Set Coding Judging Criteria:</label>
                            <select
                              value={CodingJudgingCriteria}
                              onChange={handleCodingJudgingCriteriaChange}
                              required
                            >
                              <option value="">Select Criteria</option>
                              <option value="0">Judge 0</option>
                              <option value="1">Judge 1</option>
                              <option value="2" disabled>
                                Judge 2
                              </option>
                              <option value="3" disabled>
                                Judge 3
                              </option>
                              <option value="4" disabled>
                                Judge 4
                              </option>
                              <option value="5" disabled>
                                Master Judge
                              </option>
                            </select>
                          </div>
                        </div>
                        <hr />
                      </>
                    )}
                  </div>
                </div>
                <div>
                  <button className="update-button" onClick={handleUpdateJD}>
                    Save And Next
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
export default App;
