import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Authenticator } from '@aws-amplify/ui-react';
import host from '../../global';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

const Actions = () => {
    const [topics, setTopics] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [questionsPerTopic, setQuestionsPerTopic] = useState({});
    const [setName, setSetName] = useState('');
    const [areTopicsLoading, setAreTopicsLoading] = useState(false);
    const accessToken = Cookies.get("accessToken")

    useEffect(() => {
        const fetchTopics = async () => {
            try {
                setAreTopicsLoading(true);
                const response = await axios.get(`${host}/get_mcq_topics`,{
                  headers:{
                    Authorization: `Bearer ${accessToken}`
                  }
                });
                setTopics(response.data);
                setAreTopicsLoading(false);
            } catch (error) {
                if (error.response) {
                    const status = error.response.status;
                    const detail = error.response.data.detail;
                    if (status === 401) {
                        await new Promise((resolve) => {
                            Cookies.remove('accessToken');
                            resolve();
                        });
                        toast.error(`Unauthorized: ${detail}`);
                    } else if (status === 404) {
                        toast.warning(`Not Found: ${detail}`);
                    } else {
                        toast.error(`Error: ${detail}`);
                    }
                } else {
                    toast.error('An unexpected error occurred. Please try again.');
                }
            }
        };
        fetchTopics();
    }, [accessToken]);

    const handleInputChange = (topic, value) => {
        setQuestionsPerTopic((prev) => ({
            ...prev,
            [topic]: parseInt(value, 10),
        }));
    };

    const handleSetChange = (e) => {
        setSetName(e.target.value);
    };

    const handleSaveSet = async () => {
        try {
          const res = await axios.post(
            `${host}/create_mcq_set`, 
            { data: { data: questions } },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`
              },
              params: {
                setName: setName
              }
            }
          );      
            if (res) {
                toast.success("Data Inserted");
            }
        } catch (e) {
            console.error('There was an error!', e);
            toast.error('Error saving set');
        }
    };

    const handleFetchQuestions = async () => {
        try {
            const topicCounts = Object.entries(questionsPerTopic)
                .filter(([topic, count]) => count > 0)
                .map(([topic, count]) => ({ topic, count }));

                const response = await axios.post(
                  `${host}/fetchmcq`, 
                  topicCounts,
                  {
                    headers: {
                      Authorization: `Bearer ${accessToken}`
                    }
                  }
                );                
            setQuestions(response.data);
        } catch (error) {
            console.error('Error fetching questions', error);
            toast.error('Error fetching questions');
        }
    };

    return (
        <Authenticator>
            <div className="container p-4">
                <div>
                    {areTopicsLoading ? (
                        <div className='loader'></div>
                    ) : (
                        <div>
                            <h1 className="text-2xl font-bold mb-4">Fetch Random MCQs</h1>
                            <div style={{display:'flex',justifyContent:"space-evenly",gap:'20px',margin:'20px'}}>
                                {topics.map((topic) => (
                                    <div key={topic} className='flex items-center space-x-2'>
                                        <div className="text-lg">{topic}</div>
                                        <input
                                            type="number"
                                            min="0"
                                            max="10"
                                            onChange={(e) => handleInputChange(topic, e.target.value)}
                                            className="border border-gray-300 rounded p-2 w-16"
                                            aria-label={`Number of Questions for ${topic}`}
                                        />
                                    </div>
                                ))}
                            </div>
                            <button 
                                onClick={handleFetchQuestions} 
                                className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                            >
                                Fetch Questions
                            </button>
                            <div className="mt-6">
                                {questions.length > 0 && (
                                    <div className='saveAsSet mb-4'>
                                        <label className="mr-2">Save As Set:</label>
                                        <input 
                                            placeholder='Set Name' 
                                            value={setName} 
                                            onChange={handleSetChange}
                                            className="border border-gray-300 rounded p-2 mr-2"
                                        />
                                        <button 
                                            onClick={handleSaveSet} 
                                            className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600"
                                        >
                                            Save
                                        </button>
                                    </div>
                                )}
                                {questions.map((question, index) => (
                                    <div key={index} className="question border p-4 rounded mb-4">
                                        <h3 className="text-lg font-semibold">{question.question}</h3>
                                        <ul className="list-none pl-5">
                                            <li>a. {question.option1}</li>
                                            <li>b. {question.option2}</li>
                                            <li>c. {question.option3}</li>
                                            <li>d. {question.option4}</li>
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Authenticator>
    );
};

export default Actions;
