import React, { useEffect, useRef, useState } from "react";
import { Authenticator } from "@aws-amplify/ui-react";
import host from "../../global";
import { getCurrentUser } from "aws-amplify/auth";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import Cookies from 'js-cookie';
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const profile_photos_host = process.env.REACT_APP_PROFILE_PHOTOS_HOST;
const EditProfile = () => {
  const fileInputRef = useRef(null);
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [profileData, setProfileData] = useState({
    name: "",
    email: "",
    contact: "",
    profileImage: "",
    profileImageFile: null,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const accessToken = Cookies.get('accessToken');

  useEffect(() => {
    if (shouldSubmit) {
      handleSubmit();
      setShouldSubmit(false);
    }// eslint-disable-next-line
  }, [shouldSubmit,accessToken]);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(`${host}/gethrdetails`,{
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
          const data = await response.json();
          const { userId } = await getCurrentUser();
          setProfileData({
            name: data.name,
            email: data.email,
            contact: data.contact,
            profileImage: data.profile ? `${profile_photos_host}/HRProfiles/${userId}_${data.profile}` : "",
          });  
          setIsLoading(false);
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
              await new Promise((resolve) => {
                Cookies.remove('accessToken');
                resolve();
              });
              toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
              toast.warning(`Not Found: ${detail}`);
          } else {
              toast.error(`Error: ${detail}`);
          }
      } else {
          toast.error('An unexpected error occurred. Please try again.');
      }
      }
    };
    fetchProfileData();
  }, [accessToken]);

  const handleChange = (e) => {
    const { name, files } = e.target;
    
    if (name === "profileImage" && files.length > 0) {
      const file = files[0];
  
      const validImageTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (!validImageTypes.includes(file.type)) {
        setMessage("Invalid file type. Please upload a PNG, JPG, or JPEG image.");
        return;
      }
  
      // Check for file size (max 1MB)
      const maxSize = 1 * 1024 * 1024;
      if (file.size > maxSize) {
        setMessage("File size exceeds 1MB. Please upload a smaller image.");
        return;
      }
  
      const imageUrl = URL.createObjectURL(file);
      console.log(imageUrl)
      setProfileData((prevData) => ({
        ...prevData,
        profileImage: imageUrl,
        profileImageFile: file,
      }));
  
      setIsLoading(true);
      setShouldSubmit(true);
    } else {
      setProfileData((prevData) => ({
        ...prevData,
        [name]: e.target.value,
      }));
    }
  };
  
  const handleDeleteImage = () => {
    setProfileData((prevData) => ({
      ...prevData,
      profileImage: "",
      profileImageFile: null,
      deleteProfileImage: true,
    }));
    setIsLoading(true);
    setShouldSubmit(true);
  };

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("hrName", profileData.name);
    formData.append("hremail", profileData.email);
    formData.append("contactNumber", profileData.contact);
    if (profileData.profileImageFile) {
      formData.append("profile", profileData.profileImageFile);
    }
    if (profileData.deleteProfileImage) {
      formData.append("deleteProfileImage", "true");
    }
    console.log(profileData);
    console.log(formData);
    try {
      const response = await fetch(`${host}/hr/updateprofile`, {
        method: "PUT",
        body: formData,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });      

      if (response.ok) {
        toast.success("Profile updated successfully!");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        const errorData = await response.json();
        console.error("Error details:", errorData);
        toast.error(
          "Failed to update profile. " +
            errorData.detail.map((err) => err.msg).join(", ")
        );
      }
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove('accessToken');
              resolve();
            });
            toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
        } else {
            toast.error(`Error: ${detail}`);
        }
    } else {
        toast.error('An unexpected error occurred. Please try again.');
    }    }

    setIsSubmitting(false);
  };

  const handleEditProfile = () => {
    fileInputRef.current.click();
  };

  const handleImageClick = () => {
    setIsModalOpen(true);
  };

  if (isLoading) {
    return <div class="loader">
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
</div>
  }

  return (
    <Authenticator>
      <div>
        {isLoading ? (
          <div class="loader">
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
</div>
        ) : (
          <div className="edit-profile">
            <div className="edit-profile-container">
              <h2>Edit Profile</h2>
              <form onSubmit={handleSubmit}>
                <div className="profile-img-container">
                  {profileData.profileImage ? (
                    <img
                      src={profileData.profileImage}
                      alt="Profile"
                      className="profile-img"
                      onClick={handleImageClick}
                    />
                  ) : (
                    <div
                      onClick={handleImageClick}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100px",
                        height: "100px",
                        border: "solid 1px black",
                        borderRadius: "50%",
                        overflow: "hidden",
                        padding: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <PersonAddAltRoundedIcon style={{ fontSize: "70px" }} />
                    </div>
                  )}
                  <input
                    type="file"
                    name="profileImage"
                    accept="image/*"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleChange}
                  />
                </div>

                <Dialog
                  open={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                >
                  <DialogTitle>Profile Picture</DialogTitle>
                  <DialogContent>
                    {profileData.profileImage ? (
                      <img
                        src={profileData.profileImage}
                        alt="Profile"
                        style={{
                          width: "45vw",
                          maxWidth: "150px",
                          height: "auto",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <AccountCircleRoundedIcon
                        style={{
                          width: "45vw",
                          maxWidth: "150px",
                          height: "auto",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                      />
                    )}
                  </DialogContent>
                  <DialogActions
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <IconButton
                      onClick={() => {
                        handleEditProfile();
                        setIsModalOpen(false);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={handleDeleteImage}>
                      <DeleteIcon />
                    </IconButton>
                  </DialogActions>
                </Dialog>
                <div>
                  <label>Name:</label>
                  <input
                    type="text"
                    name="name"
                    value={profileData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <label>Email:</label>
                  <input
                    type="email"
                    name="email"
                    value={profileData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <label>Contact:</label>
                  <input
                    type="text"
                    name="contact"
                    value={profileData.contact}
                    onChange={handleChange}
                    required
                  />
                </div>
                <button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? "Updating..." : "Update Profile"}
                </button>
              </form>
              {message && <p>{message}</p>}
            </div>
          </div>
        )}
      </div>
    </Authenticator>
  );
};

export default EditProfile;
