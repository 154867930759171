import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import host from '../global.jsx';
import ConfirmationNumberRoundedIcon from '@mui/icons-material/ConfirmationNumberRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import {Notifications, PendingActions, ExitToApp } from '@mui/icons-material';
import { signOut } from "aws-amplify/auth";
import { Edit } from '@mui/icons-material';
import { toast,ToastContainer } from 'react-toastify';

const GenieHireAdminDashboard = () => {
    const navigate = useNavigate();
    const { admin_id } = useParams();
    const [formVisible, setFormVisible] = useState(false);
    const [editForm, setEditForm] = useState(false);
    const [viewOrgDetails, setViewOrgDetails] = useState(false);
    const [formData, setFormData] = useState({
        organization_name: '',
        organization_industry: '',
        organization_email: '',
        org_admin_id: '',
        org_admin_name: '',
        org_admin_email: '',
        org_admin_contact: '',
        total_hr_allocated: ''
    });
    const [adminName, setAdminName] = useState('');
    const [adminEmail, setAdminEmail] = useState('');
    const [adminContact, setAdminContact] = useState('');
    const [data, setData] = useState([]);
    const [orgData, setOrgData] = useState(null);
    const [editOrgData, setEditOrgData] = useState(null);
    const [editOrgId, setEditOrgId] = useState('');
    const [showPending, setShowPending] = useState(false);
    const [PendingProfiles, setPendingProfiles] = useState(null);
    const [showDemo, setShowDemo] = useState(false);
    const [DemoProfiles, setDemoProfiles] = useState(null);
    const [viewDemoDetails, setViewDemoDetails] = useState(false);
    const [demoOrgData, setDemoOrgData] = useState(false);
    const [TotalHRCount, setTotalHRCount] = useState(null);
    const [TicketsRaised, setTicketsRaised] = useState(null);
    const [showTickets, setShowTickets] = useState(false);
    const [TicketDetails, setTicketDetails] = useState(null);
    const [viewTicket, setViewTicket] = useState(false);
    const [ticketRemark, setTicketRemark] = useState('');
    const [interviewCount, setInterviewCount] = useState(0);
    const [isAddingCompany,setisAddingCompany] = useState(false);

    useEffect(() => {
        const updateDashboardData = async () => {
            await displayConvAIAdminData();
            await getDashCounts();
            await getOrgAdminsData();
            await getPendingProfilesData();
            await getDemoProfilesData();
            await fetchRaisedTicketsData();
        };
        updateDashboardData();    // eslint-disable-next-line
    }, []);

    const getDashCounts = async () => {
        try {
            const response = await axios.get(`${host}/get-dashboard-counts/${admin_id}`);
            if(response){
                setTotalHRCount(response.data.total_hr);
                setInterviewCount(response.data.total_interviews_count);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const displayConvAIAdminData = async () => {
        try {
            const response = await axios.get(`${host}/get_convai_admin_details/${admin_id}`);
            if(response){
                setAdminName(response.data.admin_name);
                setAdminEmail(response.data.admin_email);
                setAdminContact(response.data.admin_contact);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const getOrgAdminsData = async () => {
        try {
            const response = await axios.get(`${host}/getAllOrgAdmins/${admin_id}`);
            if(response){
                setData(response.data);
            }                
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const getPendingProfilesData = async () => {
        try {
            const response = await axios.get(`${host}/org-admins/${admin_id}`);
            if(response){
                setPendingProfiles(response.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const getDemoProfilesData = async () => {
        try {
            const response = await axios.get(`${host}/get-demo-requests`);
            if(response){
                setDemoProfiles(response.data);  
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const fetchRaisedTicketsData = async () => {
        try {
            const response = await axios.get(`${host}/get-raised-tickets/${admin_id}`);
            if(response){
                setTicketsRaised(response.data);  
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const handleFormChange = (event) => {
        const { name, value } = event.target;
        setFormData({
          ...formData,
          [name]: value
        });
    };

    const handleTicketRemarkChange = (e) => {
        setTicketRemark(e.target.value);
    }
    
    const handleAddCompany = () => {
      setisAddingCompany(true)
        setFormData({
            organization_name: '',
            organization_industry: '',
            organization_email: '',
            org_admin_id: '',
            org_admin_name: '',
            org_admin_email: '',
            org_admin_contact: '',
            total_hr_allocated: ''
        });
        setFormVisible(true);
    }

    const handleFormSubmit = async (e) => {
      e.preventDefault();
      try {
          await axios.post(`${host}/create_new_org_admin/${admin_id}`, formData, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          });
          toast.success("New Organization Added Successfully!");
          setFormData({
              organization_name: '',
              organization_industry: '',
              organization_email: '',
              org_admin_id: '',
              org_admin_name: '',
              org_admin_email: '',
              org_admin_contact: '',
              total_hr_allocated: ''
          });
          setFormVisible(false);
          setTimeout(() => {
            window.location.reload()
          }, 2000);
      } catch (error) {
          toast.error("Error: ", error);
      }
  }

    const hideForm = () => {
        setFormVisible(false);
    }

    const handleFormUpdate = async (e) => {
        e.preventDefault();
        setisAddingCompany(false);
        try {
            await axios.post(`${host}/update_org_data/${editOrgId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            toast.success("Organization Data Successfully Updated!");
            setTimeout(() => {
                setEditForm(false);
            }, 1200);
            setFormData({
                organization_name: '',
                organization_industry: '',
                organization_email: '',
                org_admin_id: '',
                org_admin_name: '',
                org_admin_email: '',
                org_admin_contact: '',
                total_hr_allocated: ''
            });
        } catch (error) {
            console.error("Error: ", error);
        }
    }

    const showOrgData = async (org_id) => {
        try {
            const response = await axios.get(`${host}/getCompanyData/${org_id}`);
            if(response){
                setOrgData(response.data);
                setViewOrgDetails(true);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const toggleEditForm = async (org_id) => {
        setEditOrgId(org_id);
        if(editForm){
            setEditForm(false);
        }
        else{
            try {
                const response = await axios.get(`${host}/getCompanyData/${org_id}`);
                if(response){
                    setEditOrgData(response.data);
                    setEditForm(true);
                    setFormData({
                        organization_name: response.data.organization_name,
                        organization_industry: response.data.organization_industry,
                        organization_email: response.data.organization_email,
                        org_admin_id: response.data.org_admin_id,
                        org_admin_name: response.data.org_admin_name,
                        org_admin_email: response.data.org_admin_email,
                        org_admin_contact: response.data.org_admin_contact,
                        total_hr_allocated: response.data.total_hr_allocated
                    });
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    }

    const handleDeleteOrg = async (e) => {
        e.preventDefault();
        alert("Confirm Delete Operation");
        try {
            const response = await axios.get(`${host}/deleteCompanyData/${editOrgId}`);
            if(response){
                toast.success("Data Deleted Successfully!");
                await getDashCounts();
                await getOrgAdminsData();
                setTimeout(() => {
                    setEditForm(false);
                }, 1300);                
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const handleLogout = async () => {
        await signOut();
        console.log("Candidate logged out");
        navigate("/");
    }

    const hideOrgDetails = () => {
        setViewOrgDetails(false);
    }

    const toggleVisibility = (stateSetter, otherStates) => {
        otherStates.forEach(state => state(false));
        stateSetter(prevState => !prevState);
    };
    
    const toggleShowPending = () => toggleVisibility(setShowPending, [setShowDemo, setShowTickets]);
    const toggleShowDemo = () => toggleVisibility(setShowDemo, [setShowPending, setShowTickets]);
    const toggleShowTickets = () => toggleVisibility(setShowTickets, [setShowDemo, setShowPending]);    

    const handleAccept = async (index) => {
        const id = prompt("Enter HR ID"); 
        if (!id) {
            toast.error("HR ID is required.");
            return;
        }
        try {
            const result = await axios.post(`${host}/store-hr-data/${id}`, PendingProfiles[index]);     
            if(result){
                toggleShowPending();
                await getPendingProfilesData();
                toast.success("Profile accepted!");
            }       
        } catch (error) {
            toast.error("Failed to accept profile.");
        }
    };

    const handleReject = async (index) => {
        const email = PendingProfiles[index].hremail;
        alert("Confirm Delete?");
        try {
            const result = await axios.post(`${host}/delete-pending-hr-data/${email}`);   
            if(result){
                toggleShowPending();
                await getPendingProfilesData();
            }          
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const showDemoDetails = async (demo_id) => {
        if(viewDemoDetails){
            setViewDemoDetails(false);
        }
        else{
            setViewDemoDetails(true);
            try {
                const result = await axios.get(`${host}/demo-org-data/${demo_id}`); 
                if(result){
                    setDemoOrgData(result.data);   
                }          
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    }

    const handleDemoAccept = async (demo_id) => {
        try {
            await axios.get(`${host}/delete-demo-org-data/${demo_id}`); 
            toast.success("Request accepted");
            toggleShowDemo();   
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const handleDemoReject = async (demo_id) => {
        alert("Request rejected");
        try {
            await axios.get(`${host}/delete-demo-org-data/${demo_id}`);     
            toast.error("Request rejected");
            toggleShowDemo();       
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const showTicketDetails = (index) => {
        console.log(TicketsRaised[index]);
        setTicketDetails(TicketsRaised[index]);
        toggleShowTickets();
        setViewTicket(true);
    }

    const hideTicket = () => {
        setViewTicket(false);
    }

    const updateTicketStatus = async (ticketId) => {
        if(ticketRemark !== ""){
            try {
                const result = await axios.post(`${host}/update-ticket-status/${ticketId}/${ticketRemark}`); 
                if(result){
                    alert("Status updated successfully.");
                    fetchRaisedTicketsData();
                    setViewTicket(false);
                }         
            } catch (error) {
                console.error("Error:", error);
            }
        }
        else{
            alert("Fill out remark first")
        }   
    }

    return (
        <>  
        <ToastContainer/>  
            {formVisible && (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
    <div className="bg-white rounded-lg p-6 w-full max-w-lg mx-auto shadow-lg">
      <div className="flex justify-between items-center mb-4">
        <p className="text-lg font-semibold text-gray-800">Fill The Details</p>
        <CloseRoundedIcon className='text-red-500 cursor-pointer' onClick={hideForm} />
      </div>
      <form className="space-y-4 max-h-80 overflow-y-auto">
        {[
          { label: 'Organization Name', name: 'organization_name', type: 'text' },
          { label: 'Organization Industry', name: 'organization_industry', type: 'text' },
          { label: 'Organization Email', name: 'organization_email', type: 'email' },
          { label: 'Admin ID', name: 'org_admin_id', type: 'text' },
          { label: 'Admin Name', name: 'org_admin_name', type: 'text' },
          { label: 'Admin Email', name: 'org_admin_email', type: 'email' },
          { label: 'Admin Contact', name: 'org_admin_contact', type: 'number' },
          { label: 'Set Number of HRs', name: 'total_hr_allocated', type: 'number' }
        ].map(({ label, name, type }) => (
          <div key={name} className="mb-4">
            <label className="block text-gray-600">{label}</label>
            <input
              name={name}
              type={type}
              value={formData[name]}
              onChange={handleFormChange}
              className="w-full border border-gray-300 rounded-lg p-2 focus:ring-2 focus:ring-blue-400"
            />
          </div>
        ))}
        <button className='w-full md:w-auto py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400' onClick={handleFormSubmit}>Submit</button>
      </form>
    </div>
  </div>
)}  
{editForm && editOrgData && (
  <div className='fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50'>
    <div className='bg-white rounded-lg p-6 w-full max-w-lg mx-auto shadow-lg'>
    <div className="flex justify-between items-center mb-4">
        <p className="text-lg font-semibold text-gray-800">Edit Details</p>
        <CloseRoundedIcon className='text-red-500 cursor-pointer' onClick={toggleEditForm} />
      </div>
      <form className="space-y-4 max-h-80 overflow-y-auto">
        {[
          { label: 'Organization Name', name: 'organization_name' },
          { label: 'Organization Industry', name: 'organization_industry' },
          { label: 'Organization Email', name: 'organization_email', type: 'email' },
          { label: 'Organization Admin ID', name: 'org_admin_id' },
          { label: 'Organization Admin Name', name: 'org_admin_name' },
          { label: 'Organization Admin Email', name: 'org_admin_email', type: 'email' },
          { label: 'Organization Admin Contact', name: 'org_admin_contact', type: 'number' },
          { label: 'Set Number Of HRs', name: 'total_hr_allocated', type: 'number' }
        ].map(({ label, name, type = 'text' }) => (
          <div key={name} className='mb-4'>
            <label className='block text-gray-600'>{label}</label>
            <input
              name={name}
              type={type}
              placeholder={editOrgData[name] || ''}
              value={formData[name]}
              onChange={handleFormChange}
              className='w-full border border-gray-300 rounded-lg p-2 focus:ring-2 focus:ring-blue-400'
            />
          </div>
        ))}
        <div className='flex space-x-4'>
          <button type='button' className='w-full md:w-auto py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400' onClick={handleFormUpdate}>Update</button>
          <button type='button' onClick={handleDeleteOrg} className='w-full md:w-auto py-2 px-4 bg-red-500 text-white rounded-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400'>Delete Organization</button>
        </div>
      </form>
    </div>
  </div>
)}
{viewOrgDetails && orgData && (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
    <div className="bg-white rounded-lg p-6 w-full max-w-lg mx-auto shadow-lg overflow-y-auto">
    <div className="flex justify-between items-center mb-4">
        <p className="text-lg font-semibold text-gray-800">Organization Details</p>
        <CloseRoundedIcon className='text-red-500 cursor-pointer' onClick={hideOrgDetails} />
      </div>
      <hr className="border-gray-300 mb-4" />
      <div className="space-y-2 text-gray-700">
        {[
          { label: 'Organization Name', value: orgData.organization_name },
          { label: 'Organization Type', value: orgData.organization_industry },
          { label: 'Organization Email', value: orgData.organization_email },
          { label: 'Admin ID', value: orgData.org_admin_id },
          { label: 'Admin Name', value: orgData.org_admin_name },
          { label: 'Admin Email', value: orgData.org_admin_email },
          { label: 'Admin Contact', value: orgData.org_admin_contact },
          { label: 'HR Allocated', value: orgData.total_hr_allocated }
        ].map(({ label, value }) => (
          <p key={label}><strong>{label}:</strong> {value}</p>
        ))}
      </div>
    </div>
  </div>
)}
{viewDemoDetails && demoOrgData && (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
    <div className="bg-white rounded-lg p-6 w-full max-w-lg mx-auto shadow-lg overflow-y-auto">
    <div className="flex justify-between items-center mb-4">
        <p className="text-lg font-semibold text-gray-800">Demo Request Details</p>
        <CloseRoundedIcon className='text-red-500 cursor-pointer' onClick={showDemoDetails} />
      </div>
      <hr className="border-gray-300 mb-4" />
      <div className="space-y-3 text-gray-700">
        {[
          { label: 'Name', value: demoOrgData.name },
          { label: 'Phone', value: demoOrgData.phone },
          { label: 'Email', value: demoOrgData.email },
          { label: 'Employee Size', value: demoOrgData.employeeSize },
          { label: 'Job Function', value: demoOrgData.jobFunction },
          { label: 'Job Role', value: demoOrgData.jobRole },
          { label: 'Is Using ATS', value: demoOrgData.isUsingATS ? 'Yes' : 'No' },
          { label: 'Is Recruitment Consultancy', value: demoOrgData.isRecruitmentConsultancy ? 'Yes' : 'No' },
          { label: 'Message', value: demoOrgData.message }
        ].map(({ label, value }) => (
          <p key={label}><strong>{label}:</strong> {value}</p>
        ))}
      </div>
    </div>
  </div>
)}
{viewTicket && TicketDetails && (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
    <div className="bg-white rounded-lg p-6 w-full max-w-lg mx-auto shadow-lg overflow-y-auto">
    <div className="flex justify-between items-center mb-4">
        <p className="text-lg font-semibold text-gray-800">Ticket Details</p>
        <CloseRoundedIcon className='text-red-500 cursor-pointer' onClick={hideTicket} />
      </div>
      <hr className="border-gray-300 mb-4" />
      <div className="space-y-3 text-gray-700">
        {[
          { label: 'Ticket Title', value: TicketDetails.title },
          { label: 'Description', value: TicketDetails.description },
          { label: 'Raised by Admin', value: TicketDetails.orgadminName },
          { label: 'Admin Email', value: TicketDetails.orgadminEmail },
          { label: 'Date', value: TicketDetails.createdDate }
        ].map(({ label, value }) => (
          <p key={label}><strong>{label}:</strong> {value}</p>
        ))}
      </div>
      <label className="block text-gray-700 mt-4"><strong>Add Remark:</strong></label>
      <textarea 
        id="ticketRemark" 
        value={ticketRemark} 
        onChange={handleTicketRemarkChange} 
        required 
        className="w-full p-2 mt-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
      />
      <button 
        onClick={() => updateTicketStatus(TicketDetails._id)} 
        className="mt-4 bg-blue-500 text-white py-1 px-4 rounded-lg hover:bg-blue-600"
      >
        Update Status
      </button>
    </div>
  </div>
)}
<div className="p-4 bg-gray-100 min-h-screen max-h-screen overflow-auto">
<header className="flex justify-between items-center p-4 bg-white shadow-md rounded-lg mb-6 relative">
  <h1 className="text-xl font-bold">GenieHire AI</h1>
  <div className="flex space-x-6 items-center">
  {[
  { icon: <ConfirmationNumberRoundedIcon onClick={toggleShowTickets} />, count: TicketsRaised?.length },
  { icon: <Notifications onClick={toggleShowDemo} />, count: DemoProfiles?.length },
  { icon: <PendingActions onClick={toggleShowPending} />, count: PendingProfiles?.length }
].map(({ icon, count }, index) => (
  <div key={index} className="relative flex items-center">
    <div className="absolute top-0 right-0 text-xs bg-red-500 text-white rounded-full w-4 h-4 flex items-center justify-center">
      {count || 0}
    </div>
    {React.cloneElement(icon, {
      style: { fontSize: 36 }, 
      className: "cursor-pointer hover:text-blue-400"
    })}
  </div>
))}
    <div className="cursor-pointer hover:text-blue-400" onClick={handleLogout}>
      <ExitToApp style={{fontSize:"36px"}} className="text-2xl" />
    </div>
  </div>
  {
  (showPending || showDemo || showTickets) && (
    <div className="absolute top-full right-0 mt-2 bg-white  rounded-lg shadow-lg w-1/2 md:w-1/3 lg:w-1/4 z-10">
            {showPending && PendingProfiles?.length > 0 ? (
        <div className="bg-white-100 p-4 rounded-lg shadow-md">
          <h2 className="font-bold text-lg">Pending Profiles</h2>
          {PendingProfiles && PendingProfiles.map((item, index) => (
    <ul key={index} className="mb-4 p-4 border rounded-lg shadow-sm">
        <li><strong>HR Name: </strong>{item.hrName}</li>
        <li><strong>HR Email: </strong>{item.hremail}</li>
        <li><strong>Request from: </strong>{item.request_by}</li>                        
        <div className='pendingProfileBtns flex space-x-4 mt-2'>
            <button 
                id='acceptPending' 
                onClick={() => handleAccept(index)} 
                className="px-4 py-2 bg-green-500 text-white font-semibold rounded-lg flex items-center space-x-2 hover:bg-green-600 transition duration-200 ease-in-out"
            >
                <CheckCircleIcon className="text-white" />
                <span>Accept</span>
            </button>
            <button 
                id='rejectPending' 
                onClick={() => handleReject(index)} 
                className="px-4 py-2 bg-red-500 text-white font-semibold rounded-lg flex items-center space-x-2 hover:bg-red-600 transition duration-200 ease-in-out"
            >
                <CancelIcon className="text-white" />
                <span>Reject</span>
            </button>
        </div>
    </ul>
))}
        </div>
      ) : (
        showPending && <p className='p-4'>No Pending Actions</p>
      )}
      {showDemo && DemoProfiles?.length > 0 ? (
        <div className="bg-white-100 p-4 rounded-lg shadow-md">
          <h2 className="font-bold text-lg">Demo Profiles</h2>
          {DemoProfiles.map((item) => (
            <div key={item._id} className="space-y-2">
              <ul className="text-gray-700">
                <li><strong>Name:</strong> {item.name}</li>
                <li><strong>Email:</strong> {item.email}</li>
                <li><strong>Role:</strong> {item.jobRole}</li>
                <div className="flex space-x-2">
                  <button 
                    onClick={() => showDemoDetails(item._id)} 
                    className="bg-green-500 text-white py-1 px-4 rounded-lg hover:bg-green-600"
                  >
                    View
                  </button>
                  <button 
                    onClick={() => handleDemoAccept(item._id)} 
                    className="bg-blue-500 text-white py-1 px-4 rounded-lg hover:bg-blue-600"
                  >
                    Accept
                  </button>
                  <button 
                    onClick={() => handleDemoReject(item._id)} 
                    className="bg-red-500 text-white py-1 px-4 rounded-lg hover:bg-red-600"
                  >
                    Reject
                  </button>
                </div>
              </ul>
            </div>
          ))}
        </div>
      ) : (
        showDemo && <p className='p-4'>No Demo Requests</p>
      )}
      {showTickets && TicketsRaised?.length > 0 ? (
        <div className="bg-white-100 p-4 rounded-lg shadow-md">
          <h2 className="font-bold text-lg">Tickets Raised</h2>
          {TicketsRaised && TicketsRaised.map((item, index) => (
                                <ul key={index}>
                                    <li><strong>Title: </strong>{item.title}</li>
                                    <li><strong>From: </strong>{item.orgadminName}</li>
                                    <li><strong>Date: </strong>{item.createdDate}</li>                        
                                    <button className="mt-2 px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 transition duration-200 ease-in-out" id='viewPending' onClick={() => showTicketDetails(index)}>View</button>
                                </ul>
                            ))}
        </div>
      ) : (
        showTickets && <p className='p-4'>No Raised Tickets</p>
      )}
    </div>
  )}
</header>
<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 p-6">
  <div className="bg-white p-6 rounded-lg shadow-xl flex flex-col items-center hover:scale-105 transition">
    <h2 className="text-xl font-semibold">{adminName}</h2>
    <p className="text-sm text-gray-600">{adminEmail}</p>
    <p className="text-sm text-gray-600">{adminContact}</p>
    <p className="font-bold text-gray-500">Admin</p>
    <button
      onClick={handleAddCompany}
      className="mt-6 bg-green-600 text-white py-2 px-6 rounded-lg w-full hover:bg-green-700 transition"
    >
      Add Organization
    </button>
  </div>
  <div className="bg-white p-6 rounded-lg shadow-xl flex flex-col items-center hover:scale-105 transition">
    <div className="text-4xl font-bold text-blue-600">{data?.length || 0}</div>
    <p className="mt-2">Active Organizations</p>
  </div>
  <div className="bg-white p-6 rounded-lg shadow-xl flex flex-col items-center hover:scale-105 transition">
    <div className="text-4xl font-bold text-indigo-600">{TotalHRCount || 0}</div>
    <p className="mt-2">Active HRs</p>
  </div>
  <div className="bg-white p-6 rounded-lg shadow-xl flex flex-col items-center hover:scale-105 transition">
    <div className="text-4xl font-bold text-purple-600">{interviewCount}</div>
    <p className="mt-2">Total Interviews Conducted</p>
  </div>
</div>

<div className="overflow-x-auto mt-6 bg-white shadow-lg rounded-lg">
  <table className="min-w-full table-auto">
    <thead className="bg-blue-600 text-white">
      <tr>
        <th className="py-2 px-4 text-left">Sr</th>
        <th className="py-2 px-4 text-left">Admin Name</th>
        <th className="py-2 px-4 text-left">Admin Contact</th>
        <th className="py-2 px-4 text-left">HRs Allocated</th>
        <th className="py-2 px-4 text-left">Action</th>
      </tr>
    </thead>
    <tbody>
      {data?.map((item, index) => (
        <tr key={index} className="border-b border-gray-200 hover:bg-gray-50">
          <td className="py-2 px-4">{index + 1}</td>
          <td
            className="py-2 px-4 text-blue-600 cursor-pointer hover:text-blue-800"
            onClick={() => showOrgData(item.organization_id)}
          >
            {item.org_admin_name}
          </td>
          <td className="py-2 px-4">{item.org_admin_contact}</td>
          <td className="py-2 px-4">{item.total_hr_allocated}</td>
          <td className="py-2 px-4">
            <Edit 
              className="text-blue-500 cursor-pointer hover:text-blue-700 transition duration-200"
              onClick={() => toggleEditForm(item.organization_id)}
            />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>

            </div>
        </>
    );
    
};

export default GenieHireAdminDashboard;