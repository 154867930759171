import Header from './Header';
import React, { useState } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import host from '../../global.jsx';

const Layout = () => {
  const location = useLocation();
  const [roundName, setRoundName] = useState('');
  const {interview_id} = useParams();
  const [candidateName,setCandidateName] = useState('');

  useEffect(() => {
    const fetchInterviewProgress = async () => {
      try {
        const response = await fetch(`${host}/check_test_progress/${interview_id}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setCandidateName(data.name)
      } catch (error) {
        toast.error(error.message)
      }
    };

    fetchInterviewProgress();
  }, [interview_id]);

  useEffect(() => {
    const path = location.pathname;

    switch (true) {
      case path.includes('/mcq'):
        setRoundName('MCQ ROUND');
        break;
        case path.includes('/coding'):
        setRoundName('CODING ROUND');
        break;
        case path.includes('/interview'):
        setRoundName('INTERVIEW ROUND');
        break;
      default:
        setRoundName('');
    }
  }, [location.pathname]);

  return (
    <>
    <ToastContainer/>
    <div style={{ height: '100vh', maxHeight: '100vh', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
      <Header candidateName={candidateName} roundName={roundName}/>
      <main style={{ flex: 1, overflowY: 'auto' }}>
        <Outlet />
      </main>
    </div>
    </>
  );
};

export default Layout;
