import React from 'react';
import { useState } from 'react';
import { 
  Lightbulb,
  ThumbsUp,
  Mic,
  ShieldAlert,
  Video,
  ArrowRight,
  User,
  Bot
} from 'lucide-react';
import host from '../../global';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const InterviewInstructions = () => {
  const [isHovered, setIsHovered] = useState(false);
  const {interview_id} = useParams();
  const navigate = useNavigate();
  const [currentChat,setCurrentChat] = useState(0);
  const chatMessages = [
    { type: 'ai', message: "Tell me about your experience with machine learning." },
    { type: 'user', message: "I've worked on several ML projects..." },
    { type: 'ai', message: "How would you handle a large dataset?" },
    { type: 'user', message: "I would first analyze the data distribution..." },
    { type: 'ai', message: "Can you explain your problem-solving approach?" },
    { type: 'user', message: "I follow a systematic method..." }
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentChat((prev) => (prev + 1) % chatMessages.length);
    }, 3000);
    return () => clearInterval(timer);  // eslint-disable-next-line
  }, []);

  const handleProceed = async () =>{
    await fetch(`${host}/greet_user/${interview_id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    navigate(`/candidate/interview/${interview_id}`);
  }

  const instructions = [
    { 
      icon: <Lightbulb className="w-6 h-6" />,
      text: "Be ready to explain your thought process and reasoning for each answer or solution you provide."
    },
    { 
      icon: <ThumbsUp className="w-6 h-6" />,
      text: "Maintain a professional demeanor and respond to questions clearly and concisely."
    },
    { 
      icon: <ShieldAlert className="w-6 h-6" />,
      text: "Do not attempt to access unauthorized resources or collaborate with others during the interview."
    },
    { 
      icon: <Video className="w-6 h-6" />,
      text: "The interview will be recorded; ensure your environment and behavior adhere to professional standards."
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#3ECFDA] to-[#0952DA]">
      <div className="h-screen flex">
        {/* Left Section */}
        <div className="w-5/12 bg-white p-8 flex flex-col justify-start items-start relative overflow-hidden">
  <div className="relative z-10 max-w-lg text-center">
    <h1 className="text-4xl font-bold mb-6 bg-gradient-to-r from-[#3ECFDA] to-[#0952DA] text-transparent bg-clip-text">
      Virtual Interview
    </h1>

    {/* Interview Environment Preview */}
    <div className="bg-gray-900 rounded-xl p-6 shadow-xl mb-8">
              <div className="bg-gray-800 rounded-lg p-2 mb-4 flex justify-between items-center">
                <div className="flex items-center">
                  <Video className="w-5 h-5 text-[#3ECFDA] mr-2" />
                  <span className="text-green-400 text-sm">Camera Active</span>
                </div>
                <div className="flex items-center">
                  <Mic className="w-5 h-5 text-[#0952DA] mr-2" />
                  <span className="text-green-400 text-sm">Mic Active</span>
                </div>
              </div>
              <div className="space-y-4">
                {chatMessages.slice(0, currentChat + 1).map((msg, index) => (
                  <div
                    key={index}
                    className={`flex items-start gap-2 ${
                      msg.type === 'ai' ? '' : 'flex-row-reverse'
                    } transition-all duration-300 opacity-0 animate-fade-in`}
                    style={{
                      animation: 'fadeIn 0.5s ease-in forwards',
                      animationDelay: `${index * 0.2}s`
                    }}
                  >
                    <div
                      className={`w-8 h-8 rounded-full flex items-center justify-center ${
                        msg.type === 'ai' ? 'bg-[#3ECFDA]' : 'bg-[#0952DA]'
                      }`}
                    >
                      {msg.type === 'ai' ? (
                        <Bot className="w-5 h-5 text-white" />
                      ) : (
                        <User className="w-5 h-5 text-white" />
                      )}
                    </div>
                    <div
                      className={`p-2 rounded-lg max-w-[80%] ${
                        msg.type === 'ai'
                          ? 'bg-gray-800 text-white'
                          : 'bg-[#0952DA] text-white'
                      }`}
                    >
                      <p className="text-sm">{msg.message}</p>
                    </div>
                  </div>
                ))}
              </div>

              {/* Status Indicator */}
              <div className="mt-2 pt-2 border-t border-gray-700">
                <div className="flex items-center justify-center">
                  <div className="w-2 h-2 bg-green-400 rounded-full animate-pulse mr-2"></div>
                  <p className="text-green-400 text-sm font-mono">Interview in progress...</p>
                </div>
              </div>
            </div>

  </div>
</div>

        {/* Right Section */}
        <div className="w-7/12 p-8 bg-white/10 backdrop-blur-md">
        <div className="max-w-2xl mx-auto">
          <div className="bg-white/90 backdrop-blur-lg rounded-2xl p-8 shadow-xl">
            <h2 className="text-3xl font-bold text-center mb-8 bg-gradient-to-r from-[#3ECFDA] to-[#0952DA] text-transparent bg-clip-text">
              Test Instructions
            </h2>
            
            <div className="space-y-4">
              {instructions.map((instruction, index) => (
                <div 
                  key={index}
                  className="flex items-center gap-2 p-2 bg-white/50 rounded-lg hover:bg-white/80 transform hover:scale-102 transition-all duration-300 hover:shadow-md"
                >
                  <div className="text-[#0952DA] mt-1 flex-shrink-0">
                    {instruction.icon}
                  </div>
                  <p className="text-gray-700">
                    {instruction.text}
                  </p>
                </div>
              ))}
            </div>

            <div className="mt-8 text-center">
              <button
              onClick={handleProceed}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                className="group relative px-8 py-3 bg-gradient-to-r from-[#3ECFDA] to-[#0952DA] text-white font-semibold rounded-lg transform transition-all duration-300 hover:scale-105 hover:shadow-lg flex items-center gap-2"
              >
                Proceed to Test
                <ArrowRight className={`w-5 h-5 transition-transform duration-300 ${isHovered ? 'translate-x-1' : ''}`} />
              </button>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default InterviewInstructions;