import React, { useState, useEffect } from "react";
import {useLocation, useNavigate } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { Avatar, Pagination, PaginationItem } from "@mui/material";
import "../../css/HRPortal.css";
import { Authenticator } from "@aws-amplify/ui-react";
import axios from "axios";
import host from "../../global";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { useMediaQuery } from "@mui/material";
import Cookies from 'js-cookie';
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const determineStatus = (status) => {
  if (status === "Completed") {
    return <div className="status-completed">Completed</div>;
  } else if (status === "In Progress") {
    return <div className="status-in-progress">In Progress</div>;
  } else {
    return <div className="status-pending">Pending</div>;
  }
};

export default function CandidateTable() {
  const navigate = useNavigate();
  const [candidates, setCandidates] = useState([]);
  const [totalCandidates, setTotalCandidates] = useState(0);
  const [itemsPerPage] = useState(10);
  const [areCandidatesLoading, setAreCandidatesLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [jobPositions, setJobPositions] = useState([]);
  const statusOptions = ["Completed", "In Progress", "Pending"];
  const isSmallScreen = useMediaQuery("(max-width: 750px)");
  const accessToken = Cookies.get('accessToken');
  const location = useLocation();
  const { page, job_position, combined_status } = location.state || {};
  const [currentPage, setCurrentPage] = useState(page || 1);
  const [filters, setFilters] = useState({
    jobPosition: job_position || "All",
    combinedStatus: combined_status || "All",
  });

  useEffect(() => {
    const handler = setTimeout(() => { 
      setDebouncedQuery(searchQuery);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  useEffect(() => {
    if (debouncedQuery.trim() === "") {
      setSearchResults([]);
      return;
    }

    const fetchSearchResults = async () => {
      try {
        const response = await axios.get(
          `${host}/search_candidates`, 
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params: {
              search_query: debouncedQuery,
            },
          }
        );
        setSearchResults(response.data);
        
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
              await new Promise((resolve) => {
                Cookies.remove('accessToken');
                resolve();
              });
              toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
              toast.warning(`Not Found: ${detail}`);
          } else {
              toast.error(`Error: ${detail}`);
          }
      } else {
          toast.error('An unexpected error occurred. Please try again.');
      }
      }
    };

    fetchSearchResults();
  }, [debouncedQuery,accessToken]);

  useEffect(() => {
    const fetchJobPositions = async () => {
      try {
        const response = await axios.get(
          `${host}/jobroles`, 
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            }
          }
        );
        setJobPositions(response.data);
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
              await new Promise((resolve) => {
                Cookies.remove('accessToken');
                resolve();
              });
              toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
              toast.warning(`Not Found: ${detail}`);
          } else {
              toast.error(`Error: ${detail}`);
          }
      } else {
          toast.error('An unexpected error occurred. Please try again.');
      }
      }
    };
    fetchJobPositions();
  }, [accessToken]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setAreCandidatesLoading(true);
        const { jobPosition, combinedStatus } = filters;
        console.log(currentPage,jobPosition,combinedStatus)
        const response = await axios.get(
          `${host}/get_candidates_data`, 
          {
              params: {
                  page: currentPage,
                  limit: itemsPerPage,
                  job_position: jobPosition,
                  combined_status: combinedStatus
              },
              headers: {
                  Authorization: `Bearer ${accessToken}`
              }
          }
      );
        setCandidates(response.data.candidates);
        setTotalCandidates(response.data.total_count)
        setAreCandidatesLoading(false);
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
              await new Promise((resolve) => {
                Cookies.remove('accessToken');
                resolve();
              });
              toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
              toast.warning(`Not Found: ${detail}`);
          } else {
              toast.error(`Error: ${detail}`);
          }
      } else {
          toast.error('An unexpected error occurred. Please try again.');
      }
        if (error.message === "UserUnAuthenticatedException") {
          navigate("/");
        }
      }
    };

    fetchData();
  }, [filters, currentPage,accessToken,navigate,itemsPerPage]);

  const totalPages = Math.ceil(totalCandidates / itemsPerPage);

  const getPaginationRange = (currentPage, totalPages) => {
    const range = [];
    const start = Math.max(1, currentPage - 1);
    const end = Math.min(totalPages, currentPage + 9);

    for (let i = start; i <= end; i++) {
      range.push(i);
    }

    return range;
  };

  const totalPagesArray = getPaginationRange(currentPage, totalPages);

  const handleViewCandidate = (interviewId) => {
    navigate(`/org-admin/evaluationpage/${interviewId}`, {
      state: {
        page: currentPage,
        job_position: filters.jobPosition,
        combined_status: filters.combinedStatus,
      },
    });
  };  

  if (areCandidatesLoading) {
    return <div class="loader">
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
</div>
  }

  return (
    <Authenticator>
      <div className="candidate-reports">
        <div className="table-container">
          <h1>Candidate Reports</h1>
          <div className="search-option">
            <div className="search-input">
              <TextField
                type="search"
                placeholder="Search by name"
                variant="outlined"
                size="small"
                className="search-input"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <div className="search-results">
                {searchResults.length > 0 ? (
                  <ul>
                    {searchResults.map((candidate, index) => (
                      <li
                        key={index}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleViewCandidate(candidate.interview_id)
                        }
                      >
                        {candidate.name}
                      </li>
                    ))}
                  </ul>
                ) : (
                  searchQuery.trim() !== "" && (
                    <p className="no-results">No results found</p>
                  )
                )}
              </div>
            </div>
            <FormControl
  variant="outlined"
  size="small"
  style={{ marginRight: "10px", minWidth: 150, maxWidth: 150 }}
>
  <InputLabel>Job Position</InputLabel>
  <Select
    value={filters.jobPosition}
    onChange={(e) => {
      setCurrentPage(1);  // Reset currentPage to 1
      setFilters((prevFilters) => ({
        ...prevFilters,
        jobPosition: e.target.value,
      }));
    }}
    name="jobPosition"
    label="Job Position"
  >
    <MenuItem value="All">All</MenuItem>
    {jobPositions.map((position) => (
      <MenuItem key={position} value={position}>
        {position}
      </MenuItem>
    ))}
  </Select>
</FormControl>

<FormControl
  variant="outlined"
  size="small"
  style={{ minWidth: 150, maxWidth: 150 }}
>
  <InputLabel>Status</InputLabel>
  <Select
    value={filters.combinedStatus}
    onChange={(e) => {
      setCurrentPage(1); 
      setFilters((prevFilters) => ({
        ...prevFilters,
        combinedStatus: e.target.value,
      }));
    }}
    name="combinedStatus"
    label="Status"
  >
    <MenuItem value="All">All</MenuItem>
    {statusOptions.map((status) => (
      <MenuItem key={status} value={status}>
        {status}
      </MenuItem>
    ))}
  </Select>
</FormControl>

          </div>
          <Table>
            <Thead>
              <Tr>
              <Th className="custom-th">Candidate Name</Th>
                <Th className="custom-th">Job Role</Th>
                <Th className="custom-th">Status</Th>
                <Th >Invited Date</Th>
              </Tr>
            </Thead>
            <Tbody>
              {candidates.map((candidate) => {
                return (
                  <Tr
                    style={{ cursor: "pointer" }}
                    key={candidate.interview_id}
                    onClick={() => handleViewCandidate(candidate.interview_id)}
                  >
                    <Td>
                      {!isSmallScreen ? (
                        <div
                          className="avatar-cell"
                          style={{ whiteSpace: "normal" }}
                        >
                          <Avatar>{candidate.candidate_name[0]}</Avatar>
                          <div style={{ whiteSpace: "normal" }}>
                            {candidate.candidate_name || "Unknown"}
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            fontSize: "1rem",
                            fontWeight: "500",
                            color: "#1f2937",
                            whiteSpace: "normal",
                          }}
                        >
                          {candidate.candidate_name || "Unknown"}
                        </div>
                      )}
                    </Td>
                    <Td style={{ whiteSpace: "normal" }}>
                      {candidate.jdposition || "Unknown Role"}
                    </Td>
                    <Td className="report-status">
                      {determineStatus(candidate.combined_status)}
                    </Td>
                    <Td>{candidate.invited_date}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          <div className="table-footer">
            <div className="pagination-text">
              Showing{" "}
              <span className="font-medium">
                {(currentPage - 1) * itemsPerPage + 1}
              </span>{" "}
              to{" "}
              <span className="font-medium">
                {Math.min(currentPage * itemsPerPage, totalCandidates)}
              </span>{" "}
              of <span className="font-medium">{totalCandidates}</span> results
            </div>

            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={(event, page) => setCurrentPage(page)}
              color="primary"
              renderItem={(item) => {
                if (totalPagesArray.includes(item.page)) {
                  return (
                    <PaginationItem
                      component="button"
                      onClick={() => setCurrentPage(item.page)}
                      {...item}
                    />
                  );
                }
                return null;
              }}
            />
          </div>
        </div>
      </div>
    </Authenticator>
  );
}
