import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Button} from '@mui/material';
import { Calendar as CalendarIcon, X } from 'lucide-react';

const TimeFrameSelector = ({ onDateRangeChange }) => {
  const [showCalendar, setShowCalendar] = useState(true);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [highlightedRange, setHighlightedRange] = useState([]);

  useEffect(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    setSelectedStartDate(today);
    setHighlightedRange([today, null]);
  }, []);

  const handleDateChange = (date) => {
    if (Array.isArray(date)) {
      const [start, end] = date;
      if (start && end && start <= end) {
        setSelectedStartDate(start);
        setSelectedEndDate(end);
        setHighlightedRange([start, end]);
        handleApply(start, end);
      } else if (start && !end) {
        setSelectedStartDate(start);
        setHighlightedRange([start, null]);
      }
    }
  };
  
  const handleApply = (start = selectedStartDate, end = selectedEndDate) => {
    if (start && end) {
      onDateRangeChange({ startDate: start, endDate: end });
      setShowCalendar(false);
    }
  };  

  const handleCancel = () => {
    resetCalendar();
  };

  const resetCalendar = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    setSelectedStartDate(today);
    setSelectedEndDate(null);
    setHighlightedRange([today, null]);
    setShowCalendar(false);
  };

  const formatDate = (date) => date ? date.toDateString() : '';

  return (
    <div>
<div className="flex justify-between">
<Button
          onClick={() => setShowCalendar(!showCalendar)}
          aria-expanded={showCalendar}
          aria-controls="calendar-container"
          aria-label={showCalendar ? 'Close Calendar' : 'Open Calendar'}
          variant="outline"
          className="flex items-center gap-2 flex-1 sm:flex-none !text-blue-600 hover:!text-white"
          >
          <CalendarIcon className="h-4 w-4" />
          <span className="hidden sm:inline">
            {selectedStartDate && selectedEndDate
              ? `${formatDate(selectedStartDate)} - ${formatDate(selectedEndDate)}`
              : 'Select Time Frame'}
          </span>
        </Button>
      {showCalendar && (
          <Button
          onClick={handleCancel}
          variant="ghost"
          size="icon"
          className="h-10 w-10"
        >
          <X className="h-4 w-4" />
        </Button>
      )}
                      </div>
      {showCalendar && (
        <>
        <div id="calendar-container" className="calendar-container">
          <Calendar
            selectRange
            onChange={handleDateChange}
            value={[selectedStartDate, selectedEndDate]}
            tileClassName={({ date }) => {
              if (highlightedRange.length === 2) {
                const [start, end] = highlightedRange;
                if (date >= start && date <= end && date.toDateString() !== new Date().toDateString()) {
                  return 'highlighted';
                }
              }
              return null;
            }}
          />
        </div>
                </>
      )}
    </div>
  );
};

export default TimeFrameSelector;