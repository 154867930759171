import React, { useState, useEffect } from 'react';
import { TextField, Pagination } from '@mui/material';
import '../../css/HRPortal.css';
import axios from 'axios';
import host from '../../global';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Cookies from 'js-cookie';
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SegmentedProgressBar = ({ processComplete, inProgress, testPending, total }) => {
  const getSegmentStyle = (value, color) => ({
    width: `${total > 0 ? (value / total) * 100 : 0}%`,
    backgroundColor: color,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5px',
    margin: '1px',
  });

  const totalCount = processComplete + inProgress + testPending;

  return (
    <div style={{ display: 'flex', height: '35px', borderRadius: '4px', overflow: 'hidden', width: '100%' }}>
      {processComplete > 0 && (
        <div style={getSegmentStyle(processComplete, '#069855')}>
          <span style={{ color: '#fff' }}>{processComplete}</span>
        </div>
      )}
      {inProgress > 0 && (
        <div style={getSegmentStyle(inProgress, 'rgba(243, 200, 102, 1)')}>
          <span style={{ color: '#000' }}>{inProgress}</span>
        </div>
      )}
      {testPending > 0 && (
        <div style={getSegmentStyle(testPending, '#D9D9D9')}>
          <span style={{ color: '#000' }}>{testPending}</span>
        </div>
      )}
      {totalCount === 0 && <div style={{ width: '100%', backgroundColor: '#E6E8F0' }} />}
    </div>
  );
};


export default function JobVacanciesTable() {
  const [jobPositions, setJobPositions] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredJobPositions, setFilteredJobPositions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = Cookies.get('accessToken');

  useEffect(() => {
    const fetchJobPositions = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${host}/getjobpositionsdata`, 
          {
              headers: {
                  Authorization: `Bearer ${accessToken}`
              }
          });
        setJobPositions(response.data);
        setFilteredJobPositions(response.data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
              await new Promise((resolve) => {
                Cookies.remove('accessToken');
                resolve();
              });
              toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
              toast.warning(`Not Found: ${detail}`);
          } else {
              toast.error(`Error: ${detail}`);
          }
      } else {
          toast.error('An unexpected error occurred. Please try again.');
      }
    }
    };
    fetchJobPositions();
  }, [accessToken]);

  useEffect(() => {
    const filtered = jobPositions
      .filter((job) =>
        job.job_role.toLowerCase().includes(searchQuery.toLowerCase())
      )
      .sort((a, b) => new Date(b.posting_date) - new Date(a.posting_date));
      setFilteredJobPositions(filtered);
  }, [searchQuery, jobPositions]);

  if (isLoading) {
    return <div class="loader">
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
</div>
  }

  return (
    <div className="job-vacancies">
      <div className="table-container">
        <div className="table-header">
          <h1>Job Vacancies</h1>
          <div className="search-option">
          <div className="search-input">
            <TextField
              type="search"
              placeholder="Search by Job Role"
              variant="outlined"
              size="small"
              className="search-input"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
              <div style={{
                width: '20px',
                height: '20px',
                backgroundColor: '#069855',
                borderRadius: '3px',
                marginRight: '5px'
              }}></div>
              <span style={{ fontSize: '0.875rem' }}>Complete</span>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
              <div style={{
                width: '20px',
                height: '20px',
                backgroundColor: 'rgba(243, 200, 102, 1)',
                borderRadius: '3px',
                marginRight: '5px'
              }}></div>
              <span style={{ fontSize: '0.875rem' }}>In Progress</span>
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{
                width: '20px',
                height: '20px',
                backgroundColor: '#D9D9D9',
                borderRadius: '3px',
                marginRight: '5px'
              }}></div>
              <span style={{ fontSize: '0.875rem' }}> Pending</span>
            </div>
            </div>
          </div>
        </div>
          <Table>
            <Thead>
              <Tr>
                <Th>Posting Date</Th>
                <Th>Job Role</Th>
                <Th style={{ textAlign: 'center' }}>Total Candidates</Th>
                <Th style={{ textAlign: 'center' }}>Progress</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredJobPositions.map((job, index) => (
                <Tr key={index}>
                  <Td>{job.posting_date}</Td>
                  <Td style={{ whiteSpace: 'normal' }}>{job.job_role}</Td>
                  <Td style={{ textAlign: 'center' }}>{job.scheduled_candidates}</Td>
                  <Td>
                    <SegmentedProgressBar
                      processComplete={job.process_complete_candidates}
                      inProgress={job.in_progress_candidates}
                      testPending={job.test_completion_pending}
                      total={job.scheduled_candidates}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        <div className="table-footer">
          <div className="pagination-text">
            Showing <span className="font-medium">1</span> to <span className="font-medium">{filteredJobPositions.length}</span> of <span className="font-medium">{filteredJobPositions.length}</span> results
          </div>
          <Pagination count={1} color="primary" />
        </div>
      </div>
    </div>
  );
}
