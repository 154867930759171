import React, { useState, useEffect } from "react";
import axios from "axios";
import host from "../../global";
import { Authenticator } from "@aws-amplify/ui-react";
import { Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { FolderOpen } from 'lucide-react';
import Cookies from 'js-cookie';
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BulkResumeUpload = () => {
  const [jobPositions, setJobPositions] = useState([]);
  const [selectedJobPosition, setSelectedJobPosition] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [resume, setResume] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [resumeName, setResumeName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const accessToken = Cookies.get('accessToken');
  const [selectedInterviewType,setSelectedInterviewType] =useState('');
  const [interview_timelimit,setInterviewTimeLimit] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  useEffect(() => {  
    const fetchJobPositions = async () => {
      try {
        const response = await axios.get(`${host}/getjdpositions`, {
          headers: {
            Authorization: `Bearer ${accessToken.toString()}`
          }
        });
        setJobPositions(response.data);
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
              await new Promise((resolve) => {
                Cookies.remove('accessToken');
                resolve();
              });
              toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
              toast.warning(`Not Found: ${detail}`);
          } else {
              toast.error(`Error: ${detail}`);
          }
      } else {
          toast.error('An unexpected error occurred. Please try again.');
      }
      }
    };
  
    fetchJobPositions();
  },[accessToken]);

  const handleDrop = (event) => {
    event.preventDefault();

    const droppedFile = event.dataTransfer.files[0];  
    if (!['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(droppedFile.type)) {
      toast.error('Only PDF or document files are allowed.');
      return;
    }
  
    setResume(droppedFile);
    setResumeName(droppedFile.name);
    handleResumeChange({ target: { files: [droppedFile] } });
  };
  
  const handleDragOver = (event) => {
    event.preventDefault();
  };
  
  const handleResumeChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setResumeName(file.name);

      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (fileExtension === "zip") {
        setErrorMessage("");
        setResume(file);
        console.log("Valid ZIP file selected:", file);
      } else {
        setErrorMessage("Only ZIP files are allowed.");
        setResume(null);
        console.log("Invalid file type selected");
      }
    } else {
      setErrorMessage("Please select a file.");
      setResume(null);
    }
  };

  const handleJobPositionChange = async (e) => {
    const selectedPosition = e.target.value;
    setSelectedJobPosition(selectedPosition);
    setJobDescription("");
    setIsSubmitting(false);
    try {
      const response = await axios.get(`${host}/getjd`, {
        headers: {
          Authorization: `Bearer ${accessToken.toString()}`
        },
        params: {
          job_position: selectedPosition,
        },
      });
      if(response){
        setJobDescription(response.data.description);
        setInterviewTimeLimit(response.data.interview_timelimit);
        setSelectedInterviewType(response.data.interview_type);
      }
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove('accessToken');
              resolve();
            });
            toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
        } else {
            toast.error(`Error: ${detail}`);
        }
    } else {
        toast.error('An unexpected error occurred. Please try again.');
    }
      setJobDescription('');
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedJobPosition || !jobDescription) {
      toast.error('Please select a job position and load the job description.');
      return;
    }

    if (!resume) {
      toast.error('Please upload a resume.');
      return;
    }

    setIsSubmitting(true);

    const formData = new FormData();
    formData.append('interview_type', selectedInterviewType);
    formData.append('time_limit', interview_timelimit);
    formData.append('job_role', selectedJobPosition);
    formData.append('job_description', JSON.stringify(jobDescription));
    formData.append('file', resume);

    try {
      axios.post(`${host}/Bulkupload`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken.toString()}`
        }
      });
      setIsSubmitting(false);
      toast.success("Bulk Resume Upload Successfull!")
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove('accessToken');
              resolve();
            });
            toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
        } else {
            toast.error(`Error: ${detail}`);
        }
    } else {
        toast.error('An unexpected error occurred. Please try again.');
    }
    } finally {
      setIsSubmitting(false);
    }
  };
  
  return (
    <Authenticator>
      <div className={`container`}>
        <div className="left-section gap-4">
          <div
            className="drag-drop-area"
            onClick={() => document.getElementById('fileInput')?.click()}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <input
              id="fileInput"
              type="file"
              className="hidden"
              onChange={handleResumeChange}
              accept=".zip"
              style={{ display: 'none' }}
            />
            <FolderOpen size={50} className="mx-auto h-12 w-12 text-gray-400" />
            <p className="mt-2 text-sm text-gray-500">
              {resumeName ? resumeName : "Drag resume here or click to upload"}
            </p>
            <p className="text-xs text-gray-400 mt-1">Format: .zip</p>
          </div>
          <FormControl className="select-job-role">
            <InputLabel id="job-role-select-label">Select Job Role</InputLabel>
            <Select
              labelId="job-role-select-label"
              value={selectedJobPosition}
              onChange={handleJobPositionChange}
              label="Select Job Role"
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 350,
                    overflowY: "auto",
                  },
                },
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {jobPositions.map((position, index) => (
                <MenuItem key={index} value={position}>
                  {position}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div style={{ position: 'relative', maxHeight: isExpanded ? 'auto' : '200px', overflow: 'hidden' }}>
          {jobDescription && (
        <pre style={{
          fontFamily: 'Poppins',
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word',
          padding: '10px',
          border: 'solid 1px gray',
          borderRadius: '8px',
          position: 'relative'
        }}>
            {JSON.stringify(jobDescription, null, 2)}
        </pre>
  )}
        {(!isExpanded && jobDescription) &&(
          <>
          <div style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '20px',
            background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))'
          }} />
        </>
        )}
      </div>
{jobDescription &&(
    <div style={{display:'flex',justifyContent:"flex-end", width:"100%"}}>
      <Button onClick={toggleExpand} style={{ cursor: 'pointer' }}>
          {isExpanded ? "Read Less" : "Read More"}
        </Button>
        </div>
)}
      {isExpanded && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex:'100000'
        }}>
          <div style={{
            background: '#fff',
            padding: '20px',
            borderRadius: '8px',
            maxWidth: '600px',
            maxHeight: '80vh',
            overflowY: 'auto'
          }}>
            <div style={{display:'flex',justifyContent:'flex-end'}}>
            <Button variant="contained" onClick={toggleExpand} style={{ marginTop: '10px', cursor: 'pointer' }}>Close</Button>
            </div>
            <pre style={{ fontFamily: 'Poppins', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
            {JSON.stringify(jobDescription, null, 2)}
            </pre>
          </div>
        </div>
      )}
          {!isSubmitting ? (
            <Button id='customBtn' onClick={handleSubmit}>
              Submit
            </Button>
          ) : (
            <div className="loading-animation">Loading...</div>
          )}
  
          {errorMessage && <p className="error">{errorMessage}</p>}
        </div>
      </div>
    </Authenticator>
  );
  
};

export default BulkResumeUpload;
