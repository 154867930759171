import React from 'react';
import { Button, TextField, IconButton, Icon,useMediaQuery } from '@mui/material';
import { Menu} from '@mui/icons-material'
import NotificationsActiveRoundedIcon from '@mui/icons-material/NotificationsActiveRounded';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import '../../css/HRPortal.css'
import axios from 'axios';
import host from '../../global';
import { useEffect } from 'react';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import Sidebar from './Sidebar';
import Cookies from 'js-cookie';
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Header = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const navigate = useNavigate();
  const [notification, setNotification] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width: 750px)'); 
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const accessToken = Cookies.get('accessToken');
  const availablePages = [
    { name: 'Home', route: `/hr/dashboard` },
    { name: 'Candidate Reports', route: `/hr/candidatereports` },
    { name: 'Job Vacancies', route: `/hr/jobvacancies` },
    { name: 'Profile', route: `/hr/hreditprofile` },
    { name: 'Single Resume Parser', route: `/hr/checkresume` },
    { name: 'Bulk Resume Parser', route: `/hr/BulkResume` },
  ];
    
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const handleSearchInputChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  
    if (query) {
      const filteredSuggestions = availablePages.filter((page) =>
        page.name.toLowerCase().includes(query.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
      setNoResults(filteredSuggestions.length === 0);
    } else {
      setSuggestions([]);
      setNoResults(false);
    }
  };
  const handleSuggestionClick = (route) => {
    navigate(route);
    setSearchQuery(''); 
    setSuggestions([]); 
  };
  
  const handleNotificationBellClick = async (event) => {
    setIsPopupVisible(!isPopupVisible);
  };

  const handlenotificationClick = (upload_id) => {
    navigate(`/hr/BulkResume/Bulkresumemodel/${upload_id}`);
  };

  useEffect(() => {
    const fetchBulkResumeUploadResult = async () => {
      try {
        const response = await axios.get(`${host}/bulkuploadresult`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setNotification(response.data.results);
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove('accessToken');
              resolve();
            });
              toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
              toast.warning(`Not Found: ${detail}`);
          } else {
              toast.error(`Error: ${detail}`);
          }
      } else {
          toast.error('An unexpected error occurred. Please try again.');
      }
      }
    };

    fetchBulkResumeUploadResult();
  }, [accessToken]);

  return (
    <>
    <header className="main-header">
      <nav>
      {isSmallScreen && (
            <IconButton onClick={toggleSidebar}>
              <Menu />
            </IconButton>
          )}
        <Button onClick={() => navigate(`/hr/dashboard`)}>Home</Button>
        <Button onClick={() => navigate(`/hr/candidatereports`)} >Candidate Reports</Button>
        <Button onClick={() => navigate(`/hr/jobvacancies`)} >Job Vacancies</Button>
        {/* <Button onClick={() => navigate(`/hr/tests`)} >Tests</Button> */}
      </nav>
      <div className="main-header-actions">
<TextField
  type="search"
  placeholder="Search..."
  variant="outlined"
  size="small"
  className="search-input"
  value={searchQuery}
  onChange={handleSearchInputChange}
/>

{suggestions.length > 0 && (
  <div className="suggestions-dropdown">
    <ul>
      {suggestions.map((suggestion, index) => (
        <li key={index} onClick={() => handleSuggestionClick(suggestion.route)}>
          {suggestion.name}
        </li>
      ))}
    </ul>
  </div>
)}

{noResults &&   <div className="suggestions-dropdown">
  <ul>
  <li>No results found</li>
  </ul>
  </div>}

        <IconButton className= 'custom-action-button' onClick={handleNotificationBellClick}> <NotificationsActiveRoundedIcon fontSize='large'/></IconButton>
        {isPopupVisible && (
            <div className='notification-popup'>
              {notification.length > 0 ? (
        <ul>
          {notification.map(({ upload_id, job_role }, index) => (
            <>
<li key={index} onClick={(event) => {
    handleNotificationBellClick(event);
    handlenotificationClick(upload_id);
}}>
    <div className="notification-card">
        <Icon className="notification-icon">
            <CheckCircleOutlineRoundedIcon />
        </Icon>
        <div className="desc">
            All resumes for the {job_role} position have been successfully parsed.
        </div>
    </div>
</li>


            </>
          ))}
        </ul>
      ) : (
        <p>No New Notifications.</p>
      )}
            </div>
          )}
      </div>
    </header>
          <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
</>
  );
};

export default Header;

