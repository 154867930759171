import React, { useState, useEffect, useRef } from 'react';
import { Card, CardContent, Typography, IconButton, CircularProgress } from '@mui/material'; 
import { GetApp } from '@mui/icons-material';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import host from '../../global';
import Cookies from 'js-cookie'
import { toast } from 'react-toastify';

export default function CodingTestReport({name,coding_percent}) {
  const { interview_id } = useParams();
  const [problems, setProblems] = useState([]);
  const [codeSubmissions, setCodeSubmissions] = useState([]);
  const [timeTaken,setTimeTaken] = useState(0)
  const [startDateTime,setStartDateTime] = useState("");
  const [loading, setLoading] = useState(true);
  const reportRef = useRef();
  const [selectedProblemId, setSelectedProblemId] = useState(null);
  const handleToggleDetails = (problemId) => {
    setSelectedProblemId(selectedProblemId === problemId ? null : problemId);
  };
  const accessToken = Cookies.get("accessToken")
  
  useEffect(() => {
    const fetchProblems = async () => {
      try {
        const response = await axios.get(`${host}/api/problems_from_codesubmissions`,{
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            interview_id: interview_id,
          },
        });
        setProblems(response.data);
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
              await new Promise((resolve) => {
                Cookies.remove('accessToken');
                resolve();
              });
              toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
              toast.warning(`Not Found: ${detail}`);
          } else {
              toast.error(`Error: ${detail}`);
          }
      } else {
          toast.error('An unexpected error occurred. Please try again.');
      }
      }
    };

    const fetchCodeSubmissions = async () => {
      try {
        const response = await axios.get(`${host}/api/get_codesubmissions`,{
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            interview_id: interview_id,
          },
        });
        setCodeSubmissions(response.data.submissions);
        setTimeTaken(response.data.time_taken)
        setStartDateTime(response.data.startDateTime);

      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
              await new Promise((resolve) => {
                Cookies.remove('accessToken');
                resolve();
              });
              toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
              toast.warning(`Not Found: ${detail}`);
          } else {
              toast.error(`Error: ${detail}`);
          }
      } else {
          toast.error('An unexpected error occurred. Please try again.');
      }
      }
    };

    const displayCodingTestResults = async () => {
      setLoading(true);
      await fetchProblems();
      await fetchCodeSubmissions();
      setLoading(false);
    };

    displayCodingTestResults();
  }, [interview_id,accessToken]);

  const handleDownloadPDF = () => {
    html2canvas(reportRef.current, {
      onclone: (document) => {
        const elementsToHide = document.querySelectorAll('.no-print');
        elementsToHide.forEach((el) => (el.style.display = 'none'));
      }
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      
      const marginTop = 10;  
      const marginLeft = 10;  
      const marginBottom = 10; 
      const marginRight = 10; 
  
      const imgWidth = pdf.internal.pageSize.width - marginLeft - marginRight; 
      const pageHeight = pdf.internal.pageSize.height;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
  
      let heightLeft = imgHeight;
  
      let position = marginTop; 
      pdf.addImage(imgData, 'PNG', marginLeft, position, imgWidth, imgHeight);
      heightLeft -= (pageHeight - marginBottom); 
  
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight + marginTop; 
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', marginLeft, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      pdf.save(`${name}_Coding_Test_Report.pdf`);
    });
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div style={{ margin: '0 auto', padding: '16px' }} ref={reportRef}>
      <Card elevation={3}>
        <CardContent
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: '#f0f0f0',
            paddingBottom: '5px',
            borderBottom: '1px solid #e0e0e0',
          }}
        >
          <Typography variant="h5" fontWeight="bold" color="textPrimary">
            Coding Test Report -  
            <Typography variant="h6" fontWeight="normal" color="textSecondary" style={{ display: 'inline', marginLeft: '5px' }}>
              {name}
            </Typography>
          </Typography>

          <IconButton onClick={handleDownloadPDF} className="no-print">
            <GetApp />
          </IconButton>
        </CardContent>
        <CardContent>
        <div style={{ display: 'flex', justifyContent: 'space-evenly', borderBottom: '1px solid #e0e0e0' }}>
        <Typography variant="body1" color="textSecondary">
              Percent Score: {coding_percent || '0'}%
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Time span: {timeTaken || '0'}s
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {startDateTime || "NA"}
            </Typography>
          </div>
        {problems.map((problem) => {
            const problemId = problem.id;
            const submission = codeSubmissions[problemId];
            const testCases = submission?.test_cases_passed;
            
            const isDetailedTestCases = Array.isArray(testCases) && testCases.every(tc => tc.hasOwnProperty('status_message') && tc.hasOwnProperty('yourOutput') && tc.hasOwnProperty('expectedOutput'));
            const totalTestCasesCount = isDetailedTestCases ? testCases.length : 0;
            const acceptedTestCasesCount = isDetailedTestCases ? testCases.filter(testCase => testCase.status_message === 'Accepted').length : 0;

            return (
              <div key={problem._id} style={{
                marginTop: '16px',
                padding: '16px',
                borderRadius: '8px',
                backgroundColor: '#ffffff',
                border: '1px solid #ced4da',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                paddingBottom:'10px',
                borderBottom: '1px solid #A09F9F'
              }}>
                <h3 style={{ margin: 0, fontWeight: 'bold', color: '#343a40' }}>
                  Problem Title: {problem.title}
                </h3>
                <p style={{ margin: '8px 0', color: '#6c757d' }}>
                  <strong>Description:</strong> {problem.description}
                </p>
                
                {submission && submission.language && submission.code && submission.coding_score !== undefined ? (
                  <div style={{ marginTop: '16px' }}>
                    <p style={{ margin: '8px 0', color: '#6c757d' }}>
                      <strong>Language:</strong> {submission.language}
                    </p>
                    
                    <p style={{ margin: '8px 0', color: '#6c757d' }}>
                      <strong>Code:</strong>
                      <pre style={{
                        margin: '8px 0',
                        padding: '8px',
                        borderRadius: '4px',
                        backgroundColor: '#f8f9fa',
                        overflowWrap: 'break-word',
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word',
                        fontFamily: 'Poppins',
                        background:"rgb(240, 240, 240)"
                      }}>
                        {submission.code}
                      </pre>
                    </p>
            
                    <p style={{ margin: '8px 0', color: '#6c757d' }}>
                      <strong>Score:</strong> {submission.coding_score} / 100 
                    </p>
            
                    <p style={{ margin: '8px 0', color: '#6c757d' }}>
                      <strong>Test Cases Passed:</strong> {acceptedTestCasesCount}
                    </p>
                    <p style={{ margin: '8px 0', color: '#6c757d' }}>
                      <strong>Total Test Cases:</strong> {totalTestCasesCount}
                    </p>
            
                    <button 
                      onClick={() => handleToggleDetails(problemId)}
                      className='no-print' 
                      style={{
                        marginTop: '8px',
                        padding: '10px 16px',
                        borderRadius: '4px',
                        backgroundColor: '#007bff',
                        color: '#ffffff',
                        border: 'none',
                        cursor: 'pointer',
                        fontSize: '16px'
                      }}>
                      {selectedProblemId === problemId ? 'Hide Details' : 'Show Details'}
                    </button>
            
                    {selectedProblemId === problemId && (
                      <div style={{ marginTop: '16px', padding: '16px', backgroundColor: '#f8f9fa', borderRadius: '4px', border: '1px solid #dee2e6' }}>
                        {isDetailedTestCases ? (
                          testCases.map((testCase, index) => (
                            <div key={index} style={{ marginBottom: '16px' }}>
                              <h4 style={{ margin: '0', color: '#343a40' }}>
                                Test Case {index + 1}
                              </h4>
                              <p style={{ margin: '4px 0', color: '#6c757d' }}>
                                <strong>Status:</strong> {testCase.status_message}
                              </p>
                              <p style={{ margin: '4px 0', color: '#6c757d' }}>
                                <strong>Your Output:</strong>
                              </p>
                              <pre style={{
                                margin: '8px 0',
                                padding: '8px',
                                borderRadius: '4px',
                                backgroundColor: '#ffffff',
                                overflowWrap: 'break-word',
                                whiteSpace: 'pre-wrap',
                                wordWrap: 'break-word',
                                fontFamily: 'Poppins'
                              }}>
                                {testCase.yourOutput}
                              </pre>
                              <p style={{ margin: '4px 0', color: '#6c757d' }}>
                                <strong>Expected Output:</strong>
                              </p>
                              <pre style={{
                                margin: '8px 0',
                                padding: '8px',
                                borderRadius: '4px',
                                backgroundColor: '#ffffff',
                                overflowWrap: 'break-word',
                                whiteSpace: 'pre-wrap',
                                wordWrap: 'break-word',
                                fontFamily: 'Poppins'
                              }}>
                                {testCase.expectedOutput}
                              </pre>
                              {testCase.stderr && (
                                <div>
                                  <p style={{ margin: '4px 0', color: '#6c757d' }}>
                                    <strong>Stderr:</strong>
                                  </p>
                                  <pre style={{
                                    margin: '8px 0',
                                    padding: '8px',
                                    borderRadius: '4px',
                                    backgroundColor: '#ffffff',
                                    overflowWrap: 'break-word',
                                    whiteSpace: 'pre-wrap',
                                    wordWrap: 'break-word',
                                    fontFamily: 'Poppins'
                                  }}>
                                    {testCase.stderr}
                                  </pre>
                                </div>
                              )}
                            </div>
                          ))
                        ) : (
                          <div>
                            <p style={{ margin: '4px 0', color: '#6c757d' }}>
                              <strong>Remark:</strong>
                            </p>
                            <pre style={{
                              padding: '8px',
                              borderRadius: '4px',
                              backgroundColor: '#f8f9fa',
                              overflowWrap: 'break-word',
                              whiteSpace: 'pre-wrap',
                              wordWrap: 'break-word',
                              fontFamily: 'Poppins'
                            }}>
                              {testCases[0]}
                            </pre>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <p style={{ fontWeight: 'bold', color: 'red' }}>Not Attempted</p>
                )}
              </div>
            );
            
          })}
        </CardContent>
      </Card>
    </div>
  );
}
