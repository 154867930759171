import React, { useState, useEffect } from "react";
import {
  CircularProgress,
} from "@mui/material";
import { BiSolidQuoteAltLeft } from "react-icons/bi";
import { Clock } from 'lucide-react';
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import host from "../../global";
import { ScheduleMeeting } from "react-schedule-meeting";
import Modal from "react-modal";
import { ToastContainer, toast } from "react-toastify";
import { SiGooglecalendar } from "react-icons/si";
import { FaMicrosoft } from "react-icons/fa";
import { FaApple } from "react-icons/fa";

export default function Component() {
  const [jobRole, setJobRole] = useState("");
  const { interview_id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [jobDescription, setJobDescription] = useState("");
  const [startdate, setStartdate] = useState(null);
  const [enddate, setEnddate] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    interviewID: interview_id,
    email: "",
  });
  const [interviewTime, setInterviewTime] = useState(null);
  const [countdown, setCountdown] = useState(null);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const navigate = useNavigate();

  const roundToNearestTenMinutes = (date) => {
    const roundedMinutes = Math.round(date.getMinutes() / 10) * 10;
    date.setMinutes(roundedMinutes);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  };
  
  useEffect(() => {
    if (!interviewTime) {
      return;
    }

    const updateCountdown = () => {
      const now = new Date();
      const timeDifference = interviewTime - now;

      if (timeDifference > 0) {
        const hours = Math.floor(
          (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
        setCountdown(`${hours}h ${minutes}m ${seconds}s`);
      } else {
        setCountdown(null);
        navigate(`/candidate/${interview_id}/testhomepage`, {
          state: { candidateName: formData.name },
        });
      }
    };

    updateCountdown();
    const intervalId = setInterval(updateCountdown, 1000);

    return () => clearInterval(intervalId);
  }, [interviewTime, formData.name, interview_id, navigate]);

  const openScheduleModal = (event) => {
    event.preventDefault();
    if (validateForm()) {
      setIsScheduleModalOpen(true);
    } else {
      toast.warn("Please fill in all the required fields correctly.");
    }
  };

  const handleStartTimeSelect = (startTimeEventEmit) => {
    const selectedTime = startTimeEventEmit.startTime;
    const roundedTime = new Date(selectedTime);
    roundedTime.setMinutes(Math.round(selectedTime.getMinutes() / 60) * 60);
    roundedTime.setSeconds(0);
    roundedTime.setMilliseconds(0);

    setSelectedDate(roundedTime);
  };

  const handleSubmit = async () => {
    setIsSubmitClicked(true);
    setIsScheduleModalOpen(false);
    if (!selectedDate) {
      toast.warn("Please select a date and time before submitting.");
      return;
    }

    const formattedDate = selectedDate.toISOString();
    try {
      const response = await axios.post(
        `${host}/candidate/schedule_test/${interview_id}/${formattedDate}`
      );
      if (response.status === 200) {
        setIsSubmitClicked(true);
        toast.success("Interview Scheduled Successfully!");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error("Failed to submit the schedule.");
      }
    } catch (err) {
      toast.error("Failed to submit the schedule.");
    }
  };

  const handleSubmitCandidateData = async (event) => {
    const currentDateTime = new Date().toISOString();
    event.preventDefault();

    if (!countdown) {
      const data = new FormData();
      data.append("name", formData.name);
      data.append("contact", formData.contact);
      data.append("interviewID", formData.interviewID);
      data.append("interview_scheduled_time", currentDateTime);
      try {
        await axios.patch(`${host}/save_candidateinfo`, null, {
          params: {
            interview_id: formData.interviewID,
            contact_number: formData.contact,
            name: formData.name,
            interview_scheduled_time: currentDateTime,
          },
        });
        toast.success("Candidate information updated successfully!");
        setTimeout(() => {
          navigate(`/candidate/${interview_id}/testhomepage`, {
            state: { candidateName: formData.name },
          });
        }, 2000);
      } catch (error) {
        if (error.response) {
          toast.error(
            error.response.data.detail ||
              "An error occurred while submitting the form."
          );
        } else if (error.request) {
          toast.error(
            "No response received from the server. Please check your connection."
          );
        } else {
          toast.error("Error: " + error.message);
        }
      }
    } else {
      toast.warn(
        "The test has not started yet. Please wait for the countdown to complete."
      );
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.email) newErrors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = "Email address is invalid";
    if (!formData.contact) newErrors.phone = "Phone number is required";
    else if (!/^\d{10}$/.test(formData.contact))
      newErrors.phone = "Phone number is invalid";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleStartInterview = (e) => {
    if (validateForm()) {
      handleSubmitCandidateData(e);
    }
  };
  const createCalendarLink = (type) => {
    if (!selectedDate) return "#";
    const eventTitle = `Test Schedule for ${jobRole}`;
    const eventDescription = jobDescription;
    const eventLocation = "";
    const startDateTime = new Date(selectedDate);
    const endDateTime = new Date(startDateTime);
    endDateTime.setHours(startDateTime.getHours() + 2);

    const formatDate = (date, isApple) => {
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const day = String(date.getUTCDate()).padStart(2, "0");
      const hours = String(date.getUTCHours()).padStart(2, "0");
      const minutes = String(date.getUTCMinutes()).padStart(2, "0");
      return isApple
        ? `${year}${month}${day}T${hours}${minutes}00Z`
        : `${year}-${month}-${day}T${hours}:${minutes}:00`;
    };

    const startDateTimeStr = formatDate(startDateTime, type === "apple");
    const endDateTimeStr = formatDate(endDateTime, type === "apple");

    switch (type) {
      case "google":
        return `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
          eventTitle
        )}&dates=${startDateTimeStr}/${endDateTimeStr}&details=${encodeURIComponent(
          eventDescription
        )}&location=${encodeURIComponent(eventLocation)}`;
      case "outlook":
        return `https://outlook.office.com/calendar/0/deeplink/compose?subject=${encodeURIComponent(
          eventTitle
        )}&startdt=${startDateTimeStr}&enddt=${endDateTimeStr}&body=${encodeURIComponent(
          eventDescription
        )}&location=${encodeURIComponent(eventLocation)}`;
      case "apple":
        return `webcal://www.apple.com/calendar/compose.ics?subject=${encodeURIComponent(
          eventTitle
        )}&start=${startDateTimeStr}&end=${endDateTimeStr}&description=${encodeURIComponent(
          eventDescription
        )}&location=${encodeURIComponent(eventLocation)}`;
      default:
        return "#";
    }
  };

  useEffect(() => {
    const fetchInterviewAndTimeframe = async () => {
      try {
        const response = await axios.get(
          `${host}/get_test_timeframe/${interview_id}`
        );
        const {
          candidate_name,
          candidate_email,
          interview_scheduled_time,
          job_role,
          job_description,
          start_date,
          end_date,
          message,
        } = response.data;

        if (message) {
          toast.warning(message);
        }

        if (interview_scheduled_time) {
          setInterviewTime(new Date(interview_scheduled_time));
        }
        setFormData((prevFormData) => ({
          ...prevFormData,
          name: candidate_name,
          email: candidate_email,
        }));

        setJobRole(job_role);
        setJobDescription(job_description);

        const start = new Date(start_date);
        const end = new Date(end_date);

        if (start.getHours() < 13) {
          start.setHours(13, 0, 0, 0);
        }

        setStartdate(start);
        setEnddate(end);
      } catch (error) {
        const errorMessage =
          error.response?.data?.detail || "Failed to fetch interview details.";
        toast.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInterviewAndTimeframe();
  }, [interview_id]);

  return (
    <>
      <ToastContainer />
      <div className="max-h-screen h-full w-full bg-white flex flex-col gap-3 p-4">
      <div className="flex flex-col sm:flex-row flex-1 gap-3">
          {/* Left Section */}
          <div
            className="w-full sm:w-5/12 flex flex-col justify-center items-center px-4 py-6 rounded-3xl gap-5"
            style={{
              background:
                "linear-gradient(142.42deg, #3ECFDA 38.71%, #0952DA 140.16%)",
            }}
          >
            <img
              src="/congrats-img.png"
              className="h-[150px] w-[130px]"
              alt="Congrats"
            />
            <h1 className="font-poppins text-2xl md:text-3xl font-bold text-center text-white">
              CONGRATULATIONS
            </h1>
            <p className="font-poppins text-lg md:text-xl text-center text-white max-w-sm">
              Your profile got shortlisted for the position of {jobRole}
            </p>
          </div>

          {/* Right Section */}
          <div
            className="w-full sm:w-7/12 px-4 py-6 flex flex-col justify-center items-center gap-5 rounded-3xl"
            style={{
              background:
                "linear-gradient(142.42deg, #3ECFDA 38.71%, #0952DA 140.16%)",
            }}
          >
            {countdown ? (
              <>
  <div className="relative bg-gradient-to-br from-[#0952DA] to-[#3ECFDA] backdrop-blur-lg rounded-xl p-6 shadow-2xl overflow-hidden border border-white/20">
    <div className="absolute top-0 left-0 w-24 h-24 bg-[#3ECFDA]/30 rounded-full blur-3xl transform -translate-x-1/2 -translate-y-1/2"></div>
    <div className="absolute bottom-0 right-0 w-24 h-24 bg-[#0952DA]/30 rounded-full blur-3xl"></div>
    <div className="relative z-10 text-center">
      <div className="flex items-center justify-center mb-4 space-x-2">
        <Clock className="w-6 h-6 text-white animate-pulse" strokeWidth={1.5} />
        <h2 className="text-xl font-bold text-white font-poppins">
          Countdown to Test
        </h2>
      </div>
      <div className="bg-gradient-to-r from-white/20 to-white/10 backdrop-blur-sm rounded-xl p-4 mb-3 border border-white/20 shadow-lg">
        <p className="font-poppins text-3xl font-bold text-white tracking-wider animate-pulse-slow">
          {countdown}
        </p>
      </div>
      <div className="flex items-center justify-center mt-2">
        <div className="relative">
          <div className="w-2 h-2 bg-[#3ECFDA] rounded-full"></div>
          <div className="absolute inset-0 w-2 h-2 bg-[#3ECFDA] rounded-full animate-ping"></div>
        </div>
        <p className="text-white/90 text-sm font-medium ml-2 font-poppins">
          Time remaining
        </p>
      </div>
    </div>
  </div>
                <button
                  className="h-[50px] w-full max-w-[300px] bg-[#0A1518] rounded-[50px] font-poppins text-lg text-white hover:bg-[#0A1518]/90 transition-colors"
                  onClick={() =>
                    navigate(`/candidate/${interview_id}/testhomepage`)
                  }
                >
                  Start Now
                </button>
                {/* <button
                  className="h-[50px] w-full max-w-[300px] border-[1.57px] border-[#FFF] rounded-[50px] font-poppins text-lg hover:bg-[#0A1518]/10 hover:border-transparent transition-colors text-black"
                  onClick={() => setIsScheduleModalOpen(true)}
                >
                  Reschedule Interview
                </button> */}
              </>
            ) : (
              <>
                <h2 className="font-poppins text-2xl font-semibold tracking-wide text-center w-full">
                  FILL THE DETAILS
                </h2>

                {isLoading ? (
                  <CircularProgress />
                ) : (
<form className="w-full max-w-lg">
  <div className="space-y-6 gap-6">
    {["name", "email", "contact"].map((field) => (
      <div key={field} className="w-4/5 mx-auto">
        <input
          type={
            field === "contact"
              ? "tel"
              : field === "email"
              ? "email"
              : "text"
          }
          placeholder={field.charAt(0).toUpperCase() + field.slice(1)}
          name={field}
          value={formData[field]}
          onChange={handleInputChange}
          disabled={field === "email"}
          className="w-full h-[45px] px-4 py-3 border border-white rounded-[50px] bg-transparent font-inter text-white placeholder-black/70 focus:outline-none focus:ring-2 focus:ring-white/50 text-lg"
        />
        {errors[field] && (
          <p className="mt-1 text-red-600 text-sm">{errors[field]}</p>
        )}
      </div>
    ))}
    <div className="flex flex-col w-4/5 mx-auto sm:flex-row gap-6 mt-6">
      <button
        className="h-[50px] w-full bg-[#0A1518] rounded-[50px] font-poppins text-lg text-white hover:bg-[#0A1518]/90 transition-colors"
        onClick={handleStartInterview}
      >
        Start Interview
      </button>
      {/* <button
        className="h-[50px] w-full border-[1.57px] border-[#FFF] rounded-[50px] font-poppins text-lg hover:bg-[#0A1518]/10 hover:border-transparent transition-colors text-black"
        onClick={openScheduleModal}
      >
        Schedule Interview
      </button> */}
    </div>
  </div>
</form>
                )}
              </>
            )}
          </div>
        </div>

        {/* Quote Section */}
        <div
          className="w-full py-4 px-4  rounded-t-3xl"
          style={{
            background:
              "linear-gradient(142.42deg, #3ECFDA 38.71%, #0952DA 140.16%)",
          }}
        >
          <div className="max-w-4xl mx-auto text-center">
            <BiSolidQuoteAltLeft className="w-12 h-12 mx-auto mb-4 text-white opacity-50" />
            <p className="text-lg md:text-xl font-poppins text-gray-700 mb-3">
            "I believed, and still believe, that you can build your dreams
              brick by brick. That you can accomplish anything with
              persistence."
            </p>
            <p className="text-base md:text-lg font-poppins text-[#0A1518]/80">
              - Maurene Goo
            </p>
          </div>
        </div>

        {/* Modals */}
        <Modal
          isOpen={isScheduleModalOpen}
          onRequestClose={() => setIsScheduleModalOpen(false)}
          className="max-w-5xl min-w-3xl mx-auto p-3 rounded-lg bg-none overflow-y-auto"
          overlayClassName="fixed inset-0 bg-black/50"
          >
          <div className="custom-schedular-modal bg-white p-4 rounded-2xl">
          <ScheduleMeeting
            className="border-none shadow-none"
            availableTimeslots={[{ startTime: roundToNearestTenMinutes(new Date(startdate)), endTime: roundToNearestTenMinutes(new Date(enddate)) }]}
            eventDurationInMinutes={0}
            eventStartTimeSpreadInMinutes={60}
            onStartTimeSelect={handleStartTimeSelect}
            minDate={new Date()}
            maxDate={enddate}
            primaryColor="black"
            backgroundColor="#3ECFDA"
          />
          <div className="flex justify-center gap-4 m-0">
            <button
              className="px-4 py-2 text-white bg-[#0A1518] rounded-[50px] font-poppins text-lg hover:bg-[#0A1518]/10 hover:border-transparent transition-colors text-black"
              onClick={() => setIsScheduleModalOpen(false)}
            >
              Close
            </button>
            {selectedDate && (
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                onClick={handleSubmit}
              >
                Confirm
              </button>
            )}
          </div>
          </div>
        </Modal>
        <Modal
          isOpen={isSubmitClicked}
          onRequestClose={() => setIsSubmitClicked(false)}
          className="max-w-md mx-auto mt-20 p-6 bg-white rounded-lg"
          overlayClassName="fixed inset-0 bg-black/50"
        >
          <p className="text-center mb-6">
            Your test schedule has been submitted successfully and an email has
            been sent!
          </p>

          <div className="flex justify-center gap-8 mb-6">
  {["google", "outlook", "apple"].map((calendar) => (
    <a
      key={calendar}
      href={createCalendarLink(calendar)}
      target="_blank"
      rel="noopener noreferrer"
      className="block"
    >
      {calendar === "google" && (
        <SiGooglecalendar className="w-[54px] h-[54px] text-[#4285F4]" />
      )}
      {calendar === "outlook" && (
        <FaMicrosoft className="w-[54px] h-[54px] text-[#0078D4]" />
      )}
      {calendar === "apple" && (
        <FaApple className="w-[54px] h-[54px] text-[#000000]" />
      )}
    </a>
  ))}
</div>
          <button
            onClick={() => setIsSubmitClicked(false)}
            className="w-full py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Close
          </button>
        </Modal>
      </div>
    </>
  );
}
