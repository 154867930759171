import React from 'react';
import { useState } from 'react';
import { 
  Code,
  CheckSquare,
  Lock,
  ArrowRight,
  Terminal,
  Cpu,
  Binary,
  Timer
} from 'lucide-react';
import { useNavigate,useParams } from 'react-router-dom';
const CodingInstructions = () => {
  const navigate = useNavigate();
  const {interview_id} = useParams();
  const [isHovered, setIsHovered] = useState(false);

  const instructions = [
    { 
      icon: <Code className="w-6 h-6" />,
      text: "The test consists of multiple coding problems."
    },
    { 
      icon: <Terminal className="w-6 h-6" />,
      text: "You may use any programming language of your choice, but ensure your code is executable in the provided environment."
    },
    { 
      icon: <CheckSquare className="w-6 h-6" />,
      text: "Make sure to test your code thoroughly before submission."
    },
    { 
      icon: <Lock className="w-6 h-6" />,
      text: "Do not attempt to access external websites or resources. The use of unauthorized resources will be monitored."
    },
    { 
      icon: <Timer className="w-6 h-6" />,
      text: "The test will automatically submit when the time is up, so manage your time wisely."
    }
  ];

  const codeSnippet = `
function solution() {
  // Your code here
  return success;
}
  `.trim();

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#3ECFDA] to-[#0952DA]">
      <div className="h-screen flex">
        {/* Left Section */}
        <div className="w-5/12 bg-white p-4 flex flex-col justify-center items-center relative overflow-hidden">
          <div className="relative z-10 max-w-lg text-center">
            <h1 className="text-4xl font-bold mb-6 bg-gradient-to-r from-[#3ECFDA] to-[#0952DA] text-transparent bg-clip-text">
              Coding Challenge
            </h1>
            <div className="space-y-8">
              {/* Animated Code Editor */}
              <div className="bg-gray-900 rounded-lg p-4 text-left shadow-xl transform hover:scale-105 transition-all duration-300">
                <div className="flex items-center mb-3">
                  <div className="w-3 h-3 rounded-full bg-red-500 mr-2"></div>
                  <div className="w-3 h-3 rounded-full bg-yellow-500 mr-2"></div>
                  <div className="w-3 h-3 rounded-full bg-green-500"></div>
                </div>
                <pre className="text-green-400 font-mono text-sm">
                  {codeSnippet}
                </pre>
              </div>

              {/* Feature Icons */}
              <div className="grid grid-cols-3 gap-4">
                <div className="p-4 bg-gray-50 rounded-lg transform hover:scale-105 transition-all duration-300">
                  <Cpu className="w-8 h-8 text-[#3ECFDA] mx-auto mb-2" />
                  <p className="text-sm text-gray-600">Multiple Problems</p>
                </div>
                <div className="p-4 bg-gray-50 rounded-lg transform hover:scale-105 transition-all duration-300">
                  <Binary className="w-8 h-8 text-[#0952DA] mx-auto mb-2" />
                  <p className="text-sm text-gray-600">Real-time Testing</p>
                </div>
                <div className="p-4 bg-gray-50 rounded-lg transform hover:scale-105 transition-all duration-300">
                  <Terminal className="w-8 h-8 text-[#3ECFDA] mx-auto mb-2" />
                  <p className="text-sm text-gray-600">Multiple Languages</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div className="w-7/12 p-4 bg-white/5 backdrop-blur-md bg-gradient-to-br from-[#3ECFDA] to-[#0952DA]">
          <div className="max-w-2xl mx-auto">
                      <div className="bg-white/90 backdrop-blur-lg rounded-2xl p-8 shadow-xl">
            <h2 className="text-3xl font-bold text-center mb-8 bg-gradient-to-r from-[#3ECFDA] to-[#0952DA] text-transparent bg-clip-text">
              Test Instructions
            </h2>
            
            <div className="space-y-4">
              {instructions.map((instruction, index) => (
                <div 
                  key={index}
                  className="flex items-center gap-2 p-2 bg-white/50 rounded-lg hover:bg-white/80 transform hover:scale-102 transition-all duration-300 hover:shadow-md"
                >
                  <div className="text-[#0952DA] mt-1 flex-shrink-0">
                    {instruction.icon}
                  </div>
                  <p className="text-gray-700">
                    {instruction.text}
                  </p>
                </div>
              ))}
            </div>

            <div className="mt-8 text-center">
              <button
                onClick={() => navigate(`/candidate/codingtest/${interview_id}`)}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                className="group relative px-8 py-3 bg-gradient-to-r from-[#3ECFDA] to-[#0952DA] text-white font-semibold rounded-lg transform transition-all duration-300 hover:scale-105 hover:shadow-lg flex items-center gap-2"
              >
                Proceed to Test
                <ArrowRight className={`w-5 h-5 transition-transform duration-300 ${isHovered ? 'translate-x-1' : ''}`} />
              </button>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CodingInstructions;