import React, { useState, useEffect } from "react";
import axios from "axios";
import host from "../../global";
import { Box, Typography, Divider,Icon } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import PlayCircleOutlineRoundedIcon from "@mui/icons-material/PlayCircleOutlineRounded";
import StopCircleRoundedIcon from "@mui/icons-material/StopCircleRounded";
import { useRef } from "react";
import {Download } from 'lucide-react';
import Cookies from 'js-cookie'
const PDFGenerator = ({ name, role, scores, stages, interview_id }) => {
  const [MCQTestResultData, setMCQTestResultData] = useState([]);
  const [codingProblems, setProblems] = useState([]);
  const [codeSubmissions, setCodeSubmissions] = useState([]);
  const [evaluationData, setEvaluationData] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedProblemId, setSelectedProblemId] = useState(null);
  const [audioPlaying, setAudioPlaying] = useState(false);
  const [audioIndex, setAudioIndex] = useState(null);
  const audioRefs = useRef([]);
  const averageScore = (Object.values(scores).reduce((acc, curr) => acc + parseFloat(curr), 0) / Object.values(scores).length).toFixed(2);
  const accessToken = Cookies.get("accessToken")
  const handleToggleDetails = (problemId) => {
    setSelectedProblemId(selectedProblemId === problemId ? null : problemId);
  };
  const toggleAudio = (index) => {
    if (audioIndex === index && audioPlaying) {
      audioRefs.current[index].pause();
      setAudioPlaying(false);
      setAudioIndex(null);
    } else {
      audioRefs.current[index].play();
      setAudioPlaying(true);
      setAudioIndex(index);
      audioRefs.current.forEach((audio, i) => {
        if (i !== index) {
          audio.pause();
        }
      });
    }
  };

  const generatePDF = async () => {
    window.print();
  };
  

  useEffect(() => {
    if (stages.some((stage) => stage.name === "MCQ")) {
      const displayMCQTestResult = async () => {
        try {
          const response = await axios.get(`${host}/get_mcqs_test_result`,{
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params: {
              interview_id: interview_id,
            },
          });
          setMCQTestResultData(response.data);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching MCQ data:", error);
          setLoading(false);
        }
      };
      displayMCQTestResult();
    }
  }, [interview_id, stages,accessToken]);

  useEffect(() => {
    if (stages.some((stage) => stage.name === "Coding")) {
      const fetchProblems = async () => {
        try {
          const response = await axios.get(`${host}/api/problems_from_codesubmissions`,{
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params: {
              interview_id: interview_id,
            },
          });
          setProblems(response.data);
        } catch (error) {
          console.error("Error fetching coding problems:", error);
        }
      };

      const fetchCodeSubmissions = async () => {
        try {
          const response = await axios.get(`${host}/api/get_codesubmissions`,{
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params: {
              interview_id: interview_id,
            },
          });
          setCodeSubmissions(response.data.submissions);
        } catch (error) {
          console.error("Error fetching code submissions:", error);
        }
      };

      const displayCodingTestResults = async () => {
        setLoading(true);
        await fetchProblems();
        await fetchCodeSubmissions();
        setLoading(false);
      };

      displayCodingTestResults();
    }
  }, [interview_id, stages,accessToken]);

  useEffect(() => {
    if (stages.some((stage) => stage.name === "AI Interview")) {
      const fetchEvaluationResults = async () => {
        try {
          const response = await axios.get(
            `${host}/evaluation`,{
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
              params: {
                interview_id: interview_id,
              }
            });
          const {
            questions,
            answers,
            scores,
            remarks,
            audio_urls,
            startDateTime,
          } = response.data;
          setEvaluationData({
            questions,
            answers,
            scores,
            remarks,
            audio_urls,
            startDateTime,
          });
          setLoading(false);
        } catch (error) {
          console.error("Error fetching evaluation results:", error);
          setLoading(false);
        }
      };

      fetchEvaluationResults();
    }
  }, [interview_id, stages,accessToken]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Box minHeight="100vh" p={4}>
<div className="flex justify-between items-center mb-3">
  <div className="flex gap-4 items-center">
    <h1 className="text-2xl font-bold font-poppins">{name}</h1>
    <Divider orientation="vertical" flexItem sx={{ bgcolor: "gray" }} />
    <div className="flex flex-col">
      <p className="text-sm text-gray-500 font-poppins">Job Role</p>
      <h2 className="text-lg font-bold text-black font-poppins">{role}</h2>
    </div>
    <Divider orientation="vertical" flexItem sx={{ bgcolor: "gray" }} />
    <div className="flex flex-col">
      <p className="text-sm text-gray-500 font-poppins">Total Average Percent</p>
      <h2 className="text-lg font-bold text-black font-poppins">{averageScore}%</h2>
    </div>
  </div>
    <div>
    <button
      onClick={generatePDF}
      className="no-print flex items-center justify-center"
    >
      <Download />
    </button>
  </div>
</div>
<Divider orientation="horizontal" flexItem sx={{ bgcolor: "gray" }} />

      {stages.some((stage) => stage.name === "MCQ") && (
          <div className="mt-2" id="mcq-section" style={{marginBottom:"20px"}}>

        <Box>
          <Typography
            style={{ fontFamily: "Poppins" }}
            variant="h6"
            fontWeight="bold"
          >
            MCQs - {scores.mcq_percent}%
          </Typography>
          {MCQTestResultData.mcqs &&
            Object.keys(MCQTestResultData.mcqs).map((key, index) => {
              const questionData = MCQTestResultData.mcqs[key];
              return (
                <div
                  key={index}
                  style={{
                    marginTop: "16px",
                    paddingBottom: "10px",
                    borderBottom: "1px solid #A09F9F",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    color="textPrimary"
                  >
                    <pre
                      style={{
                        overflowWrap: "break-word",
                        whiteSpace: "pre-wrap",
                        wordWrap: "break-word",
                        margin: 0,
                        fontFamily: "Poppins",
                      }}
                    >
                      {index + 1}. {questionData.question}
                    </pre>{" "}
                  </Typography>
                  <div style={{ marginLeft: "16px", marginTop: "8px" }}>
                    {[
                      questionData.option1,
                      questionData.option2,
                      questionData.option3,
                      questionData.option4,
                    ].map((option, optIndex) => {
                      const isCorrect = option === questionData.answer;
                      const isGivenAnswer =
                        option === questionData.given_answer;

                      return (
                        <div
                          key={optIndex}
                          style={{
                            padding: "8px",
                            borderRadius: "4px",
                            backgroundColor: isGivenAnswer
                              ? isCorrect
                                ? "#d4edda"
                                : "#f8d7da"
                              : "#f8f9fa",
                            border: isCorrect
                              ? "1px solid #c3e6cb"
                              : isGivenAnswer
                              ? "1px solid #f5c6cb"
                              : "1px solid #dee2e6",
                            marginBottom: "4px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            overflowWrap: "break-word",
                          }}
                        >
                          {option}
                          {isGivenAnswer &&
                            (isCorrect ? (
                              <DoneIcon style={{ color: "green" }} />
                            ) : (
                              <CloseIcon style={{ color: "red" }} />
                            ))}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </Box>
        </div>
      )}
            {stages.some((stage) => stage.name === "Coding") && (
                  <div className="mt-2" id="coding-section" style={{marginBottom:"20px"}}>

                        <Box>
                        <Typography
                          style={{ fontFamily: "Poppins" }}
                          variant="h6"
                          fontWeight="bold"
                        >
                          Coding - {scores.coding_percent}%
                        </Typography>
                        {codingProblems.map((problem) => {
            const problemId = problem.id;
            const submission = codeSubmissions[problemId];
            const testCases = submission?.test_cases_passed;
            
            const isDetailedTestCases = Array.isArray(testCases) && testCases.every(tc => tc.hasOwnProperty('status_message') && tc.hasOwnProperty('yourOutput') && tc.hasOwnProperty('expectedOutput'));

            const acceptedTestCasesCount = isDetailedTestCases ? testCases.filter(testCase => testCase.status_message === 'Accepted').length : 0;

            return (
              <div key={problem._id} style={{
                marginTop: '16px',
                padding: '16px',
                borderRadius: '8px',
                backgroundColor: '#ffffff',
                border: '1px solid #ced4da',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                paddingBottom:'10px',
                borderBottom: '1px solid #A09F9F'
              }}>
                <h3 style={{ margin: 0, fontWeight: 'bold', color: '#343a40' }}>
                  Problem Title: {problem.title}
                </h3>
                <p style={{ margin: '8px 0', color: '#6c757d' }}>
                  <strong>Description:</strong> {problem.description}
                </p>
                
                {submission && submission.language && submission.code && submission.coding_score !== undefined ? (
                  <div style={{ marginTop: '16px' }}>
                    <p style={{ margin: '8px 0', color: '#6c757d' }}>
                      <strong>Language:</strong> {submission.language}
                    </p>
                    
                    <p style={{ margin: '8px 0', color: '#6c757d' }}>
                      <strong>Code:</strong>
                      <pre style={{
                        margin: '8px 0',
                        padding: '8px',
                        borderRadius: '4px',
                        backgroundColor: '#f8f9fa',
                        overflowWrap: 'break-word',
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word',
                        fontFamily: 'Poppins',
                        background:"rgb(240, 240, 240)"
                      }}>
                        {submission.code}
                      </pre>
                    </p>
            
                    <p style={{ margin: '8px 0', color: '#6c757d' }}>
                      <strong>Score:</strong> {submission.coding_score}
                    </p>
            
                    <p style={{ margin: '8px 0', color: '#6c757d' }}>
                      <strong>Test Cases Passed:</strong> {acceptedTestCasesCount}
                    </p>
            
                    <button 
                      onClick={() => handleToggleDetails(problemId)}
                      className='no-print' 
                      style={{
                        marginTop: '8px',
                        padding: '10px 16px',
                        borderRadius: '4px',
                        backgroundColor: '#007bff',
                        color: '#ffffff',
                        border: 'none',
                        cursor: 'pointer',
                        fontSize: '16px'
                      }}>
                      {selectedProblemId === problemId ? 'Hide Details' : 'Show Details'}
                    </button>
            
                    {selectedProblemId === problemId && (
                      <div style={{ marginTop: '16px', padding: '16px', backgroundColor: '#f8f9fa', borderRadius: '4px', border: '1px solid #dee2e6' }}>
                        {isDetailedTestCases ? (
                          testCases.map((testCase, index) => (
                            <div key={index} style={{ marginBottom: '16px' }}>
                              <h4 style={{ margin: '0', color: '#343a40' }}>
                                Test Case {index + 1}
                              </h4>
                              <p style={{ margin: '4px 0', color: '#6c757d' }}>
                                <strong>Status:</strong> {testCase.status_message}
                              </p>
                              <p style={{ margin: '4px 0', color: '#6c757d' }}>
                                <strong>Your Output:</strong>
                              </p>
                              <pre style={{
                                margin: '8px 0',
                                padding: '8px',
                                borderRadius: '4px',
                                backgroundColor: '#ffffff',
                                overflowWrap: 'break-word',
                                whiteSpace: 'pre-wrap',
                                wordWrap: 'break-word',
                                fontFamily: 'Poppins'
                              }}>
                                {testCase.yourOutput}
                              </pre>
                              <p style={{ margin: '4px 0', color: '#6c757d' }}>
                                <strong>Expected Output:</strong>
                              </p>
                              <pre style={{
                                margin: '8px 0',
                                padding: '8px',
                                borderRadius: '4px',
                                backgroundColor: '#ffffff',
                                overflowWrap: 'break-word',
                                whiteSpace: 'pre-wrap',
                                wordWrap: 'break-word',
                                fontFamily: 'Poppins'
                              }}>
                                {testCase.expectedOutput}
                              </pre>
                              {testCase.stderr && (
                                <div>
                                  <p style={{ margin: '4px 0', color: '#6c757d' }}>
                                    <strong>Stderr:</strong>
                                  </p>
                                  <pre style={{
                                    margin: '8px 0',
                                    padding: '8px',
                                    borderRadius: '4px',
                                    backgroundColor: '#ffffff',
                                    overflowWrap: 'break-word',
                                    whiteSpace: 'pre-wrap',
                                    wordWrap: 'break-word',
                                    fontFamily: 'Poppins'
                                  }}>
                                    {testCase.stderr}
                                  </pre>
                                </div>
                              )}
                            </div>
                          ))
                        ) : (
                          <div>
                            <p style={{ margin: '4px 0', color: '#6c757d' }}>
                              <strong>Remark:</strong>
                            </p>
                            <pre style={{
                              padding: '8px',
                              borderRadius: '4px',
                              backgroundColor: '#f8f9fa',
                              overflowWrap: 'break-word',
                              whiteSpace: 'pre-wrap',
                              wordWrap: 'break-word',
                              fontFamily: 'Poppins'
                            }}>
                              {testCases[0]}
                            </pre>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <p style={{ fontWeight: 'bold', color: 'red' }}>Not Attempted</p>
                )}
              </div>
            );
            
          })}
                        </Box>
                        </div>
            )}
            {stages.some((stage) => stage.name === "AI Interview") && (
                  <div className="mt-2" id="ai-interview-section">

                        <Box>
                        <Typography
                          style={{ fontFamily: "Poppins" }}
                          variant="h6"
                          fontWeight="bold"
                        >
                          AI Interview - {scores.interview_percent}%
                        </Typography>
                        {evaluationData.questions &&
            evaluationData.questions.map((question, index) => {
              return (
                <div
                  key={index}
                  style={{
                    marginTop: "16px",
                    display: "flex",
                    flexDirection: "column",
                    paddingBottom: "10px",
                    borderBottom: "1px solid #A09F9F",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    color="textPrimary"
                  >
                    <pre
                      style={{
                        overflowWrap: "break-word",
                        whiteSpace: "pre-wrap",
                        wordWrap: "break-word",
                        margin: 0,
                        fontFamily: "Poppins",
                      }}
                    >
                      {index + 1}. {question}
                    </pre>
                  </Typography>
                  <Typography variant="body" color="textSecondary">
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Answer:
                    </span>{" "}
                    {evaluationData.answers[index] || "NA"}
                  </Typography>
                  <Typography variant="body" color="textSecondary">
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Score:{" "}
                    </span>
                    {evaluationData.scores[index] || "NA"}
                  </Typography>
                  <Typography variant="body" color="textSecondary">
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      Remark:
                    </span>{" "}
                    {evaluationData.remarks[index] || "NA"}
                  </Typography>
                  <Icon
                    onClick={() => toggleAudio(index)}
                    style={{height:"fit-content"}}
                    className="no-print"
                  >
                    {audioPlaying && audioIndex === index ? (
                      <StopCircleRoundedIcon />
                    ) : (
                      <PlayCircleOutlineRoundedIcon />
                    )}
                  </Icon>
                  <audio
                    ref={(el) => (audioRefs.current[index] = el)}
                    src={evaluationData.audio_urls[index]}
                  />
                </div>
              );
            })}
                        </Box>
                        </div>
            )}
    </Box>
  );
};

export default PDFGenerator;
