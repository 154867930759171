import React, { useEffect, useRef, useState } from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import host from '../../global';
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
  } from '@mui/material';
  import EditIcon from '@mui/icons-material/Edit';
  import DeleteIcon from '@mui/icons-material/Delete';
  import Cookies from 'js-cookie'
  import { getCurrentUser } from 'aws-amplify/auth';
  
const EditProfile = () => {
    const fileInputRef = useRef(null);
    const profile_photos_host = process.env.REACT_APP_PROFILE_PHOTOS_HOST;
    const [profileData, setProfileData] = useState({
        name: '',
        email: '',
        contact: '',
        profileImage: '',
        profileImageFile: null, 
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [shouldSubmit, setShouldSubmit] = useState(false);
    const accessToken = Cookies.get("accessToken")
    const handleImageClick = () => {
        setIsModalOpen(true);
    };
    
    const handleEditProfile = () => {
        fileInputRef.current.click();
        setIsModalOpen(false);
    };
    
    useEffect(() => {
        if (shouldSubmit) {
          handleSubmit();
          setShouldSubmit(false);
        }                           // eslint-disable-next-line
      }, [shouldSubmit]);

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const response = await fetch(`${host}/getadmindetails`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });
                const data = await response.json();
                const { userId } = await getCurrentUser();
                setProfileData({
                    name: data.name,
                    email: data.email,
                    contact: data.contact,
                    profileImage: data.profile ? `${profile_photos_host}/OrganizationAdmins/${userId}_${data.profile}` : "",
                });
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching profile data:', error.message);
            }
        };

        fetchProfileData();
    }, [accessToken,profile_photos_host]);

  const handleChange = (e) => {
    const { name, files } = e.target;
    
    if (name === "profileImage" && files.length > 0) {
      const file = files[0];
  
      const validImageTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (!validImageTypes.includes(file.type)) {
        setMessage("Invalid file type. Please upload a PNG, JPG, or JPEG image.");
        return;
      }
  
      // Check for file size (max 1MB)
      const maxSize = 1 * 1024 * 1024;
      if (file.size > maxSize) {
        setMessage("File size exceeds 1MB. Please upload a smaller image.");
        return;
      }
  
      const imageUrl = URL.createObjectURL(file);
      console.log(imageUrl)
      setProfileData((prevData) => ({
        ...prevData,
        profileImage: imageUrl,
        profileImageFile: file,
      }));
  
      setIsLoading(true);
      setShouldSubmit(true);
    } else {
      setProfileData((prevData) => ({
        ...prevData,
        [name]: e.target.value,
      }));
    }
  };

    const handleSubmit = async (e) => {
        if (e) {
            e.preventDefault();
          }
        setIsSubmitting(true);
    
        const formData = new FormData();
        formData.append('org_admin_name', profileData.name);
        formData.append('org_admin_email', profileData.email);
        formData.append('org_admin_contact', profileData.contact);
        if (profileData.profileImageFile) {
            formData.append('profile', profileData.profileImageFile);
        }
        if (profileData.deleteProfileImage) {
            formData.append('deleteProfileImage', 'true');
        }
        try {
            const response = await fetch(`${host}/org-admin/updateprofile`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
                body: formData
            });            
    
            if (response.ok) {
                setMessage('Profile updated successfully!');
                window.location.reload()
            } else {
                const errorData = await response.json();
                console.error('Error details:', errorData);
                setMessage('Failed to update profile. ' + errorData.detail.map(err => err.msg).join(', '));
            }
        } catch (error) {
            console.error('Error updating profile:', error.message);
            setMessage('Error updating profile.');
        }
    
        setIsSubmitting(false);
    };

    const handleDeleteImage = () => {
        setProfileData((prevData) => ({
          ...prevData,
          profileImage: "",
          profileImageFile: null,
          deleteProfileImage: true,
        }));
        setIsLoading(true);
        setShouldSubmit(true);
      };

      if (isLoading) {
        return <div class="loader">
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
</div>
      }
      
    return (
        <Authenticator>
        <div>
        <div className="company-admin-container">
          <div className="content">
            <div className='edit-profile'>
                <div className="edit-profile-container">
                <h2>Edit Profile</h2>
                    <form onSubmit={handleSubmit}>
                    <div className='profile-img-container'>
    {profileData.profileImage ? (
        <img
            src={profileData.profileImage}
            alt="Profile"
            className="profile-img"
            onClick={handleImageClick}
        />
    ) : (
        <AccountCircleRoundedIcon
            style={{
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                fill: "#0073e6",
            }}
            onClick={handleImageClick}
        />
    )}
    <input
        type="file"
        name="profileImage"
        accept="image/*"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleChange}
    />
</div>

<Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
    <DialogTitle>Profile Picture</DialogTitle>
    <DialogContent>
        {profileData.profileImage ? (
            <img
                src={profileData.profileImage}
                alt="Profile"
                style={{
                    width: "45vw",
                    maxWidth: "150px",
                    height: "auto",
                    borderRadius: "50%",
                    objectFit: "cover",
                }}
            />
        ) : (
            <AccountCircleRoundedIcon
                style={{
                    width: "45vw",
                    maxWidth: "150px",
                    height: "auto",
                    borderRadius: "50%",
                    objectFit: "cover",
                }}
            />
        )}
    </DialogContent>
    <DialogActions style={{ display: "flex", justifyContent: "space-evenly" }}>
        <IconButton onClick={handleEditProfile}>
            <EditIcon />
        </IconButton>
        <IconButton onClick={handleDeleteImage}>
            <DeleteIcon />
        </IconButton>
    </DialogActions>
</Dialog>


                        <div>
                            <label>Name:</label>
                            <input
                                type="text"
                                name="name"
                                value={profileData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Email:</label>
                            <input
                                type="email"
                                name="email"
                                value={profileData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Contact:</label>
                            <input
                                type="text"
                                name="contact"
                                value={profileData.contact}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <button type="submit" disabled={isSubmitting}>
                            {isSubmitting ? 'Updating...' : 'Update Profile'}
                        </button>
                    </form>
                    {message && <p>{message}</p>}
                </div>
            </div>
            </div>
            </div>
            </div>
        </Authenticator>
    );
};

export default EditProfile;