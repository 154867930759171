import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Avatar,
  IconButton,
} from "@mui/material";
import FolderZipOutlinedIcon from "@mui/icons-material/FolderZipOutlined";
import BusinessCenterRoundedIcon from "@mui/icons-material/BusinessCenterRounded";
import UploadFileTwoToneIcon from "@mui/icons-material/UploadFileTwoTone";
import "../../css/HRPortal.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import host from "../../global";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Cookies from 'js-cookie';
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Dashboard = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [interviews, setInterviews] = useState([]);
  const navigate = useNavigate();
  const accessToken = Cookies.get('accessToken');

  function convertToISO(date) {
    const timezoneOffset = date.getTimezoneOffset() * 60000;
    const localISOTime = new Date(
      date.getTime() - timezoneOffset
    ).toISOString();
    return localISOTime.slice(0, -1);
  }

  const convertToLocalTime = (utcTimeString) => {
    const utcDate = new Date(utcTimeString);

    const localTime = new Intl.DateTimeFormat('default', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    }).format(utcDate);

    return localTime;
  };

  const handleDateChange = async (date) => {
    setSelectedDate(date);
  };

  const handleResumeUpload = async () => {
    console.log("clicked");
  };

  useEffect(() => {
    const fetchScheduledInterviews = async () => {
      const isoDate = convertToISO(selectedDate) + 'Z';
      try {
        const response = await fetch(
          `${host}/scheduled_interviews?date=${isoDate}`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        }
      );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setInterviews(data);
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove('accessToken');
              resolve();
            });
              toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
              toast.warning(`Not Found: ${detail}`);
          } else {
              toast.error(`Error: ${detail}`);
          }
      } else {
          toast.error('An unexpected error occurred. Please try again.');
      }
        setInterviews([]);
      }
    };

    fetchScheduledInterviews();
  }, [selectedDate,accessToken]); 

  return (
<div className="p-5 overflow-auto no-scrollbar">
<div className="flex flex-col p-5 rounded-2xl text-white lg:col-span-1 lg:w-1/4 card-grid">
        <Card
          className="custom-card"
          style={{ cursor: "pointer" }}
          onClick={handleResumeUpload}
        >
          <CardHeader
          style={{padding:'0px',textAlign:'center'}}
            title={
              <Typography className="custom-card-title">
                Parse Resume
              </Typography>
            }
          />
          <CardContent className="custom-card-content" style={{padding:'0px'}}>
            <IconButton
              className="custom-iconbutton"
              onClick={() => navigate(`/hr/checkresume`)}
            >
              <UploadFileTwoToneIcon
                style={{ color: "white" }}
                fontSize="large"
              />
              <span style={{ color: "white", fontSize: "10px" }}>Single</span>
            </IconButton>
            <IconButton
              className="custom-iconbutton"
              onClick={() => navigate(`/hr/BulkResume`)}
            >
              <FolderZipOutlinedIcon
                style={{ color: "white" }}
                fontSize="large"
              />
              <span style={{ color: "white", fontSize: "10px" }}>Bulk</span>
            </IconButton>
          </CardContent>
        </Card>
        </div>
        <div className="card-grid grid grid-cols-[repeat(auto-fit,minmax(230px,1fr))] gap-6 mb-2.5 p-5">
        <Card className="custom-card">
          <CardHeader
            avatar={<BusinessCenterRoundedIcon />}
            title={
              <Typography className="custom-card-title">
                Total Test Submissions
              </Typography>
            }
          />
          <CardContent style={{ padding: "0px", paddingLeft: "25%" }}>
            <Typography variant="h4">010</Typography>
          </CardContent>
        </Card>
        <Card className="custom-card">
          <CardHeader
            avatar={<BusinessCenterRoundedIcon />}
            title={
              <Typography className="custom-card-title">
                Passed Candidates
              </Typography>
            }
          />
          <CardContent style={{ padding: "0px", paddingLeft: "25%" }}>
            <Typography variant="h4">006</Typography>
          </CardContent>
        </Card>
        <Card className="custom-card">
          <CardHeader
            avatar={<BusinessCenterRoundedIcon />}
            title={
              <Typography className="custom-card-title">
                Rejected Candidates
              </Typography>
            }
          />
          <CardContent style={{ padding: "0px", paddingLeft: "25%" }}>
            <Typography variant="h4">002</Typography>
          </CardContent>
        </Card>
        <Card className="custom-card">
          <CardHeader
            avatar={<BusinessCenterRoundedIcon className="custom-card-icon" />}
            title={
              <Typography className="custom-card-title">
                Shortlisted Candidates
              </Typography>
            }
          />
          <CardContent style={{ padding: "0px", paddingLeft: "25%" }}>
            <Typography variant="h4">003</Typography>
          </CardContent>
        </Card>
      </div>
      <div className="card-grid-large p-5">
      <h2 className="text-xl font-semibold mb-2">Scheduled Interviews</h2>
      <div className="scheduled-interviews border border-white rounded-lg grid grid-cols-1 lg:grid-cols-2 gap-4">
      <div className="calendar flex justify-evenly rounded-lg">
      <Calendar onChange={handleDateChange} value={selectedDate} />
      </div>
  <div className="interview-list-container flex flex-col lg:flex-row justify-start items-start gap-5 p-4">
    <ul className="p-0">
      {interviews.length > 0 ? (
        interviews.map((interview, index) => (
          <li key={index} className="interview-item flex flex-row items-center gap-6 mb-4">
            <div className="avatar-cell flex items-center gap-2">
              <Avatar>{interview.candidate_name[0]}</Avatar>
              <Typography className="candidate-name text-base font-poppins">
                {interview.candidate_name || "Unknown"}
              </Typography>
            </div>
            <Typography className="job-role text-sm text-gray-500 font-poppins">
              {interview.job_role}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              className="interview-time ml-auto text-sm text-gray-500"
            >
              {convertToLocalTime(interview.interview_time)}
            </Typography>
          </li>
        ))
      ) : (
        <p>No interviews scheduled for this date.</p>
      )}
    </ul>
  </div>
</div>
      </div>
    </div>
  );
};

export default Dashboard;
