import React from 'react';
import { useState } from 'react';
import { 
  AlertTriangle, 
  Eye, 
  XCircle, 
  BookOpen,
  ArrowRight,
  Timer,
  Brain,
  Target,
} from 'lucide-react';
import { useNavigate,useParams } from 'react-router-dom';

const MCQInstructions = () => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const {interview_id} = useParams();
  const instructions = [
    { icon: <XCircle className="w-6 h-6" />, text: "There is no Negative Marking" },
    { icon: <AlertTriangle className="w-6 h-6" />, text: "Don't try to sneak out of the tab, you will get 2 warnings, after that test will be submitted automatically" },
    { icon: <Timer className="w-6 h-6" />, text: "The test will automatically submit when the time is up" },
    { icon: <Eye className="w-6 h-6" />, text: "Read each question carefully before answering" },
    { icon: <BookOpen className="w-6 h-6" />, text: "No external help or reference materials are allowed during the test" }
  ];

  const features = [
    { 
      icon: <Brain className="w-8 h-8 text-[#3ECFDA] mx-auto mb-2" />, 
      title: "Test Your Knowledge",
      description: "Challenge yourself with carefully curated questions"
    },
    { 
      icon: <Target className="w-8 h-8 text-[#3ECFDA] mx-auto mb-2" />, 
      title: "Clear Objectives",
      description: "Each question is designed to assess specific skills"
    },
  ];

  return (
    <div className="min-h-screen flex ">
      {/* Left Section */}
      <div className="w-5/12 p-8 flex flex-col justify-center items-center text-white">
      <div className="max-w-lg space-y-12">
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold mb-6 bg-gradient-to-r from-[#3ECFDA] to-[#0952DA] text-transparent bg-clip-text">Ready to Show Your Skills?</h1>
            <p className="text-xl opacity-90 text-black">Take out a quick and interactive MCQ assessment to demonstrate your expertise</p>
          </div>
          
          <div className="grid grid-cols-2 gap-8">
            {features.map((feature, index) => (
              <div 
                key={index}
                className="p-4 bg-gray-50 rounded-lg transform hover:scale-105 transition-all duration-300"
              >
                <div>
                  {feature.icon}
                </div>
                <h3 className="text-lg font-semibold mb-2 text-gray-600 text-center">{feature.title}</h3>
                <p className="text-sm text-gray-600 text-center">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Right Section - Instructions */}
      <div className="w-7/12 p-4 bg-white/5 backdrop-blur-md bg-gradient-to-br from-[#3ECFDA] to-[#0952DA]">
        <div className="max-w-2xl mx-auto">
          <div className="bg-white/90 backdrop-blur-lg rounded-2xl p-8 shadow-xl">
            <h2 className="text-3xl font-bold text-center mb-8 bg-gradient-to-r from-[#3ECFDA] to-[#0952DA] text-transparent bg-clip-text">
              Test Instructions
            </h2>
            
            <div className="space-y-4">
              {instructions.map((instruction, index) => (
                <div 
                  key={index}
                  className="flex items-center gap-2 p-2 bg-white/50 rounded-lg hover:bg-white/80 transform hover:scale-102 transition-all duration-300 hover:shadow-md"
                >
                  <div className="text-[#0952DA] mt-1 flex-shrink-0">
                    {instruction.icon}
                  </div>
                  <p className="text-gray-700">
                    {instruction.text}
                  </p>
                </div>
              ))}
            </div>

            <div className="mt-8 text-center">
              <button
                onClick={() => navigate(`/candidate/mcqtest/${interview_id}`)}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                className="group relative px-8 py-3 bg-gradient-to-r from-[#3ECFDA] to-[#0952DA] text-white font-semibold rounded-lg transform transition-all duration-300 hover:scale-105 hover:shadow-lg flex items-center gap-2"
              >
                Proceed to Test
                <ArrowRight className={`w-5 h-5 transition-transform duration-300 ${isHovered ? 'translate-x-1' : ''}`} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MCQInstructions;