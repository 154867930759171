import React, { useEffect, useState } from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import axios from 'axios';
import host from '../../global.jsx';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

const CreateMCQSet = () => {
  const [setsData, setSetsData] = useState({});
  const [selectedSet, setSelectedSet] = useState('');
  const [newSetName, setNewSetName] = useState('');
  const [editIndex, setEditIndex] = useState(null);
  const [editFormData, setEditFormData] = useState({});
  const accessToken = Cookies.get("accessToken");
  const [formData, setFormData] = useState({
    question: '',
    option1: '',
    option2: '',
    option3: '',
    option4: '',
    answer: '',
  });
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${host}/fetch_all_mcqs`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      setSetsData(response.data.sets["0"] || {});
      setIsLoading(false)
    } catch (error) {
      toast.error("Error fetching data");
    }
  };

  useEffect(() => {
    fetchData();      // eslint-disable-next-line 
  }, []);

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleEditFormChange = (event) => {
    const { name, value } = event.target;
    setEditFormData({
      ...editFormData,
      [name]: value
    });
  };

  const handleSetQuestion = async (e) => {
    e.preventDefault();
    const { question, option1, option2, option3, option4, answer } = formData;

    if (!question || !option1 || !option2 || !option3 || !option4 || !answer) {
      toast.error("All fields must be filled.");
      return;
    }

    if (new Set([option1, option2, option3, option4]).size !== 4) {
      toast.error("All four options must be different.");
      return;
    }

    if (![option1, option2, option3, option4].includes(answer)) {
      toast.error("Correct answer must match one of the options.");
      return;
    }

    const setName = selectedSet || newSetName;

    if (!setName) {
      toast.error("You must select or create a set.");
      return;
    }

    if (newSetName && Object.keys(setsData).includes(newSetName)) {
      toast.error("A set with this name already exists.");
      return;
    }
    console.log(formData);
    try {
      await axios.post(`${host}/add_mcq_to_set`, formData, {
        params: {
          set_name: setName 
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}` 
        }
    });
      toast.success("Question Inserted");
      setFormData({
        question: '',
        option1: '',
        option2: '',
        option3: '',
        option4: '',
        answer: ''
      });
      setNewSetName('');
      fetchData();
    } catch (error) {
      toast.error("Error adding question.");
    }
  };

  const handleEditQuestion = (setName, index) => {
    setEditIndex({ setName, index });
    setEditFormData(setsData[setName][index]);
  };

  const handleSaveQuestion = async (setName, index) => {
    const { question, option1, option2, option3, option4, answer } = editFormData;

    if (!question || !option1 || !option2 || !option3 || !option4 || !answer) {
      toast.error("All fields must be filled.");
      return;
    }

    if (new Set([option1, option2, option3, option4]).size !== 4) {
      toast.error("All four options must be different.");
      return;
    }

    if (![option1, option2, option3, option4].includes(answer)) {
      toast.error("Correct answer must match one of the options.");
      return;
    }
    console.log(editFormData)
    try {
      await axios.put(`${host}/editmcq`, editFormData, {
        params: {
          set_name: setName,
            index: index 
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    });
      toast.success("Question Updated Successfully!");
      setEditIndex(null);
      fetchData();
    } catch (error) {
      toast.error("Error updating question.");
    }
  };

  const handleDeleteQuestion = async (setName, index) => {
    try {
      await axios.delete(`${host}/deletemcq`,{
        params: {
            set_name: setName,
            index: index 
        },
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    });
      toast.success("Question Deleted Successfully!");
      fetchData();
    } catch (error) {
      toast.error("Error deleting question.");
    }
  };

  if (isLoading) {
    <div class="loader">
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
</div>
  }
  
  return (
    <>
      <Authenticator>
      <div class="w-full max-w-auto p-4 mx-auto">
            <div class="createMCQ mb-8">
      <h2 class="text-2xl font-bold text-center mb-6 text-gray-700">Add New Question</h2>
      <div class="border border-gray-300 rounded-lg p-6 shadow-md bg-white max-w-lg mx-auto">
  <form class="space-y-4">
    <div class="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4">
      <label class="text-gray-600 w-full sm:w-1/3" for="setSelect">Select a Set:</label>
      <select
        id="setSelect"
        class="border border-gray-300 rounded px-3 py-2 w-full sm:w-2/3"
        value={selectedSet}
        onChange={(e) => setSelectedSet(e.target.value)}
      >
        <option value="">Create New Set</option>
        {Object.keys(setsData).map((setName) => (
          <option key={setName} value={setName}>SET {setName}</option>
        ))}
      </select>
    </div>

    <div class="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4">
      <label class="text-gray-600 w-full sm:w-1/3" for="newSetName">New Set Name:</label>
      <input
        id="newSetName"
        name="newSetName"
        class="border border-gray-300 rounded px-3 py-2 w-full sm:w-2/3"
        placeholder="Enter New Set Name"
        value={newSetName}
        onChange={(e) => setNewSetName(e.target.value)}
        disabled={!!selectedSet}
      />
    </div>

    <input
      name="question"
      placeholder="Enter Question"
      class="border border-gray-300 rounded w-full px-4 py-2"
      value={formData.question}
      onChange={handleFormChange}
    />

    <div class="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
      <input
        name="option1"
        placeholder="Enter Option 1"
        class="border border-gray-300 rounded w-full px-4 py-2"
        value={formData.option1}
        onChange={handleFormChange}
      />
      <input
        name="option2"
        placeholder="Enter Option 2"
        class="border border-gray-300 rounded w-full px-4 py-2"
        value={formData.option2}
        onChange={handleFormChange}
      />
    </div>

    <div class="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
      <input
        name="option3"
        placeholder="Enter Option 3"
        class="border border-gray-300 rounded w-full px-4 py-2"
        value={formData.option3}
        onChange={handleFormChange}
      />
      <input
        name="option4"
        placeholder="Enter Option 4"
        class="border border-gray-300 rounded w-full px-4 py-2"
        value={formData.option4}
        onChange={handleFormChange}
      />
    </div>

    <input
      name="answer"
      placeholder="Enter Answer"
      class="border border-gray-300 rounded w-full sm:w-1/2 px-4 py-2"
      value={formData.answer}
      onChange={handleFormChange}
    />

    <div class="flex justify-center">
      <button
        type="button"
        onClick={handleSetQuestion}
        class="py-2 mt-6 bg-blue-600 text-white rounded hover:bg-blue-500 w-fit px-4"
      >
        Add Question
      </button>
    </div>
  </form>
</div>

    </div>

    {selectedSet && (
      <div class="displayQuestions">
        {setsData[selectedSet] && (
          <div class="questionsContainer mt-8 p-6">
            <h3 class="text-xl font-semibold text-gray-700 mb-4">SET {selectedSet} QUESTIONS</h3>
            {Object.keys(setsData[selectedSet]).map((key) => (
              <div key={key} class="displayedQuestions border-t border-gray-200 py-4">
{editIndex && editIndex.setName === selectedSet && editIndex.index === Number(key) ? (
  <>
    <div className="flex items-center mb-4">
      <label className="font-bold mr-4">{editIndex.index + 1}:</label>
      <input
        name="question"
        placeholder="Enter Question"
        className="border border-gray-300 rounded w-full px-4 py-2"
        value={editFormData.question}
        onChange={handleEditFormChange}
      />
    </div>

    <div className="flex items-center mb-4">
      <label className="font-semibold mr-4">A</label>
      <input
        name="option1"
        placeholder="Enter Option A"
        className="border border-gray-300 rounded w-full px-4 py-2"
        value={editFormData.option1}
        onChange={handleEditFormChange}
      />
    </div>

    <div className="flex items-center mb-4">
      <label className="font-semibold mr-4">B</label>
      <input
        name="option2"
        placeholder="Enter Option B"
        className="border border-gray-300 rounded w-full px-4 py-2"
        value={editFormData.option2}
        onChange={handleEditFormChange}
      />
    </div>

    <div className="flex items-center mb-4">
      <label className="font-semibold mr-4">C</label>
      <input
        name="option3"
        placeholder="Enter Option C"
        className="border border-gray-300 rounded w-full px-4 py-2"
        value={editFormData.option3}
        onChange={handleEditFormChange}
      />
    </div>

    <div className="flex items-center mb-4">
      <label className="font-semibold mr-4">D</label>
      <input
        name="option4"
        placeholder="Enter Option D"
        className="border border-gray-300 rounded w-full px-4 py-2"
        value={editFormData.option4}
        onChange={handleEditFormChange}
      />
    </div>

    <div className="flex items-center mb-4">
      <label className="font-semibold mr-4">Answer</label>
      <input
        name="answer"
        placeholder="Enter Answer"
        className="border border-gray-300 rounded w-full px-4 py-2"
        value={editFormData.answer}
        onChange={handleEditFormChange}
      />
    </div>
    <div class="flex space-x-4 mt-2">
    <button
      type="button"
      onClick={() => handleSaveQuestion(selectedSet, Number(key))}
      className="mt-4 bg-green-600 text-white py-2 px-4 rounded hover:bg-green-500"
    >
      Save
    </button>
    <button
      type="button"
      onClick={() => setEditIndex(null)}
      className="mt-4 bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-500"
    >
      Cancel
    </button>
                    </div>


  </>
) : (
                  <>
                    <p class="displayedQuestion text-gray-700 font-medium">
                    <div style={{ fontFamily: "Poppins", whiteSpace: "pre-wrap", wordWrap: "break-word", wordBreak: "break-word" }}>
    {Number(key)}: {setsData[selectedSet][key].question}
</div>
                    </p>
                    <ul class="list-none text-gray-600">
                      <li>a. {setsData[selectedSet][key].option1}</li>
                      <li>b. {setsData[selectedSet][key].option2}</li>
                      <li>c. {setsData[selectedSet][key].option3}</li>
                      <li>d. {setsData[selectedSet][key].option4}</li>
                    </ul>
                    <p class="text-gray-700 mt-2">Correct Answer: <span class="font-semibold">{setsData[selectedSet][key].answer}</span></p>
                    <div class="flex space-x-4 mt-2">
                      <button
                        type="button"
                        class="editBtn bg-yellow-500 text-white py-1 px-4 rounded hover:bg-yellow-400"
                        onClick={() => handleEditQuestion(selectedSet, Number(key))}
                      >
                        Edit
                      </button>
                      <button
                        type="button"
                        class="deleteBtn bg-red-500 text-white py-1 px-4 rounded hover:bg-red-400"
                        onClick={() => handleDeleteQuestion(selectedSet, Number(key))}
                      >
                        Delete
                      </button>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    )}
  </div>
      </Authenticator>
    </>
  );
};

export default CreateMCQSet;