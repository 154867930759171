import React from "react";
import {
  Button,
  TextField,
  IconButton,
  Icon,
  useMediaQuery,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import NotificationsActiveRoundedIcon from "@mui/icons-material/NotificationsActiveRounded";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import "../../css/HRPortal.css";
import axios from "axios";
import host from "../../global";
import { useEffect } from "react";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import Sidebar from "./Sidebar";
import Cookies from "js-cookie";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import { toast } from "react-toastify";

const Header = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const navigate = useNavigate();
  const [notification, setNotification] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width: 750px)");
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [isActionsDropdownOpen, setIsActionsDropdownOpen] = useState(false);
  const [isMCQDropdownOpen, setIsMCQDropdownOpen] = useState(false);
  const [isTicketModalOpen, setIsTicketModalOpen] = useState(false);
  const [ticketTitle, setTicketTitle] = useState("");
  const [ticketDescription, setTicketDescription] = useState("");
  const accessToken = Cookies.get("accessToken");
  const availablePages = [
    { name: "Home", route: `/org-admin/dashboard` },
    {
      name: "Candidate Reports",
      route: `/org-admin/candidatereports`,
    },
    { name: "Job Vacancies", route: `/org-admin/jobvacancies` },
    {
      name: "Profile",
      route: `/org-admin/org-admin-editprofile`,
    },
    {
      name: "Single Resume Parser",
      route: `/org-admin/checkresume`,
    },
    {
      name: "Bulk Resume Parser",
      route: `/org-admin/BulkResume`,
    },
  ];

  const toggleTicketModal = () => setIsTicketModalOpen(!isTicketModalOpen);

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `${host}/raiseticket`,
        {},
        {
          params: { title: ticketTitle, description: ticketDescription },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Ticket Submitted Successfully");
        setTicketTitle("");
        setTicketDescription("");
        toggleTicketModal();
      } else {
        toast.error("Error submitting ticket:", response.statusText);
      }
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const handleSearchInputChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    if (query) {
      const filteredSuggestions = availablePages.filter((page) =>
        page.name.toLowerCase().includes(query.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
      setNoResults(filteredSuggestions.length === 0);
    } else {
      setSuggestions([]);
      setNoResults(false);
    }
  };
  const handleSuggestionClick = (route) => {
    navigate(route);
    setSearchQuery("");
    setSuggestions([]);
  };

  const handleNotificationBellClick = async (event) => {
    fetchPendingAccountRequest();
    setIsPopupVisible(!isPopupVisible);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isActionsDropdownOpen && !event.target.closest(".dropdown")) {
        setIsActionsDropdownOpen(false);
      }
      if (isMCQDropdownOpen && !event.target.closest(".mcqdropdown-menu")) {
        setIsMCQDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isActionsDropdownOpen, isMCQDropdownOpen]);

  const fetchPendingAccountRequest = async () => {
    try {
      const response = await axios.get(`${host}/pendingaccounts`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setNotification(response.data.results);
    } catch (error) {
      console.error("Error fetching Profile Data", error);
    }
  };

  return (
    <>
      <header className="main-header">
        <nav>
          {isSmallScreen && (
            <IconButton onClick={toggleSidebar}>
              <MenuIcon />
            </IconButton>
          )}
          <Button onClick={() => navigate(`/org-admin/dashboard`)}>
            Dashboard
          </Button>
          <Button onClick={() => navigate(`/org-admin/hrprofiles`)}>
            HR Profiles
          </Button>
          <Button onClick={() => navigate(`/org-admin/candidatereports`)}>
            Candidate Reports
          </Button>
          <div className="actions-container">
            <Button className="custom-action-button">Actions</Button>
            <div className="actions-dropdown">
              <Button
                className="dropdown-item"
                onClick={() => navigate(`/org-admin/checkresume`)}
              >
                Resume Parser
              </Button>
              <Button
                className="dropdown-item"
                onClick={() => navigate(`/org-admin/created-jds`)}
              >
                View Created JDs
              </Button>
              <div
                className="dropdown-item mcq-dropdown"
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "0",
                  height: "40px",
                }}
              >
                <Button className="dropdown-item">MCQ Sets</Button>
                <div className="mcq-submenu">
                  <Button
                    className="submenu-item"
                    onClick={() => navigate(`/org-admin/fetchmcq`)}
                  >
                    - Get from GenieHire
                  </Button>
                  <Button
                    className="submenu-item"
                    onClick={() => navigate(`/org-admin/createmcqset`)}
                  >
                    - Create Own Set
                  </Button>
                </div>
              </div>
              <Button
                className="dropdown-item"
                onClick={() => navigate(`/org-admin/createcodingproblemset`)}
              >
                Coding Problem Sets
              </Button>
              <Button
                    className="submenu-item"
                    onClick={() => navigate(`/org-admin/candidateanalytics`)}
                  >
                  Candidate Analytics
                  </Button>
            </div>
          </div>
        </nav>
        <div className="main-header-actions">
          <TextField
            type="search"
            placeholder="Search..."
            variant="outlined"
            size="small"
            className="search-input"
            value={searchQuery}
            onChange={handleSearchInputChange}
          />

          {suggestions.length > 0 && (
            <div className="suggestions-dropdown">
              <ul>
                {suggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    onClick={() => handleSuggestionClick(suggestion.route)}
                  >
                    {suggestion.name}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {noResults && <p>No results found</p>}

          <IconButton
            className="custom-action-button"
            onClick={handleNotificationBellClick}
          >
            {" "}
            <NotificationsActiveRoundedIcon fontSize="large" />
          </IconButton>
          {isPopupVisible && (
            <div className="notification-popup">
              <h4>Profile Creation Approval Pending:</h4>
              {Array.isArray(notification) && notification.length > 0 ? (
                <ul>
                  {notification.map((name, index) => (
                    <li key={index}>
                      <div className="notification-card">
                        <Icon className="notification-icon">
                          <CheckCircleOutlineRoundedIcon
                            style={{ height: "fit-content" }}
                          />
                        </Icon>
                        <div className="desc">
                          {index + 1}. {name}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No pending approvals.</p>
              )}
            </div>
          )}
          <IconButton
            className="custom-action-button"
            onClick={toggleTicketModal}
          >
            {" "}
            <HelpCenterIcon fontSize="large" />
          </IconButton>
        </div>
        {isTicketModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-[99999999]">
            <div className="bg-white w-full max-w-md p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold mb-4 text-center">
                Raise a Ticket
              </h3>

              <select
                value={ticketTitle}
                onChange={(e) => setTicketTitle(e.target.value)}
                className="w-full p-2 mb-4 border rounded-lg focus:border-blue-500"
              >
                <option value="">Select ticket title</option>
                {[
                  "Issue with Job Description",
                  "Technical Issue",
                  "HR Profile Issue",
                  "Host Coding problem",
                  "Other",
                ].map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <textarea
                value={ticketDescription}
                onChange={(e) => setTicketDescription(e.target.value)}
                placeholder="Enter ticket description"
                rows="5"
                className="w-full p-2 mb-4 border rounded-lg focus:border-blue-500"
              />

              <div className="flex justify-end space-x-2">
                <button
                  onClick={handleSubmit}
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
                >
                  Submit
                </button>
                <button
                  onClick={toggleTicketModal}
                  className="bg-gray-300 px-4 py-2 rounded-lg hover:bg-gray-400"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </header>
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
    </>
  );
};

export default Header;
