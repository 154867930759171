import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchAuthSession } from 'aws-amplify/auth';
import { getCurrentUser } from 'aws-amplify/auth';
import Cookies from 'js-cookie';

function RoleRedirect() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    async function redirectBasedOnRole() {
      try {
        const session = await fetchAuthSession();
        const { accessToken } = session.tokens ?? {};
        const groups = accessToken?.payload['cognito:groups'] ?? [];
        const { userId } = await getCurrentUser();
        
        if (accessToken) {
          Cookies.set('accessToken', accessToken.toString(), { 
            expires: 1 / 24,
            secure: true,
            sameSite: 'Strict'
          });
          await new Promise((resolve) => setTimeout(resolve, 0));
        }

        if (groups.includes('ConvAI_Admin')) {
          navigate(`/admin/${userId}/geniehireadmin`);
        } else if (groups.includes('Company_Admin')) {
          navigate(`/org-admin/dashboard`);
        } else if (groups.includes('HR')) {
          navigate(`/hr/dashboard`);
        } else {
          navigate('/not-found'); 
        }
      } catch (err) {
        console.log(err);
        navigate('/not-found'); 
      } finally {
        setLoading(false);
      }
    }
    redirectBasedOnRole();
  }, [navigate]);

  return loading ? <div class="loader">
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
</div> : null;
}

export default RoleRedirect;
