import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound1 = (props) => {
  const navigate = useNavigate();

  const gotoHome = () => {
    navigate('/');
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 text-center p-4">
      <h3 className="text-xl font-semibold text-gray-700 mb-4">OOPS! PAGE NOT FOUND</h3>
      <div className="flex justify-center items-center mb-6">
        <h1 className="text-6xl font-bold text-red-600">404</h1>
      </div>
      <div className="mb-6">
        <h2 className="text-2xl font-medium text-gray-600">
          WE ARE SORRY, BUT THE PAGE YOU REQUESTED WAS NOT FOUND
        </h2>
      </div>
      <button
        onClick={gotoHome}
        className="mt-4 px-6 py-2 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 transition duration-300"
      >
        Home
      </button>
    </div>
  );
};

export default NotFound1;
