import React, { useState, useEffect } from 'react';
import host from '../../global';
import Cookies from 'js-cookie';

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});
  const accessToken = Cookies.get('accessToken');

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(`${host}/orgadmin/dashboard`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const data = await response.json();
        setData(data);
      } catch (error) {
        console.error('Error fetching dashboard data:', error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDashboardData();
  }, [accessToken]);

  if (isLoading) {
    return (
      <div class="loader">
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
</div>
    );
  }

  return (
    <div className="bg-gradient-to-r from-blue-50 to-white min-h-screen flex flex-col items-center py-8">
      <div className="max-w-lg w-full px-6 py-4 bg-white rounded-lg text-center">
        <h1 className="text-4xl font-extrabold text-gray-900">Welcome, {data.name}!</h1>
        <h2 className="text-2xl text-gray-700 mt-2">Organization: {data.organizationName}</h2>
        <p className="mt-6 text-lg font-semibold text-gray-800">Number of HR Profiles Created</p>
        <div className="flex items-center justify-center mt-4">
          <div className="hr-profiles-circle bg-blue-600 text-white rounded-full w-32 h-32 flex items-center justify-center text-4xl font-bold transition-transform transform hover:scale-105">
            {data.numberOfHRProfiles}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
