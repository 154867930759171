import React, { useState, useEffect } from "react";
import axios from "axios";
import host from "../../global";
import { Authenticator } from "@aws-amplify/ui-react";
import { Upload, FileText, Scan, CheckCircle2 } from "lucide-react";
import TimeFrameSelector from "../TimeFrameSelector";
import {
  CheckCircle,
  AlertCircle,
  ChevronRight,
  Mail,
  User,
} from "lucide-react";
import { Button, Select, MenuItem, FormControl } from "@mui/material";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CheckResumeOrgAdmin = () => {
  const [jobPositions, setJobPositions] = useState([]);
  const [customQuestions, setCustomQuestions] = useState([]);
  const [selectedJobPosition, setSelectedJobPosition] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [resume, setResume] = useState(null);
  const [responseReport, setResponseReport] = useState("");
  const [responseScore, setResponseScore] = useState(0);
  const [interviewID, setInterviewID] = useState("");
  const [resumeName, setResumeName] = useState("");
  const [name, setName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailID, setEmailID] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isConfirmClicked, setIsConfirmClicked] = useState(false);
  const [resumeContent, setResumeContent] = useState("");
  const [selectedInterviewType, setSelectedInterviewType] = useState("");
  const [interview_timelimit, setInterviewTimeLimit] = useState(0);
  const [selectedMCQSet, setSelectedMCQSet] = useState("");
  const [codingJudgingCriteria, setCodingJudgingCriteria] = useState([]);
  const [selectedCodingSet, setSelectedCodingSet] = useState("");
  const [timeFrame, setTimeFrame] = useState({
    startDate: new Date(),
    endDate: new Date(new Date().setDate(new Date().getDate() + 7)),
  });
  const [MCQTimeLimit, setMCQTimeLimit] = useState(0);
  const [CodingTimeLimit, setCodingTimeLimit] = useState(0);
  const accessToken = Cookies.get("accessToken");
  const [isExpanded, setIsExpanded] = useState(false);
  const [isScanning, setIsScanning] = useState(false);
  const [scanProgress, setScanProgress] = useState(0);
  const [scanPhase, setScanPhase] = useState("");
  const scanPhases = [
    "Initializing AI Scanner...",
    "Analyzing Document Structure...",
    "Extracting Key Information...",
    "Processing Experience Data...",
    "Evaluating Skills Matrix...",
    "Finalizing Analysis...",
  ];
  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  useEffect(() => {
    if (isScanning) {
      const interval = setInterval(() => {
        setScanProgress((prev) => {
          if (prev >= 100) {
            clearInterval(interval);
            return 100;
          }
          return prev + 0.5;
        });
      }, 50);

      return () => clearInterval(interval);
    }
  }, [isScanning]);

  useEffect(() => {
    if (isScanning) {
      const phaseIndex = Math.floor((scanProgress / 100) * scanPhases.length);
      setScanPhase(scanPhases[Math.min(phaseIndex, scanPhases.length - 1)]);
    }// eslint-disable-next-line
  }, [scanProgress, isScanning]);

  useEffect(() => {
    const fetchJobPositions = async () => {
      try {
        const response = await axios.get(`${host}/getjdpositions`, {
          headers: {
            Authorization: `Bearer ${accessToken.toString()}`,
          },
        });
        setJobPositions(response.data);
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove("accessToken");
              resolve();
            });
            toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
          } else {
            toast.error(`Error: ${detail}`);
          }
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
      }
    };

    fetchJobPositions();
  }, [accessToken]);

  const handleEmailChange = (e) => {
    setEmailID(e.target.value);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  
  const handleJobPositionChange = async (e) => {
    const selectedPosition = e.target.value;
    console.log(selectedPosition);
    if (selectedPosition !== "") {
      setSelectedJobPosition(selectedPosition);
      setJobDescription("");
      setResponseReport("");
      setResponseScore(0);
      setInterviewID("");
      setIsSubmitting(false);
      setEmailID("");
      setIsVisible(false);
      setIsCheckboxChecked(false);
      setIsConfirmClicked(false);
      setResumeContent("");
      try {
        const response = await axios.get(`${host}/getjd`, {
          headers: {
            Authorization: `Bearer ${accessToken.toString()}`,
          },
          params: {
            job_position: selectedPosition,
          },
        });
        console.log(response.data);
        if (response) {
          setJobDescription(response.data.description);
          setCodingTimeLimit(response.data.coding_time_limit);
          setMCQTimeLimit(response.data.mcq_time_limit);
          setInterviewTimeLimit(response.data.interview_timelimit);
          setSelectedInterviewType(response.data.interview_type);
          setSelectedCodingSet(response.data.Coding_set);
          setSelectedMCQSet(response.data.mcq_set);
          setCodingJudgingCriteria(response.data.Coding_Judge_Criteria);
          setCustomQuestions(response.data.customQuestions || []); // Set the custom questions if available
        }
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove("accessToken");
              resolve();
            });
            toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
          } else {
            toast.error(`Error: ${detail}`);
          }
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
        setJobDescription("");
      }
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();

    const droppedFile = event.dataTransfer.files[0];
    if (
      ![
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ].includes(droppedFile.type)
    ) {
      toast.error("Only PDF or document files are allowed.");
      return;
    }

    setResume(droppedFile);
    setResumeName(droppedFile.name);
    handleFileChange({ target: { files: [droppedFile] } });
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (
      file &&
      ![
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ].includes(file.type)
    ) {
      toast.error("Only PDF or document files are allowed.");
      return;
    }

    setResume(file);
    setResumeName(file.name);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsScanning(true);
    document.documentElement.scrollTop = 0;
    const shuffledQuestions = customQuestions.sort(() => Math.random() - 0.5);
    setCustomQuestions(shuffledQuestions);

    if (!selectedJobPosition || !jobDescription) {
      setIsScanning(false);
      toast.error("Please select a job position and load the job description.");
      return;
    }

    if (!resume) {
      setIsScanning(false);
      toast.error("Please upload a resume.");
      return;
    }
    
    const formData = new FormData();
    formData.append("interview_type", selectedInterviewType);
    formData.append("time_limit", interview_timelimit);
    formData.append("job_role", selectedJobPosition);
    formData.append("job_description", JSON.stringify(jobDescription));
    if (customQuestions.length > 0) {
      formData.append("custom_questions", JSON.stringify(customQuestions));
    }
    formData.append("file", resume);
    try {
      const response = await axios.post(`${host}/parse_resume`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken.toString()}`,
        },
      });
      if (response.data.Error) {
        toast.error(response.data.Error);
        return;
      }
      setResponseReport(response.data.report);
      setResponseScore(response.data.score);
      setEmailID(response.data.email);
      setName(response.data.name);
      setInterviewID(response.data.int_id);
      setResumeContent(response.data.resume_content);
      setIsScanning(false);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    } finally {
      setIsScanning(false);
    }
  };

  if (isSubmitting) {
    return (
      <div class="loader">
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
      </div>
    );
  }

  const handleConfirm = async () => {
    setIsConfirmClicked(true);
    setIsCheckboxChecked(true);
    setIsVisible(!isVisible);
  };

  const sendNotification = async (e) => {
    e.preventDefault();
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailID) {
      toast.warn("Please enter an email ID");
      return;
    }
    if (!name) {
      toast.warn("Please enter a Name");
      return;
    }
    if (!emailPattern.test(emailID)) {
      toast.error("Please enter a valid email ID");
      return;
    }

    const formData = new FormData();
    formData.append("interview_id", interviewID);
    formData.append("name", name);
    formData.append("candidate_email", emailID);
    formData.append("mcq_set", selectedMCQSet);
    formData.append("Coding_Judge_Criteria", codingJudgingCriteria);
    formData.append("coding_set", selectedCodingSet);
    formData.append("mcq_time_limit", MCQTimeLimit);
    formData.append("coding_time_limit", CodingTimeLimit);
    formData.append("start_date", timeFrame.startDate.toISOString());
    formData.append("end_date", timeFrame.endDate.toISOString());

    if (responseScore < 70) {
      formData.append("job_role", selectedJobPosition);
      formData.append("job_description", JSON.stringify(jobDescription));
      if (customQuestions.length > 0) {
        formData.append("custom_questions", JSON.stringify(customQuestions));
      }
      formData.append("resume_content", resumeContent);
      formData.append("score", responseScore);
      formData.append("interview_type", selectedInterviewType);
      formData.append("interview_timelimit", interview_timelimit);
    }
      setIsSubmitting(false);
    try {
      await axios.post(`${host}/store_candidate_data`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken.toString()}`,
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success("Candidate data stored successfully.");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
    finally{
      setIsSubmitting(false);
    }
  };

  const handleCheckboxChange = (e) => {
    setIsCheckboxChecked(e.target.checked);
    if (!e.target.checked) {
      setIsVisible(false);
    }
  };

  const handleTimeFrameChange = (timeFrame) => {
    setTimeFrame(timeFrame);
  };

  return (
    <Authenticator>
      <div className="min-h-screen bg-gradient-to-b from-slate-50 to-slate-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="flex flex-col lg:flex-row gap-4">
            {/* Left Section */}
            <div className="flex-1">
              <div className="backdrop-blur-lg bg-white/80 rounded-3xl shadow-xl p-4 border border-white/20">
                <div className="flex flex-col gap-8">
                  {isScanning && (
                    <div
                      className="absolute inset-0 z-10"
                      style={{
                        background: `linear-gradient(to bottom,
            transparent 0%,
            rgba(0, 150, 136, 0.1) ${scanProgress - 2}%,
            rgba(0, 150, 136, 0.3) ${scanProgress}%,
            rgba(0, 150, 136, 0.1) ${scanProgress + 2}%,
            transparent 100%)`,
                      }}
                    />
                  )}
                  {/* Upload Area */}
                  {!isScanning ? (
                    <div
                      className="space-y-6 w-full relative border-2 border-dashed border-gray-300 rounded-2xl p-4 cursor-pointer "
                      onClick={() =>
                        document.getElementById("fileInput")?.click()
                      }
                      onDrop={handleDrop}
                      onDragOver={handleDragOver}
                    >
                      {resume ? (
                        <div className="transform transition-all duration-500 ease-in-out">
                          <div className="w-32 h-32 mx-auto rounded-full bg-gradient-to-br from-green-400 to-teal-500 flex items-center justify-center shadow-xl shadow-green-500/30 animate-bounce-small">
                            <CheckCircle className="w-16 h-16 text-white animate-scale-check" />
                          </div>
                          <div className="space-y-4 mt-6">
                            <h3 className="text-2xl font-bold text-green-700 text-center animate-fade-in">
                              Resume Successfully Uploaded!
                            </h3>
                            <div className="flex items-center justify-center space-x-2">
                              <FileText className="w-5 h-5 text-green-600" />
                              <p className="text-green-600 font-medium">
                                {resumeName}
                              </p>
                            </div>
                            <div className="text-center bg-green-100 rounded-lg p-3 animate-fade-in">
                              <p className="text-green-700 text-sm">
                                Your resume is ready for AI analysis
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="w-32 h-32 mx-auto rounded-full bg-gradient-to-br from-teal-500 to-green-500 flex items-center justify-center shadow-md shadow-teal-500/30">
                            <Upload className="w-16 h-16 text-white" />
                            <input
                              id="fileInput"
                              type="file"
                              className="hidden"
                              onChange={handleFileChange}
                              accept=".pdf,.docx"
                            />
                          </div>
                          <div className="text-center space-y-4">
                            <h3 className="text-2xl font-bold text-gray-800">
                              AI Resume Scanner
                            </h3>
                            <p className="text-teal-600">
                              {resumeName || "Upload Your Resume"}
                            </p>
                            <div className="flex items-center justify-center space-x-2 text-sm text-teal-500">
                              <span>Advanced parsing technology</span>
                            </div>
                            <p className="text-sm text-gray-500">
                              Supported formats: PDF, DOCX
                            </p>
                          </div>
                        </>
                      )}

                      <div className="grid grid-cols-3 gap-4 text-center text-sm">
                        {[
                          { icon: Scan, text: "AI-Powered Analysis" },
                          { icon: FileText, text: "Smart Parsing" },
                          { icon: CheckCircle2, text: "Instant Results" },
                        ].map(({ icon: Icon, text }) => (
                          <div
                            key={text}
                            className="flex items-center justify-center space-x-2 text-teal-600"
                          >
                            <Icon className="w-4 h-4" />
                            <span>{text}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className="space-y-8 py-8 px-4 relative border-2 border-dashed border-gray-300 rounded-2xl">
                      <div className="flex flex-col items-center space-y-4">
                        <div className="relative w-24 h-24">
                          <div className="absolute inset-0 rounded-full border-4 border-teal-500/20" />
                          <div
                            className="absolute inset-0 rounded-full border-4 border-transparent border-t-teal-500"
                            style={{
                              animation: "spin 1s linear infinite",
                            }}
                          />
                          <FileText className="absolute inset-0 m-auto w-12 h-12 text-teal-500" />
                        </div>
                        <div className="text-center">
                          <h4 className="text-lg font-semibold text-gray-800 mb-2">
                            {scanPhase}
                          </h4>
                        </div>
                      </div>

                      {/* Progress bar */}
                      <div className="w-full bg-gray-200 rounded-full h-2 overflow-hidden">
                        <div
                          className="h-full bg-gradient-to-r from-teal-500 to-green-500 transition-all duration-300"
                          style={{ width: `${scanProgress}%` }}
                        />
                      </div>

                      <div className="grid grid-cols-3 gap-4 text-xs text-gray-500">
                        {[
                          "Document Analysis",
                          "Data Extraction",
                          "AI Processing",
                        ].map((step, index) => (
                          <div
                            key={step}
                            className="flex items-center justify-center space-x-2"
                          >
                            {scanProgress >= (index + 1) * 33 ? (
                              <CheckCircle2 className="w-4 h-4 text-teal-500" />
                            ) : (
                              <div className="w-4 h-4 rounded-full border border-teal-300" />
                            )}
                            <span>{step}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {/* <div
                    onClick={() =>
                      document.getElementById("fileInput")?.click()
                    }
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                  >
                    <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10 group-hover:opacity-100 opacity-0 transition-opacity duration-300" />
                    <div className="relative border-2 border-dashed border-gray-300 group-hover:border-blue-500 rounded-2xl p-12 text-center cursor-pointer transition-all duration-300">
                      <input
                        id="fileInput"
                        type="file"
                        className="hidden"
                        onChange={handleFileChange}
                        accept=".pdf,.docx"
                      />
                      <div className="bg-blue-50 rounded-full w-20 h-20 mx-auto mb-4 flex items-center justify-center group-hover:scale-101 transition-transform duration-300">
                        <Upload className="h-8 w-8 text-blue-500" />
                      </div>
                      <h3 className="text-lg font-semibold text-gray-800 mb-2">
                        {resumeName ? resumeName : "Upload Your Resume"}
                      </h3>
                      <p className="text-sm text-gray-500">
                        Drag and drop your file here or click to browse
                      </p>
                      <p className="text-xs text-gray-400 mt-2">
                        Supported formats: PDF, DOCX
                      </p>
                    </div>
                  </div> */}
                  {/* <AiResumeScanner onFileChange={handleFileChange} /> */}

                  {/* Job Role Select */}
                  <div className="space-y-3">
                    <label className="block text-sm font-medium text-gray-700">
                      Select Position
                    </label>
                    <FormControl className="w-full">
                      <Select
                        value={selectedJobPosition}
                        onChange={handleJobPositionChange}
                        className="bg-white/50 backdrop-blur-sm rounded-xl"
                        MenuProps={{
                          PaperProps: {
                            className: "backdrop-blur-lg bg-white/90",
                            style: {
                              maxHeight: 350,
                              borderRadius: "1rem",
                            },
                          },
                        }}
                      >
                        <MenuItem value="">
                          <em>Choose a position</em>
                        </MenuItem>
                        {jobPositions.map((position, index) => (
                          <MenuItem key={index} value={position}>
                            {position}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  {/* Job Description Card */}
                  {jobDescription && (
                    <div className="space-y-3">
                      <div className="flex items-center justify-between">
                        <label className="block text-sm font-medium text-gray-700">
                          Job Description
                        </label>
                        <Button
                          onClick={toggleExpand}
                          className="text-blue-600 hover:text-blue-700 text-sm font-medium flex items-center gap-1"
                        >
                          {isExpanded ? "Show Less" : "Expand"}
                          <ChevronRight
                            className={`h-4 w-4 transition-transform duration-300 ${
                              isExpanded ? "rotate-90" : ""
                            }`}
                          />
                        </Button>
                      </div>
                      <div className="relative rounded-2xl overflow-hidden">
                        <div
                          className={`${
                            isExpanded ? "h-auto" : "max-h-52 overflow-hidden"
                          }`}
                        >
                          <pre className="font-sans text-sm whitespace-pre-wrap break-words p-6 bg-gray-50/50 backdrop-blur-sm border border-gray-200/50 rounded-2xl">
                            {JSON.stringify(jobDescription, null, 2)}
                          </pre>
                          {!isExpanded && (
                            <div className="absolute bottom-0 left-0 right-0 h-24 bg-gradient-to-t from-white to-transparent" />
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Custom Questions */}
                  {customQuestions.length > 0 && (
                    <div className="space-y-3">
                      <label className="block text-sm font-medium text-gray-700">
                        Screening Questions
                      </label>
                      <div className="bg-white/50 backdrop-blur-sm border border-gray-200/50 rounded-2xl p-6 max-h-96 overflow-y-auto">
                        {customQuestions.map((question, index) => (
                          <div key={index} className="mb-6 last:mb-0">
                            <div className="flex gap-4">
                              <div className="flex-shrink-0 w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center">
                                <span className="text-sm font-medium text-blue-600">
                                  {index + 1}
                                </span>
                              </div>
                              <p className="text-sm text-gray-700 leading-relaxed">
                                {question}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {/* Submit Button */}
                  {/* Display "Analyze Resume" Button Only After Both Job Description and Custom Questions */}
                  {jobDescription && (
                    <Button onClick={handleSubmit}>Analyze Resume</Button>
                  )}
                </div>
              </div>
            </div>

            {/* Right Section - Results Card */}
            {responseReport && (
              <div className="lg:w-2/5">
                <div className="backdrop-blur-lg bg-white/80 rounded-3xl shadow-xl p-4 border border-white/20">
                  <div className="space-y-8">
                    {/* Score Section */}
                    <div className="text-center">
                      <div className="relative inline-flex">
                        <div
                          className={`w-32 h-32 rounded-full flex items-center justify-center
                                     ${
                                       responseScore >= 70
                                         ? "bg-gradient-to-br from-green-400 to-green-600"
                                         : "bg-gradient-to-br from-yellow-400 to-yellow-600"
                                     }`}
                        >
                          <span className="text-4xl font-bold text-white">
                            {responseScore}%
                          </span>
                        </div>
                        <div className="absolute -right-2 -top-2">
                          {responseScore >= 70 ? (
                            <CheckCircle className="w-8 h-8 text-green-500" />
                          ) : (
                            <AlertCircle className="w-8 h-8 text-yellow-500" />
                          )}
                        </div>
                      </div>
                      <h3 className="mt-4 text-xl font-semibold text-gray-900">
                        Match Score
                      </h3>
                    </div>

                    {/* Analysis Report */}
                    <div className="bg-gradient-to-b from-slate-50 to-slate-100 rounded-lg p-4">
                      <h4 className="text-sm font-medium text-slate-700 mb-2">
                        Analysis Report
                      </h4>
                      <p className="text-sm text-slate-600">{responseReport}</p>
                    </div>

                    {/* Warning Message */}
                    {responseScore < 70 && (
                      <div className="flex items-start gap-4 p-6 bg-yellow-50/50 backdrop-blur-sm rounded-2xl border border-yellow-200/50">
                        <div className="flex-shrink-0">
                          <AlertCircle className="w-6 h-6 text-yellow-500" />
                        </div>
                        <div className="flex-1">
                          <h4 className="text-sm font-medium text-yellow-800 mb-2">
                            Low Match Score
                          </h4>
                          <div className="flex items-center gap-3">
                            <input
                              type="checkbox"
                              className="h-4 w-4 rounded border-yellow-300 text-blue-600 focus:ring-blue-500"
                              checked={isCheckboxChecked}
                              onChange={handleCheckboxChange}
                              disabled={isConfirmClicked}
                            />
                            <label className="text-sm text-yellow-700">
                              Proceed with interview despite low match score?
                            </label>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* Interview Process Section */}
                    {(responseScore >= 70 || isCheckboxChecked) && (
                      <div className="space-y-6">
                        {responseScore < 70 && !isConfirmClicked ? (
                          <button
                            className="w-full bg-gradient-to-r from-blue-600 to-indigo-600 hover:from-blue-700 hover:to-indigo-700
                                     text-white py-4 px-6 rounded-2xl transition-all duration-300 font-medium text-sm
                                     shadow-lg hover:shadow-xl hover:scale-[1.02] transform"
                            onClick={handleConfirm}
                          >
                            Confirm Interview Process
                          </button>
                        ) : (
                          <div className="space-y-6">
                            <TimeFrameSelector
                              onDateRangeChange={handleTimeFrameChange}
                            />
                            {(responseScore >= 70 || isCheckboxChecked) && (
                              <form className="space-y-4">
                                <div>
                                  <label className="block text-sm font-medium text-slate-700 mb-2">
                                    Candidate Details
                                  </label>
                                  <div className="space-y-3">
                                    <div className="relative">
                                      <Mail className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-slate-400" />
                                      <input
                                        value={emailID}
                                        type="email"
                                        placeholder="Email address"
                                        onChange={handleEmailChange}
                                        className="w-full pl-10 pr-4 py-2 bg-slate-50 border border-slate-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                      />
                                    </div>
                                    <div className="relative">
                                      <User className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-slate-400" />
                                      <input
                                        value={name}
                                        type="text"
                                        placeholder="Full name"
                                        onChange={handleNameChange}
                                        className="w-full pl-10 pr-4 py-2 bg-slate-50 border border-slate-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <Button
                                  className="w-full bg-blue-600 hover:bg-blue-700 text-white py-3 rounded-lg transition-all"
                                  onClick={sendNotification}
                                >
                                  Schedule Interview
                                </Button>
                              </form>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Modal */}
        {isExpanded && (
          <div className="fixed inset-0 bg-black/30 backdrop-blur-sm flex items-center justify-center z-50">
            <div className="bg-white/90 backdrop-blur-lg rounded-3xl p-8 max-w-3xl w-full mx-4 max-h-[80vh] overflow-y-auto shadow-2xl">
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-xl font-semibold text-gray-900">
                  Complete Job Description
                </h3>
                <Button
                  onClick={toggleExpand}
                  className="text-gray-500 hover:text-gray-700"
                >
                  Close
                </Button>
              </div>
              <pre className="font-sans text-sm whitespace-pre-wrap break-words text-gray-700 p-6 bg-gray-50/50 rounded-2xl">
                {JSON.stringify(jobDescription, null, 2)}
              </pre>
            </div>
          </div>
        )}
      </div>
    </Authenticator>
  );
};
export default CheckResumeOrgAdmin;