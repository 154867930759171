import React from 'react';

const Toolbar = ({ problems, interview_id, onProblemClick,currentProblemId  }) => {
    return (
        <div>
            {problems.map((problem, index) => (
                <div
                    key={problem.id}
                    style={{
                        background: '#374151',
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        margin: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                        cursor: 'pointer',
                        border: problem.id === currentProblemId ? '3px solid green' : 'none'
                    }}
                    onClick={() => onProblemClick(problem.id)}
                >
                    {index + 1}
                </div>
            ))}
        </div>
    );
};

export default Toolbar;
