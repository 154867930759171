import React, { useState, useEffect } from "react";
import '../../css/CandidatePortal.css';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import host from '../../global.jsx';
import { toast } from "react-toastify";
import Timer from './Timer';

export default function Component() {
  const navigate = useNavigate();
const { hr_id, interview_id } = useParams();
const [testData, setTestData] = useState(null);
const [userAnswers, setUserAnswers] = useState(JSON.parse(localStorage.getItem('userAnswers')) || {});
const [attempted, setattempted] = useState(Object.keys(userAnswers).length || 0);
const [questionId, setQuestionNum] = useState(0);
const[MCQTimeLimit, setMCQTimeLimit] = useState(90);
const [stopTest, setStopTest] = useState(false);
const[mcq_startTime, setMCQStartTime] = useState('');

useEffect(() => {
  const getCandidateStatus = async () => {
    try {
      const startDateTime = new Date().toUTCString();
      const response = await axios.get(`${host}/get_mcq_timelimit`, {
        params: {
          interview_id: interview_id,
          startDateTime: startDateTime,
        },
      });
      setMCQStartTime(new Date(response.data.mcq_start_time));
      const timeLimitInSeconds = response.data.mcq_time_limit * 60;
      const startTime = new Date(response.data.mcq_start_time);
      const startTimeInSeconds = Math.floor(startTime.getTime() / 1000);
      const currentTimeInSeconds = Math.floor(Date.now() / 1000);
      const elapsedSeconds = currentTimeInSeconds - startTimeInSeconds;
      const remainingSeconds = timeLimitInSeconds -elapsedSeconds
      setMCQTimeLimit(remainingSeconds);
      if (response.data.mcq_status === "Complete") {
        setStopTest(true);
      }
    } catch (error) {
      toast.error("Failed to fetch candidate status");
      console.error(error);
    }
  };
  
  getCandidateStatus();
}, [interview_id]);


useEffect(() => {
  const fetchData = async () => {
      if(JSON.parse(localStorage.getItem("TestDataLoad"))){
          setTestData(JSON.parse(localStorage.getItem("TestDataLoad")));
          console.log("Previous Data Loaded");
          console.log(JSON.parse(localStorage.getItem('userAnswers')));
      }
      else{
          try {
            const response = await axios.get(`${host}/get_mcqs/${interview_id}`);
              setTestData(response.data);
              localStorage.setItem("TestDataLoad", JSON.stringify(response.data));
              console.log("New Data Loaded");
          } catch (error) {
              console.error("Error fetching data:", error);
          }
      }
  };
  fetchData();
},[interview_id]);

const handleOptionChange = (questionId, selectedOption) => {
  const ans = userAnswers[questionId];
  if(ans === undefined){
    setattempted(attempted+1);
  }
  setUserAnswers({
    ...userAnswers,
    [questionId]: selectedOption
  });
  
  if(userAnswers){
      localStorage.setItem('userAnswers', JSON.stringify(userAnswers));
  }
};

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };
  
  const handleSubmit = async () => {
    const startTimeInSeconds = Math.floor(mcq_startTime.getTime() / 1000);
    const currentTimeInSeconds = Math.floor(Date.now() / 1000);
    const elapsedSeconds = currentTimeInSeconds - startTimeInSeconds;
    const timeTaken = formatTime(elapsedSeconds);
    let totalScore = 0;
    const updatedData = {};
    Object.keys(testData.data).forEach(key => {
        const question = testData.data[key];
        const givenAnswer = userAnswers[key] || '';
        const isCorrect = question.answer === givenAnswer;
        const score = isCorrect ? 1 : 0;
        totalScore += score;
  
        updatedData[key] = {
            ...question,
            given_answer: givenAnswer,
            score: score
        };
    });

    const resultData = {
        interview_id:interview_id,
        hr_id: hr_id,
        data: updatedData,
        total_score: totalScore,
        time_taken: timeTaken,
    };

    try {
      await axios.post(`${host}/save_mcqtest_result`, resultData);
      toast.success("Test submitted successfully!");
      setTimeout(() => {
        navigate(`/candidate/${interview_id}/testhomepage`)
      }, 2000);
    } catch (error) {
        toast.error('Error Submitting Test');
    }
  };

  const handleNextQuestion = (questionId) => {
    if(questionId < (Object.keys(testData.data).length)-1){
        setQuestionNum(questionId+1);
    }
  }

  if (stopTest) {
    return (
      <div className='stoptestpage'>
        <div id='stoptest'>
          <h2>Test For This Candidate ID Is Already Conducted</h2>
          <h3>Only One Attempt Is Allowed</h3>
          <h3>You Can Close This Window Now</h3>
        </div>
      </div>
    );
  }

  return (
    <>
    <Timer timeLimit={MCQTimeLimit} onTimeUp={handleSubmit} />
    <div className="min-h-screen bg-gradient-to-br from-[#3ECFDA] to-[#0952DA] p-4 ">
      <div className="min-h-[80vh] w-full mx-auto bg-white/90 backdrop-blur-lg rounded-3xl shadow-2xl p-4">
        <div className="flex gap-8">
          {/* Main Content Area */}
          <div className="flex-1">
            {testData ? (
              <div key={questionId} className="space-y-4">
                {/* Question Display */}
                <div className="bg-gray-50 rounded-2xl p-4 shadow-inner">
                  <pre className="font-sans whitespace-pre-wrap text-gray-800 text-md">
                    {testData.data[questionId].question}
                  </pre>
                </div>

                {/* Options */}
                <form className="space-y-2">
                  {[1, 2, 3, 4].map((index) => {
                    const option = testData.data[questionId][`option${index}`];
                    const isSelected = userAnswers[questionId] === option;
                    return (
                      <div
                        key={index}
                        className={`transform transition-all duration-200 hover:scale-[1.01] ${
                          isSelected 
                            ? 'bg-gradient-to-r from-[#3ECFDA]/30 to-[#0952DA]/30 shadow-lg' 
                            : 'bg-white hover:bg-gray-50'
                        } rounded-xl shadow`}
                      >
                        <label className="flex items-center cursor-pointer p-2 w-full">
                          <div className={`w-10 h-10 rounded-full flex items-center justify-center font-bold mr-4 ${
                            isSelected 
                              ? 'bg-gradient-to-r from-[#3ECFDA] to-[#0952DA] text-white' 
                              : 'bg-gray-100 text-gray-600'
                          }`}>
                            {String.fromCharCode(64 + index)}
                          </div>
                          <div className="text-gray-700 flex-grow">
                            <input
                              type="radio"
                              name={`question_${questionId}`}
                              value={option}
                              checked={isSelected}
                              className="hidden"
                              onChange={() => handleOptionChange(questionId, option)}
                            />
                            {option}
                          </div>
                        </label>
                      </div>
                    );
                  })}
                </form>
              </div>
            ) : (
              <div className="flex justify-center items-center h-64">
                <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-[#3ECFDA]"></div>
              </div>
            )}
          </div>

          {/* Question Navigator */}
          <div className="w-72 border-l border-[#3ECFDA]/30 pl-6">
            <div className="grid grid-cols-5 gap-3">
              {testData ? (
                Object.keys(testData.data).map((qId) => {
                  const isCurrentQuestion = Number(qId) === questionId;
                  const isAnswered = userAnswers[qId];
                  
                  return (
                    <div
                      key={qId}
                      className={`aspect-square rounded-2xl flex items-center justify-center font-medium text-sm transition-all duration-200 ${
                        isCurrentQuestion
                          ? 'bg-gradient-to-r from-[#3ECFDA] to-[#0952DA] text-white shadow-lg scale-110'
                          : isAnswered
                          ? 'bg-gradient-to-r from-[#28A745] to-[#218838] text-white'
                          : 'bg-white text-gray-600 hover:bg-gray-50'
                      }`}
                      
                    >
                      {Number(qId) + 1}
                    </div>
                  );
                })
              ) : (
                <div className="col-span-5 flex justify-center">
                  <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-[#3ECFDA]"></div>
                </div>
              )}
            </div>
            <div className="flex justify-center mt-8 gap-4 w-full">
            <button 
                    className="w-1/2 bg-gradient-to-r from-[#3ECFDA] to-[#0952DA] text-white py-3 px-6 rounded-xl font-medium shadow-lg hover:shadow-xl transition-all duration-200 hover:scale-105"
                    onClick={() => handleNextQuestion(questionId)}
                  >
                    Next
                  </button>
          <button 
            className="w-1/2 bg-gradient-to-r from-[#3ECFDA] to-[#0952DA] text-white py-3 px-8 rounded-xl font-medium shadow-lg hover:shadow-xl transition-all duration-200 hover:scale-105"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}
