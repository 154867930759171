import React from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import awsExports from '../aws-exports';
import { useNavigate } from 'react-router-dom';
import '../css/loginPage.css';
import { useState,useEffect } from 'react';
import { Brain,Check, Bot, ChartBar } from 'lucide-react';

Amplify.configure(awsExports);

function Login() {
  const [currentFeature, setCurrentFeature] = useState(0);
  const navigate = useNavigate();
  const features = [
    {
      icon: <Brain className="w-8 h-8" />,
      title: "AI-Powered Matching",
      description: "Smart algorithms that connect the right talent with the right opportunities",
      stats: "93% better matches"
    },
    {
      icon: <Bot className="w-8 h-8" />,
      title: "Intelligent Screening",
      description: "Automated behavioral and technical assessments",
      stats: "75% time saved"
    },
    {
      icon: <ChartBar className="w-8 h-8" />,
      title: "Predictive Analytics",
      description: "Data-driven insights for informed hiring decisions",
      stats: "88% better retention"
    }
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentFeature((prev) => (prev + 1) % features.length);
    }, 2000);
    return () => clearInterval(timer);
  }, [features.length]);

  return (
      <div className="min-h-screen w-full flex bg-gray-50 loginpage">

      <div className="hidden lg:flex w-1/2 bg-gradient-to-br from-[#3ECFDA] to-[#0952DA] p-12 relative overflow-hidden">
        <div className="relative z-10 text-white w-full max-w-xl mx-auto flex flex-col justify-center">
          {/* Enhanced logo section */}
          <div className="mb-12 transform hover:scale-105 transition-transform duration-300">
            <div className="flex items-center space-x-3 mb-2">
              <h1 className="text-5xl font-bold tracking-tight">GenieHire</h1>
            </div>
            <p className="text-white/80 text-lg ">AI-Powered Recruitment Suite</p>
          </div>

          {/* Animated headline */}
          <div className="overflow-hidden mb-12">
            <h2 className="text-6xl font-bold leading-tight animate-fade-in-up">
              Hire Smarter,
              <br />
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-white to-white/80">
                Not Harder
              </span>
            </h2>
          </div>

          {/* Enhanced feature carousel */}
          <div className="relative h-64">
            {features.map((feature, index) => (
              <div
                key={index}
                className={`absolute top-0 left-0 w-full p-6 rounded-2xl backdrop-blur-lg bg-white/5 
                           border border-white/10 transition-all duration-300 transform 
                           ${index === currentFeature 
                             ? 'opacity-100 translate-x-0' 
                             : 'opacity-0 translate-x-8'}`}
              >
                <div className="flex items-start space-x-4 mb-4">
                  <div className="p-3 bg-white/10 rounded-xl">
                    {feature.icon}
                  </div>
                  <div>
                    <h3 className="text-2xl font-semibold mb-2">{feature.title}</h3>
                    <p className="text-white/80 text-lg mb-4">{feature.description}</p>
                    <div className="flex items-center space-x-2 text-sm">
                      <Check className="w-5 h-5 text-[#3ECFDA]" />
                      <span className="font-semibold">{feature.stats}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="w-full lg:w-1/2 flex items-center justify-center p-8">
        <div className="w-full max-w-md">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-gray-900 mb-2">Welcome Back</h2>
            <p className="text-gray-600">Sign in to your GenieHire Portal</p>
          </div>

            <Authenticator>
              {({ signOut, user }) => {
                if (user) {
                  navigate('/role-redirect');
                  return null;
                }
                return null;
              }}
            </Authenticator>
        </div>
      </div>
      </div>
  );
}

export default Login;

