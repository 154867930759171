import React, { useEffect, useState } from "react";
import { Authenticator, Button } from "@aws-amplify/ui-react";
import axios from "axios";
import host from "../../global";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import "../../css/orgadmin.css";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Dialog, DialogContent, DialogActions } from "@mui/material";

const CreatedJDs = () => {
  const [jobDescriptions, setJobDescriptions] = useState([]);
  const [MCQTimeLimit, setMCQTimeLimit] = useState(0);
  const [CodingTimeLimit, setCodingTimeLimit] = useState(0);
  const [isMCQIncluded, setIsMCQIncluded] = useState(false);
  const [isCodingIncluded, setIsCodingIncluded] = useState(false);
  const [codingSets, setCodingSets] = useState([]);
  const [codingJudgingCriteria, setCodingJudgingCriteria] = useState("NA");
  const [selectedCodingSet, setSelectedCodingSet] = useState("NA");
  const [selectedInterviewType, setSelectedInterviewType] = useState("");
  const [custom_questions, setcustom_questions] = useState("");
  const [interview_timelimit, setInterviewTimeLimit] = useState(0);
  const [selectedSet, setSelectedSet] = useState("NA");
  const [sets, setSets] = useState([]);
  const [showEditJDForm, setShowEditJDForm] = useState(false);
  const [jbrole, setJbrole] = useState("");
  const [jobdesc, setJobdesc] = useState("");
  const [isUpdatingJD, setIsUpdatingJD] = useState(false);
  const [PrevJbrole, setPrevJbrole] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const accessToken = Cookies.get("accessToken");

  const fetchCombinedData = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${host}/orgadmin/get_jd_data`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setJobDescriptions(data.job_descriptions);
      setSets(data.set_names);
      setCodingSets(Object.keys(data.coding_sets));
      setIsLoading(false);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCombinedData();    // eslint-disable-next-line
  }, []);

  const handleJbroleChange = (e) => {
    setJbrole(e.target.value);
  };

  const handleNumberChange = (e) => {
    setInterviewTimeLimit(e.target.value);
  };

  const handleMCQTimeLimitChange = (e) => {
    setMCQTimeLimit(e.target.value);
  };

  const handleCodingTimeLimitChange = (e) => {
    setCodingTimeLimit(e.target.value);
  };

  const handleInterviewTypeChange = (e) => {
    setSelectedInterviewType(e.target.value);
  };

  const handleSetChange = (e) => {
    setSelectedSet(e.target.value);
  };

  const handleCodingJudgingCriteriaChange = (e) => {
    setCodingJudgingCriteria(e.target.value);
  };

  const handleInputChange = (index, event) => {
    const newQuestions = [...custom_questions];
    newQuestions[index] = event.target.value;
    setcustom_questions(newQuestions);
  };

  const handleIncludeMCQ = () => {
    if (isMCQIncluded) {
      setIsMCQIncluded(false);
      setSelectedSet("NA");
      setMCQTimeLimit(0);
    } else {
      setIsMCQIncluded(true);
    }
  };

  const handleIncludeCoding = () => {
    if (isCodingIncluded) {
      setIsCodingIncluded(false);
      setSelectedCodingSet("NA");
      setCodingJudgingCriteria("NA");
      setCodingTimeLimit(0);
    } else {
      setIsCodingIncluded(true);
    }
  };

  const handleAddJD = () => {
    navigate(`/org-admin/createaijd`);
  };

  const handleDeleteJD = async (e) => {
    e.preventDefault();
    if (
      window.confirm("Are you sure you want to delete this job description?")
    ) {
      try {
        const response = await axios.delete(`${host}/orgadmin/deletejd`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            jb_role: PrevJbrole,
          },
        });
        if (response.status === 200) {
          toast.success("JD Deleted Successfully!");
          await fetchCombinedData();
          setShowEditJDForm(false);
          setPrevJbrole("");
        }
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove("accessToken");
              resolve();
            });
            toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
          } else {
            toast.error(`Error: ${detail}`);
          }
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
      }
    }
  };

  const closeEditJD = () => {
    setShowEditJDForm(false);
  };

  const handleEditJDRequest = async (jobrole) => {
    setIsUpdatingJD(true);
    try {
      const response = await axios.get(`${host}/getjd`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          job_position: jobrole,
        },
      });
      setShowEditJDForm(true);
      if (response) {
        setPrevJbrole(response.data.jobrole);
        setJbrole(response.data.jobrole);
        setJobdesc(response.data.description);
        setInterviewTimeLimit(response.data.interview_timelimit);
        setSelectedInterviewType(response.data.interview_type);
        setcustom_questions(response.data.customQuestions);
        if (response.data.mcq_set !== "NA") {
          setIsMCQIncluded(true);
          setMCQTimeLimit(response.data.mcq_time_limit);
          setSelectedSet(response.data.mcq_set);
        } else {
          setIsMCQIncluded(false);
        }
        if (response.data.Coding_set !== "NA") {
          setIsCodingIncluded(true);
          setCodingTimeLimit(response.data.coding_time_limit);
          setCodingJudgingCriteria(response.data.Coding_Judge_Criteria);
          setSelectedCodingSet(response.data.Coding_set);
        } else {
          setIsCodingIncluded(false);
        }
      }
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  };

  const updateJDRequest = async (e) => {
    e.preventDefault();
    try {
      console.log(custom_questions);
      const response = await axios.post(
        `${host}/orgadmin/updatejd`,
        {
          jobrole: jbrole,
          selectedInterviewType: selectedInterviewType,
          interview_timelimit: interview_timelimit,
          selectedSet: selectedSet,
          MCQTimeLimit: MCQTimeLimit,
          selectedCodingSet: selectedCodingSet,
          CodingTimeLimit: CodingTimeLimit,
          codingJudgingCriteria: codingJudgingCriteria,
          custom_questions: custom_questions,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response) {
        toast.success("JD Updated Successfully!");
        setShowEditJDForm(false);
        setPrevJbrole("");
        await fetchCombinedData();
      }
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  };

  if (isLoading) {
    return <div class="loader">
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
</div>
  }

  return (
    <>
      <Authenticator>
        <Dialog open={showEditJDForm} onClose={() => setShowEditJDForm(false)}>
          <DialogContent>
            <div className="JDFormBlock">
              <form className="JDForm">
                <div className="JDSection1">
                  <label>Job Title: </label>
                  <input
                    type="text"
                    name="jobrole"
                    value={jbrole}
                    onChange={handleJbroleChange}
                    required
                    style={{ width: "fit-content" }}
                  />
                  <label>Job Description: </label>
                  {jobdesc && (
                    <pre
                      style={{
                        fontFamily: "Poppins",
                        whiteSpace: "pre-wrap",
                        wordWrap: "break-word",
                        padding: "10px",
                        border: "solid 1px gray",
                        borderRadius: "8px",
                        position: "relative",
                        maxHeight: "350px",
                        overflowY: "scroll",
                      }}
                    >
                      {JSON.stringify(jobdesc, null, 2)}
                    </pre>
                  )}
                  <>
                    {custom_questions && (
                      <>
                        <label>Custom Questions: </label>
                        <div
                          style={{
                            fontFamily: "Poppins",
                            padding: "10px",
                            border: "solid 1px gray",
                            borderRadius: "8px",
                            position: "relative",
                            maxHeight: "350px",
                            overflowY: "scroll",
                          }}
                        >
                          {custom_questions.map((question, index) => (
                            <div key={index} style={{ marginBottom: "10px" }}>
                              <input
                                type="text"
                                value={question}
                                onChange={(e) => handleInputChange(index, e)}
                                style={{
                                  width: "100%",
                                  padding: "8px",
                                  borderRadius: "4px",
                                  border: "1px solid gray",
                                  fontFamily: "Poppins",
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </>
                </div>
                <div>
                  <div className="JDSection2">
                    <div
                      className="selectInterviewType"
                      style={{ alignContent: "center" }}
                    >
                      <label>Type of Interview: </label>
                      <select
                        value={selectedInterviewType}
                        onChange={handleInterviewTypeChange}
                        required
                        style={{ width: "fit-content" }}
                      >
                        <option value="">Select Type Of Interview</option>
                        <option value="Technical Interview">
                          Technical Interview
                        </option>
                      </select>
                    </div>
                    <div className="setNumberOfQuestions">
                      <label>Interview Time Limit (Mins):</label>
                      <input
                        placeholder="0"
                        type="number"
                        min="1"
                        value={interview_timelimit}
                        onChange={handleNumberChange}
                        required
                        style={{ width: "fit-content" }}
                      />
                    </div>
                  </div>
                  <hr></hr>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "10px",
                    }}
                  >
                    <div
                      className="checkbox-container"
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <input
                        type="checkbox"
                        checked={isMCQIncluded}
                        onChange={handleIncludeMCQ}
                      />
                      <label id="checkboxLabel">Include MCQ Test</label>
                    </div>

                    {isMCQIncluded && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              alignItems: "center",
                            }}
                          >
                            <label>Select Set For MCQ Test:</label>
                            <select
                              id="JDsetsSelect"
                              value={selectedSet}
                              onChange={handleSetChange}
                              required
                            >
                              <option>Select a set</option>
                              {sets.map((setName, index) => (
                                <option key={index}>{setName}</option>
                              ))}
                            </select>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              alignItems: "center",
                            }}
                          >
                            <label>Set MCQ Time Limit (Mins):</label>
                            <input
                              type="number"
                              value={MCQTimeLimit}
                              onChange={handleMCQTimeLimitChange}
                              required
                            ></input>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <hr></hr>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "10px",
                    }}
                  >
                    <div
                      className="checkbox-container"
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <input
                        type="checkbox"
                        checked={isCodingIncluded}
                        onChange={handleIncludeCoding}
                      />
                      <label id="checkboxLabel">Include Coding Test</label>
                    </div>
                    {isCodingIncluded && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              alignItems: "center",
                            }}
                          >
                            <label>Select Coding Set:</label>
                            <select
                              id="JDsetsSelect"
                              value={selectedCodingSet}
                              onChange={(e) =>
                                setSelectedCodingSet(e.target.value)
                              }
                              required
                            >
                              <option value="">Select Set</option>
                              {codingSets.map((setName, index) => (
                                <option key={index} value={setName}>
                                  {setName}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              alignItems: "center",
                            }}
                          >
                            <label>Set Coding Time Limit (Mins):</label>
                            <input
                              placeholder="0"
                              min="1"
                              type="number"
                              value={CodingTimeLimit}
                              onChange={handleCodingTimeLimitChange}
                              required
                            ></input>
                          </div>

                          <div className="selectCodingCriteriaAndSet">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                                alignItems: "center",
                              }}
                            >
                              <label>Set Coding Judging Criteria:</label>
                              <select
                                id="JDsetsSelect"
                                value={codingJudgingCriteria}
                                onChange={handleCodingJudgingCriteriaChange}
                                required
                              >
                                <option value="">Select Criteria</option>
                                <option value="0">Judge 0</option>
                                <option value="1">Judge 1</option>
                                <option value="2" disabled>
                                  Judge 2
                                </option>
                                <option value="3" disabled>
                                  Judge 3
                                </option>
                                <option value="4" disabled>
                                  Judge 4
                                </option>
                                <option value="5" disabled>
                                  Master Judge
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <hr style={{ marginTop: "25px" }}></hr>
                      </>
                    )}
                  </div>
                  <hr></hr>
                </div>
              </form>
            </div>
          </DialogContent>
          <DialogActions>
            {isUpdatingJD && (
              <>
                <Button
                  variant="contained"
                  id="createJDBtn"
                  onClick={updateJDRequest}
                >
                  Update JD
                </Button>
                <Button variant="contained" onClick={handleDeleteJD}>
                  Delete JD
                </Button>
                <Button variant="contained" onClick={closeEditJD}>
                  Close
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
        <div className="table-container">
          <div className="table-header">
            <h1>Created Job Descriptions</h1>
            <Button
              className="addjd"
              onClick={handleAddJD}
              style={{ float: "right", marginBottom: "16px" }}
            >
              Add New JD
            </Button>
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              {jobDescriptions.length > 0 ? (
                <Table>
                  <Thead>
                    <Tr>
                      <Th>Job Role</Th>
                      <Th>Interview Type</Th>
                      <Th>Interview Duration</Th>
                      <Th>Tests Included</Th>
                      <Th>Created Date</Th>
                      <Th>Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {jobDescriptions.map((jd) => (
                      <Tr key={jd.jdid}>
                        <Td
                          style={{ whiteSpace: "normal" }}
                          onClick={() => handleEditJDRequest(jd.jobrole)}
                        >
                          <strong>{jd.jobrole}</strong>
                        </Td>
                        <Td style={{ whiteSpace: "normal" }}>
                          {jd.interview_type}
                        </Td>
                        <Td>{jd.interview_timelimit} Minutes</Td>
                        <Td>
                          <div>Interview</div>
                          <div>{jd.mcq_set !== "NA" && <span>MCQ</span>}</div>
                          <div>
                            {jd.Coding_set !== "NA" && <span>Coding</span>}
                          </div>
                        </Td>
                        <Td>{jd.postingDate}</Td>
                        <Td>
                          <Button
                            onClick={() => handleEditJDRequest(jd.jobrole)}
                          >
                            Edit
                          </Button>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              ) : (
                <div class="loader">
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
</div>
              )}
            </div>
          </div>
        </div>{" "}
      </Authenticator>
    </>
  );
};

export default CreatedJDs;
