import React from 'react';
import { User, ChevronRight } from 'lucide-react';

const Header = ({ candidateName, roundName }) => {
  return (
    <header className="relative">
      {/* Gradient background with blur effect */}
      <div className="absolute inset-0 bg-gradient-to-r from-[#3ECFDA]/10 to-[#0952DA]/10 backdrop-blur-sm" />
      
      <div className="relative px-6 py-2 flex items-center justify-between">
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-2">
            <div className="w-8 h-8 rounded-full bg-gradient-to-r from-[#3ECFDA] to-[#0952DA] flex items-center justify-center">
              <User className="w-4 h-4 text-white" />
            </div>
            <div>
              <p className="text-gray-800 font-semibold">{candidateName}</p>
            </div>
          </div>
        </div>

        {/* Center - Round info with decorative elements */}
        {roundName && (
        <div className="absolute left-1/2 -translate-x-1/2 flex items-center gap-2">
          <div className="flex items-center gap-2 px-4 py-1 bg-white/80 rounded-full shadow-sm backdrop-blur-sm">
            <div className="flex items-center gap-1 text-gray-600">
              <span className="font-medium">Current Round:</span>
              <ChevronRight className="w-4 h-4" />
              <span className="font-semibold bg-gradient-to-r from-[#3ECFDA] to-[#0952DA] bg-clip-text text-transparent">
                {roundName}
              </span>
            </div>
          </div>
        </div>
        )}

        {/* Decorative elements */}
        <div className="absolute bottom-0 left-0 right-0 h-[1px] bg-gradient-to-r from-transparent via-[#3ECFDA]/20 to-transparent" />
      </div>
    </header>
  );
};

export default Header;