import Header from './Header';
import Sidebar from './Sidebar';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import { fetchAuthSession, signOut } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import '../../css/HRPortal.css'
import { Outlet } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import '../../css/HRPortal.css';
import Cookies from 'js-cookie';
import { ToastContainer } from 'react-toastify';
import { LogOut } from 'lucide-react';

const Layout = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const orgadmin_accessToken = Cookies.get('accessToken');

  useEffect(() => {
    setIsLoading(true);
    const checkSession = async () => {
      try {
        const session = await fetchAuthSession();
        const { accessToken } = session.tokens ?? {};
        const groups = accessToken.payload['cognito:groups'] ?? [];
        if (groups.includes('Company_Admin') && orgadmin_accessToken === accessToken.toString()) {
          setIsAuthenticated(true);
        } else {
          await new Promise((resolve) => {
            Cookies.remove('accessToken');
            resolve();
          });        
          await signOut();
          setIsAuthenticated(false);
        }
      } catch (error) {
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    };

    checkSession();
  }, [location.pathname,orgadmin_accessToken]);

  const handleSignOut = async () => {
    try {
        Cookies.remove('accesstoken');
        await signOut();
        navigate('/');
    } catch (error) {
        console.error('Error during sign out:', error);
    }
};

  if (isLoading) {
    return <div class="loader">
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
</div>
  }

  if (!isAuthenticated) {
    return (
<div className="min-h-screen flex items-center justify-center bg-gray-50 p-4">
      <div className="max-w-md w-full">
        <div className="relative overflow-hidden rounded-xl shadow-2xl p-8">
          <div 
            className="absolute inset-0 z-0"
            style={{
              background: 'linear-gradient(142.42deg, #3ECFDA 38.71%, #0952DA 140.16%)'
            }}
          />          
          <div className="relative z-10 text-center space-y-6">
            <div className="mx-auto bg-white/20 rounded-full p-4 w-fit backdrop-blur-sm">
              <LogOut className="w-8 h-8 text-white" />
            </div>
            <div className="space-y-2">
              <h2 className="text-2xl font-bold text-white">Session Expired</h2>
              <p className="text-white/80">
                Please login again to continue your session
              </p>
            </div>
            <button 
              onClick={() => handleSignOut()}
              className="w-full bg-white text-blue-600 font-semibold py-3 px-6 rounded-lg 
                       transform transition-all duration-200 hover:scale-105 
                       hover:shadow-lg active:scale-95"
            >
              Return to Login
            </button>
          </div>
        </div>
      </div>
    </div>
    );
  }

  return (
<Authenticator>
<ToastContainer/>
  <div style={{ display: 'flex', height: '100vh', maxHeight: '100vh', overflow: 'hidden' }}>
    <Sidebar />
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
      <Header/>
      <main className="main-content" style={{ flex: 1, overflowY: 'auto'}}>
        <Outlet />
      </main>
    </div>
  </div>
</Authenticator>

  );
};

export default Layout;
