import { ArrowLeft, Download, Trash2, CheckCircle } from 'lucide-react';
import {
  Box,
  Typography,
  IconButton,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  Avatar,
} from "@mui/material";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import { SquareMousePointer } from 'lucide-react';
import {useLocation, useNavigate, useParams } from "react-router-dom";
import MCQReport from "../Reports/MCQResult";
import { useState, useEffect} from "react";
import axios from "axios";
import host from "../../global";
import CodingTestReport from "../Reports/CodingResult";
import InterviewEvaluationReport from "../Reports/InterviewResult";
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { styled } from '@mui/material/styles';
import StepLabel from '@mui/material/StepLabel';
import PDFGenerator from "../Reports/PrintReport";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie'

export default function CandidateAssessment() {
  const { interview_id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [openModal, setOpenModal] = useState({
    mcq: false,
    coding: false,
    interview: false,
  });
  const toggleModal = (modal) => {
    setOpenModal((prev) => ({ ...prev, [modal]: !prev[modal] }));
  };
  const [candidateData, setCandidateData] = useState({
    mcq_status: null,
    coding_status: null,
    name: "",
    job_role: "",
    invited_date: null,
  });
  const [stages, setStages] = useState([]);
  const [completedStages, setCompleteStages] = useState(0);
  const [scores, setScores] = useState([]);
  const [averageScore, setAverageScore] = useState(0);
  const accessToken = Cookies.get('accessToken');
  const navigate = useNavigate();
  const location = useLocation();
  const { page, job_position, combined_status } = location.state || {};

  const handleDelete = async () => {
    try {
      const response = await axios.delete(`${host}/deletecandidate_data`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          interview_id: interview_id,
        }
      });
      toast.success(response.data.message);
      setTimeout(() => {
        navigate(`/hr/candidatereports`, {
          state: {
            page,
            job_position,
            combined_status,
          },
        });      }, 2000);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove('accessToken');
              resolve();
            });
            toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
        } else {
            toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error('An unexpected error occurred. Please try again.');
    }
    }
  };

  useEffect(() => {
    const fetchCandidateData = async () => {
      try {
        const response = await axios.get(
          `${host}/check_test_progress/${interview_id}`
        );
        setCandidateData(response.data);
          const fetchedStages = [
            { name: "Invited for Test", status: "Complete" },
            { name: "MCQ", status: response.data.mcq_status },
            { name: "Coding", status: response.data.coding_status },
            { name: "AI Interview", status: response.data.interview_status },
            { name: "Test Complete", status: "Incomplete" },
          ];
          const validStages = fetchedStages.filter(
            (stage) =>
              stage.status !== "NA" &&
              stage.status !== undefined &&
              stage.status !== null
          );
          const allOthersComplete = validStages
          .filter(stage => stage.name !== 'Test Complete')
          .every(stage => stage.status === 'Complete' || stage.status === 'Passed');
            if (allOthersComplete) {
          const testCompleteStage = validStages.find(stage => stage.name === 'Test Complete');
          if (testCompleteStage) {
            testCompleteStage.status = 'Complete';
          }
        }
          setStages(validStages)
          console.log(validStages)
          const completedStages = validStages.reduce((acc, stage) => {
            return acc + (stage.status !== "Incomplete" ? 1 : 0);
          }, 0);
          const adjustedCompletedStages =
            completedStages === validStages.length - 1
              ? completedStages + 1
              : completedStages;
          setCompleteStages(adjustedCompletedStages);
          setIsLoading(false);
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
              await new Promise((resolve) => {
                Cookies.remove('accessToken');
                resolve();
              });
              toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
              toast.warning(`Not Found: ${detail}`);
          } else {
              toast.error(`Error: ${detail}`);
          }
      } else {
          toast.error('An unexpected error occurred. Please try again.');
      }
      }
    };

    const fetchScores = async () => {
      try {
        const response = await axios.get(
          `${host}/get_testscores`, 
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params: {
              interview_id: interview_id,
            },
          });
          console.log(response)
        const formattedScores = Object.keys(response.data)
          .filter((key) => response.data[key] !== "NA")
          .reduce((acc, key) => {
            const value = parseFloat(response.data[key]);
            acc[key] = value.toFixed(2);
            return acc;
          }, {});

        setScores(formattedScores);
        const validValues = Object.keys(response.data)
          .filter((key) => response.data[key] !== "NA")
          .map((key) => parseFloat(response.data[key]));

        const total = validValues.reduce((acc, value) => acc + value, 0);
        const average = validValues.length > 0 ? total / validValues.length : 0;

        setAverageScore(average);
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
              await new Promise((resolve) => {
                Cookies.remove('accessToken');
                resolve();
              });
              toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
              toast.warning(`Not Found: ${detail}`);
          } else {
              toast.error(`Error: ${detail}`);
          }
      } else {
          toast.error('An unexpected error occurred. Please try again.');
      }
      }
    };

    fetchCandidateData();
    fetchScores();
  }, [interview_id,accessToken]);

  const ColorlibConnector = styled(StepConnector)(({ theme, statuses, index }) => {
    const status = statuses[index];
    return {
      [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
      },
      [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
          backgroundImage:
            'linear-gradient(95deg, rgb(34 197 94), rgb(34 197 94), rgb(34 197 94))',
        },
      },
      [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor: (status === 'Complete' || status === 'Passed') ? 'blue' : 'gray',
        borderRadius: 1,
      },
    };
  });

  if (isLoading) {
    return <div class="loader">
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
</div>
  }

  return (
    <Box minHeight="100vh" p={4}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={6}
      >
        <Box display="flex" alignItems="center" gap={2}>

          <IconButton className="no-print" onClick={() =>         navigate(`/hr/candidatereports`, {
  state: {
    page,
    job_position,
    combined_status,
  },
})}>
            <ArrowLeft />
          </IconButton>
          <Typography
            style={{ fontFamily: "Poppins" }}
            variant="h4"
            fontWeight="bold"
          >
            {candidateData.name}
          </Typography>
          <Divider orientation="vertical" flexItem sx={{ bgcolor: "gray" }} />
          <Box display="flex" flexDirection="column">
            <Typography
              style={{ fontFamily: "Poppins" }}
              variant="body2"
              color="gray"
            >
              Job Role
            </Typography>
            <Typography
              style={{ fontFamily: "Poppins" }}
              variant="h6"
              color="black"
              fontWeight="bold"
            >
              {candidateData.job_role}
            </Typography>
          </Box>
          {candidateData.invited_date && (
            <>
              <Divider
                orientation="vertical"
                flexItem
                sx={{ bgcolor: "gray" }}
              />
              <Box display="flex" flexDirection="column">
                <Typography
                  style={{ fontFamily: "Poppins" }}
                  variant="body2"
                  color="gray"
                >
                  Invited Date
                </Typography>
                <Typography
                  style={{ fontFamily: "Poppins" }}
                  variant="h6"
                  color="black"
                  fontWeight="bold"
                >
                  {candidateData.invited_date}
                </Typography>
              </Box>
            </>
          )}
        </Box>
        <Box textAlign="right">
        <IconButton onClick={() => toggleModal("PDF")}>
                 <Download />
          </IconButton>
          <Dialog
                          open={openModal.PDF}
                          onClose={() => toggleModal("PDF")}
                          fullWidth
                          maxWidth="md"
                        >
                          <DialogTitle>
                            <IconButton
                              onClick={() => toggleModal("PDF")}
                              edge="start"
                              color="inherit"
                              className="no-print"
                            >
                              <ArrowLeft />
                            </IconButton>
                          </DialogTitle>
                          <DialogContent>
                            <PDFGenerator
                            name={candidateData.name}
                            role = {candidateData.job_role}
                            scores = {scores} 
                            stages = {stages} 
                            interview_id= {interview_id}/>
                          </DialogContent>
                        </Dialog>
          <IconButton>
            <Trash2 onClick={handleDelete}/>
          </IconButton>
        </Box>
      </Box>
    <Box mb={8}>
      <Stack sx={{ width: '100%' }} spacing={4}>
      <Stepper alternativeLabel activeStep={1} connector={<ColorlibConnector statuses={completedStages} />}>
      {stages.map((stage, index) => (
        <Step key={stage.name} completed={stage.status === 'Complete' || stage.status === 'Passed'}>
          <StepLabel
            StepIconComponent={(props) => (
              <VerifiedRoundedIcon style={{ color: (stage.status === 'Complete' || stage.status === 'Passed') ? 'rgb(34 197 94)' : 'gray' }} {...props} />
            )}
          >
            {stage.name}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
    </Stack>
    </Box>
      <Grid container spacing={4}>
      <Grid item xs={12} md={6} lg={8}>
  <Card sx={{ borderColor: "#444", border: "solid 1px rgba(217, 217, 217, 1)" }}>
    <CardHeader title="Test Score & Reports" sx={{ fontFamily: "Poppins" }} />
    <CardContent>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Card
            sx={{
              background: "rgba(217, 217, 217, 1)",
              border: "solid 1px rgba(217, 217, 217, 1)",
              height: "100%",
            }}
          >
            <CardContent>
              <Typography style={{ fontFamily: "Poppins" }} color="black" mb={2}>
                Total Average Score
              </Typography>
              <p className="custom-h3">
                {averageScore.toFixed(2)}%
              </p>
            </CardContent>
          </Card>
        </Grid>

        {candidateData.mcq_status !== "NA" && (
          <Grid item xs={6}>
            <Card
              sx={{
                border: "solid 1px rgba(217, 217, 217, 1)",
                height: "100%",
              }}
            >
              <CardContent sx={{ display: "flex", justifyContent: "space-between", flexDirection: "column", height: "100%" }}>
                <Box mb={2}>
                  <Typography style={{ fontFamily: "Poppins" }} color="gray" mb={2}>
                    Multiple Choice Questions
                  </Typography>
                  <p className="custom-h3">
                    {scores.mcq_percent ? (
                      <>
                        <span>{`${scores.mcq_percent}%`}</span>
                        <Box display="flex" justifyContent="flex-end" mt="auto">
                          <IconButton
                            sx={{ alignSelf: "flex-end", marginBottom: "-20px" }}
                            onClick={() => toggleModal("mcq")}
                          >
                            <SquareMousePointer />
                          </IconButton>
                        </Box>
                      </>
                    ) : (
                      "NA"
                    )}
                  </p>
                </Box>

                {/* MCQ Dialog */}
                <Dialog open={openModal.mcq} onClose={() => toggleModal("mcq")} fullWidth maxWidth="md">
                  <DialogTitle>
                    <IconButton onClick={() => toggleModal("mcq")} edge="start" color="inherit" className="no-print">
                      <ArrowLeft />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent>
                    <MCQReport name={candidateData.name} mcq_percent={scores.mcq_percent} />
                  </DialogContent>
                </Dialog>
              </CardContent>
            </Card>
          </Grid>
        )}

        {candidateData.coding_status !== "NA" && (
          <Grid item xs={6}>
            <Card
              sx={{
                border: "solid 1px rgba(217, 217, 217, 1)",
                height: "100%", // Ensure the card has the same height
              }}
            >
              <CardContent sx={{ display: "flex", justifyContent: "space-between", flexDirection: "column", height: "100%" }}>
                <Box mb={2}>
                  <Typography style={{ fontFamily: "Poppins" }} color="gray" mb={2}>
                    Coding Test
                  </Typography>
                  <p className="custom-h3">
                    {scores.coding_percent ? (
                      <>
                        <span>{`${scores.coding_percent}%`}</span>
                        <Box display="flex" justifyContent="flex-end" mt="auto">
                          <IconButton
                            sx={{ alignSelf: "flex-end", marginBottom: "-20px" }}
                            onClick={() => toggleModal("coding")}
                          >
                            <SquareMousePointer />
                          </IconButton>
                        </Box>
                      </>
                    ) : (
                      "NA"
                    )}
                  </p>
                </Box>

                {/* Coding Dialog */}
                <Dialog open={openModal.coding} onClose={() => toggleModal("coding")} fullWidth maxWidth="md">
                  <DialogTitle>
                    <IconButton onClick={() => toggleModal("coding")} edge="start" color="inherit" className="no-print">
                      <ArrowLeft />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent>
                    <CodingTestReport name={candidateData.name} coding_percent={scores.coding_percent} />
                  </DialogContent>
                </Dialog>
              </CardContent>
            </Card>
          </Grid>
        )}

        {candidateData.interview_status !== "NA" && (
          <Grid item xs={6}>
            <Card
              sx={{
                border: "solid 1px rgba(217, 217, 217, 1)",
                height: "100%",
              }}
            >
              <CardContent sx={{ display: "flex", justifyContent: "space-between", flexDirection: "column", height: "100%" }}>
                <Box mb={2}>
                  <Typography style={{ fontFamily: "Poppins" }} color="gray" mb={2}>
                    AI Interview
                  </Typography>
                  <p className="custom-h3">
                    {scores.interview_percent ? (
                      <>
                        <span>{`${scores.interview_percent}%`}</span>
                        <Box display="flex" justifyContent="flex-end" mt="auto">
                          <IconButton
                            sx={{ alignSelf: "flex-end", marginBottom: "-20px" }}
                            onClick={() => toggleModal("interview")}
                          >
                            <SquareMousePointer />
                          </IconButton>
                        </Box>
                      </>
                    ) : (
                      "NA"
                    )}
                  </p>
                </Box>

                {/* Interview Dialog */}
                <Dialog open={openModal.interview} onClose={() => toggleModal("interview")} fullWidth maxWidth="md">
                  <DialogTitle>
                    <IconButton onClick={() => toggleModal("interview")} edge="start" color="inherit" className="no-print">
                      <ArrowLeft />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent>
                    <InterviewEvaluationReport name={candidateData.name} interview_percent={scores.interview_percent} />
                  </DialogContent>
                </Dialog>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </CardContent>
  </Card>
</Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Card sx={{ borderColor: "#444",border: "solid 1px rgba(217, 217, 217, 1)" }}>
            <CardHeader
              title="Honesty Monitor"
              sx={{ fontFamily: "Poppins" }}
            />
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Avatar style={{ width: "100px", height: "100px" }}>{candidateData.name[0]}</Avatar>
            </CardContent>
            <CardContent style={{ fontFamily: "Poppins" }}>
              <Box mb={2} display="flex" justifyContent="space-between">
                <Typography style={{ fontFamily: "Poppins" }} color="gray">
                  Device Used
                </Typography>
                <Typography>Desktop</Typography>
              </Box>
              <Divider sx={{ bgcolor: "#555" }} />
              <Box my={2} display="flex" justifyContent="space-between">
                <Typography style={{ fontFamily: "Poppins" }} color="gray">
                  Webcam Enabled?
                </Typography>
                <CheckCircle style={{ color: "green" }} />
              </Box>
              <Divider sx={{ bgcolor: "#555" }} />
              <Box my={2} display="flex" justifyContent="space-between">
                <Typography style={{ fontFamily: "Poppins" }} color="gray">
                  Full Screen mode always active?
                </Typography>
                <CheckCircle style={{ color: "green" }} />
              </Box>
              <Divider sx={{ bgcolor: "#555" }} />
              <Box my={2} display="flex" justifyContent="space-between">
                <Typography style={{ fontFamily: "Poppins" }} color="gray">
                  Mouse always in Test Window?
                </Typography>
                <CheckCircle style={{ color: "green" }} />
              </Box>
              <Divider sx={{ bgcolor: "#555" }} />
              <Box my={2} display="flex" justifyContent="space-between">
                <Typography style={{ fontFamily: "Poppins" }} color="gray">
                  Filled out only once from IP address
                </Typography>
                <CheckCircle style={{ color: "green" }} />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}