import Editor from "@monaco-editor/react";
import { debounce } from "lodash";
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Timer from "./Timer";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import Toolbar from "../../components/CodingToolbar";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import host from "../../global";
import { toast } from "react-toastify";

export default function CodingPlatform() {
  const { interview_id } = useParams();
  const navigate = useNavigate();
  const [problems, setProblems] = useState([1, 2, 3]);
  const [currentProblem, setCurrentProblem] = useState(null);
  const [code, setCode] = useState("");
  const [output, setOutput] = useState("");
  const [error, setError] = useState("");
  const [testResults, setTestResults] = useState([]);
  const [showTestCases, setShowTestCases] = useState(false);
  const [customInput, setCustomInput] = useState("");
  const [showCustomInput, setShowCustomInput] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [language, setLanguage] = useState("javascript");
  const separatorRef = useRef(null);
  const descriptionRef = useRef(null);
  const codeRef = useRef(null);
  const [showResults, setShowResults] = useState(false);
  const [codingTimeLimit, setCodingTimeLimit] = useState(90000);
  const [stopTest, setStopTest] = useState(false);
  const [coding_startTime, setCodingStartTime] = useState("");

  useEffect(() => {
    const getCandidateStatus = async () => {
      try {
        const startDateTime = new Date().toUTCString();
        const response = await axios.get(`${host}/get_coding_timelimit`, {
          params: {
            interview_id: interview_id,
            startDateTime: startDateTime,
          },
        });
        setCodingStartTime(new Date(response.data.coding_start_time));
        const timeLimitInSeconds = response.data.coding_time_limit * 60;
        const startTime = new Date(response.data.coding_start_time);
        const startTimeInSeconds = Math.floor(startTime.getTime() / 1000);
        const currentTimeInSeconds = Math.floor(Date.now() / 1000);
        const elapsedSeconds = currentTimeInSeconds - startTimeInSeconds;
        const remainingSeconds = timeLimitInSeconds - elapsedSeconds;
        setCodingTimeLimit(remainingSeconds);
        if (response.data.coding_status === "Complete") {
          setStopTest(true);
        }
      } catch (error) {
        toast.error("Failed to fetch candidate status");
        console.error(error);
      }
    };
    getCandidateStatus();
  }, [interview_id]);

  const handleProblemClick = (id) => {
    const problem = problems.find((p) => p.id === id);
    setCurrentProblem(problem);
    console.log(problem.boilerplate_code[language]);
    setCode(
      localStorage.getItem(`${language}_${problem.id}`) ||
        problem.boilerplate_code[language]
    );
    setShowTestCases(false);
    setError("");
  };

  useEffect(() => {
    axios
      .get(`${host}/api/problems/${interview_id}`)
      .then((response) => {
        setProblems(response.data);
        if (response.data.length > 0) setCurrentProblem(response.data[0]);
      })
      .catch((err) => console.error("Failed to fetch problems:", err));
  }, [interview_id]);

  useEffect(() => {
    const saveCodeToLocalStorage = debounce(() => {
      if (currentProblem) {
        localStorage.setItem(`${language}_${currentProblem.id}`, code);
      }
    }, 4000);

    saveCodeToLocalStorage();

    return () => {
      saveCodeToLocalStorage.cancel();
    };
  }, [code, currentProblem,language]);

  useEffect(() => {
    if (currentProblem) {
      setCode(
        localStorage.getItem(`${language}_${currentProblem.id}`) ||
          currentProblem.boilerplate_code[language]
      );
    }
  }, [language, currentProblem]);

  const handleMouseDown = (e) => {
    e.preventDefault();
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleFinalSubmit = async () => {
    if (!window.confirm("Are you sure you want to submit your code?")) return;

    setIsLoading(true);
    setShowTestCases(false);
    setError("");

    try {
      await axios.post(
        `${host}/api/problems/${currentProblem.id}/submit_final_test_cases`,
        { code, language: language, interview_id }
      );
      toast.success(
        "Your code has been submitted for evaluation. You can proceed to the next question or End Test"
      );
      setShowCustomInput(false);
    } catch {
      console.error("Failed to submit final code");
      setError("Error: Failed to submit final code");
    } finally {
      setIsLoading(false);
    }
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours > 0 ? String(hours).padStart(2, '0') + ':' : ''}${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };  

  const EndTest = async () => {
    const startTimeInSeconds = Math.floor(coding_startTime.getTime() / 1000);
    const currentTimeInSeconds = Math.floor(Date.now() / 1000);
    const elapsedSeconds = currentTimeInSeconds - startTimeInSeconds;
    const timeTaken = formatTime(elapsedSeconds);
    const problemIds = problems.map((p) => p.id);
    const payload = {
      problem_ids: problemIds,
      time_taken: timeTaken
  };
  console.log("Payload:", JSON.stringify(payload));

      try {
      await fetch(`${host}/save_coding_test/${interview_id}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });
  
      localStorage.clear();
      problems.forEach((p) => localStorage.removeItem(`${language}_${p.id}`));
      toast.success("Test submitted successfully!");
      setTimeout(() => {
        navigate(`/candidate/${interview_id}/testhomepage`);
      }, 4000);
    } catch (error) {
      console.error("Error ending the test:", error);
    }
  };  

  const handleMouseMove = (e) => {
    if (separatorRef.current && descriptionRef.current && codeRef.current) {
      const newPosition = e.clientX;
      const containerWidth = separatorRef.current.parentElement.offsetWidth;
      const percentage = (newPosition / containerWidth) * 100;
      descriptionRef.current.style.width = `${percentage}%`;
      codeRef.current.style.width = `${100 - percentage}%`;
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  const handleRun = () => {
    setShowResults(true);
    setIsLoading(true);
    setOutput("");
    setError("");
    setTestResults([]);
    setShowTestCases(false);
    setShowCustomInput(false);

    axios
      .post(`${host}/api/problems/${currentProblem.id}/submit`, {
        code,
        language: language,
        input: customInput,
      })
      .then(({ data }) => {
        setError(data.compile_output || data.stderr);
        setOutput(data.stdout || "");
      })
      .catch(() => setError("Error: Failed to execute code"))
      .finally(() => setIsLoading(false));
  };

  const handleRunTestCases = async () => {
    setShowResults(true);
    if (!currentProblem?.test_cases) return;

    setIsLoading(true);
    try {
      const { data: results } = await axios.post(
        `${host}/api/problems/${currentProblem.id}/run_test_case`,
        {
          code,
          language: language,
          input: "",
        }
      );

      setTestResults(
        results.map((result, i) => ({
          index: i + 1,
          input: currentProblem.test_cases[i]?.input || "Unknown",
          desiredOutput: currentProblem.test_cases[i]?.output || "Unknown",
          yourOutput: result.yourOutput,
          status: result.status_message,
        }))
      );

      setShowTestCases(true);
      setShowCustomInput(false);
    } catch {
      console.error("Failed to run test cases");
    } finally {
      setIsLoading(false);
    }
  };

  if (stopTest) {
    return (
      <div className='stoptestpage'>
        <div id='stoptest'>
          <h2>Test For This Candidate ID Is Already Conducted</h2>
          <h3>Only One Attempt Is Allowed</h3>
          <h3>You Can Close This Window Now</h3>
        </div>
      </div>
    );
  }

  return (
    <>
      <Timer timeLimit={codingTimeLimit} onTimeUp={EndTest} />
      {currentProblem && (
        <div
          className="flex h-screen bg-gray-900 text-white"
          style={{ height: "94vh", overflowY: "hidden" }}
        >
          <div
            className="w-16 bg-gray-800 flex flex-col items-center"
            style={{ paddingTop: "10px" }}
          >
            <Toolbar
              problems={problems}
              interview_id={interview_id}
              onProblemClick={handleProblemClick}
              currentProblemId={currentProblem.id}
            />
          </div>
          <div className="bg-gray-600" style={{ width: "3px" }} />
          <div
            ref={descriptionRef}
            className="w-1/2 p-4 overflow-auto bg-gray-800"
          >
            <h2 className="text-xl font-bold mb-4">
              {currentProblem.description}
            </h2>

            {currentProblem.input_format && (
              <p>Input: {currentProblem.input_format}</p>
            )}
            {currentProblem.output_format && (
              <p>Output: {currentProblem.output_format}</p>
            )}
            {currentProblem.constraints && (
              <p>Constraints: {currentProblem.constraints}</p>
            )}

            {currentProblem.examples?.length > 0 && (
              <div>
                <h3>Examples:</h3>
                <ol>
                  {currentProblem.examples.map((example, index) => (
                    <li key={index}>
                      <strong>Input:</strong> <pre>{example.input}</pre>
                      <strong>Output:</strong> <pre>{example.output}</pre>
                      {example.explanation && (
                        <>
                          <strong>Explanation:</strong>{" "}
                          <pre>{example.explanation}</pre>
                        </>
                      )}
                    </li>
                  ))}
                </ol>
              </div>
            )}
          </div>
          <div
            ref={separatorRef}
            className="relative flex items-center justify-center w-1 bg-gray-600 cursor-col-resize"
            onMouseDown={handleMouseDown}
          >
            <div className="w-4 h-8 bg-gray-400 rounded-full" />
          </div>
          <div
            ref={codeRef}
            className="w-1/2 flex flex-col"
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <div className="flex justify-between items-center p-2 bg-gray-700">
              <h2 className="text-xl font-bold">Code</h2>
              <div className="flex gap-4">
                <select
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                  className="w-[180px] p-2 bg-gray-700 text-white border border-gray-500 rounded"
                >
                  <option value="javascript">JavaScript</option>
                  <option value="python">Python</option>
                  <option value="java">Java</option>
                  <option value="cpp">C++</option>
                  <option value="c">C</option>
                  <option value="go">Go</option>
                  <option value="cs">C#</option>
                </select>
                <button className="p-2 bg-red-500 text-white rounded" onClick={EndTest}>
                  End Test
                </button>
              </div>
            </div>
            <div
              style={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
              }}
            >
              <Editor
                theme="vs-dark"
                language={language.toLowerCase()}
                value={localStorage.getItem(`${language}_${currentProblem.id}`)}
                onChange={(value) => setCode(value)}
                height="100%"
              />
            </div>
            <div className="p-2 bg-gray-700">
              <button
                style={{ background: "#1f2937", borderRadius: "8px" }}
                onClick={() => setShowCustomInput(!showCustomInput)}
                className="mb-2 p-2 bg-blue-500 text-white rounded w-full flex items-center justify-between"
              >
                Custom Inputs
                <span className="bg-[#374151] p-2 rounded-xl">
                  {showCustomInput ? <FaChevronUp /> : <FaChevronDown />}
                </span>
              </button>
              {showCustomInput && (
                <textarea
                  value={customInput}
                  onChange={(e) => setCustomInput(e.target.value)}
                  placeholder="Enter custom input..."
                  className="mb-2 p-2 bg-gray-600 text-white w-full resize-none rounded-xl"
                />
              )}
              <button
                style={{ background: "#1f2937", borderRadius: "8px" }}
                onClick={() => setShowResults(!showResults)}
                className="mb-2 p-2 bg-blue-500 text-white rounded w-full flex items-center justify-between"
              >
                Results
                <span className="bg-[#374151] p-2 rounded-xl">
                  {showResults ? <FaChevronUp /> : <FaChevronDown />}
                </span>
              </button>
              {showResults && (
                <div
                  className="mb-2 p-2 bg-gray-600 text-white w-full resize-none rounded-xl"
                  style={{
                    maxHeight: "200px",
                    minHeight: "50px",
                    overflowY: "scroll",
                    transform: showResults
                      ? "translateY(0)"
                      : "translateY(100%)",
                    transition: "transform 0.3s ease",
                  }}
                >
                  {output && (
                    <div className="output mb-4">
                      <h2 className="text-xl font-bold">Output:</h2>
                      <pre>{output}</pre>
                    </div>
                  )}

                  {showTestCases && (
                    <div className="test-cases mb-4">
                      <h2 className="text-xl font-bold">Test Case Results:</h2>
                      <ul>
                        {testResults.map((result) => (
                          <li key={result.index} className="mb-2">
                            <strong>
                              Test Case {result.index} ({result.status})
                            </strong>
                            <div>
                              <strong>Your Output:</strong>
                              <pre>{result.yourOutput}</pre>
                            </div>
                            <div>
                              <strong>Expected Output:</strong>
                              <pre>{result.desiredOutput}</pre>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  {error && !isLoading && (
                    <div className="error p-2 bg-red-500 text-white rounded">
                      <h2 className="text-xl font-bold">Error:</h2>
                      <pre>{error}</pre>
                    </div>
                  )}
                </div>
              )}

              <div className="flex justify-between w-full">
                <div className="flex space-x-2">
                  <button
                    className="p-2 bg-blue-500 text-white rounded"
                    onClick={handleRun}
                  >
                    Run
                  </button>
                  <button
                    className="p-2 bg-blue-500 text-white rounded"
                    onClick={handleRunTestCases}
                  >
                    Run Test Cases
                  </button>
                </div>
                <button className="p-2 bg-blue-500 text-white rounded" onClick={handleFinalSubmit}>
                  Save Code
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
