import React, { useEffect, useState } from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import host from '../../global';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { useMediaQuery } from "@mui/material";
import Cookies from 'js-cookie'
import axios from 'axios';
const HrProfile = () => {
  const [hrProfiles, setHrProfiles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const isSmallScreen = useMediaQuery("(max-width: 1100px)");
  const accessToken = Cookies.get('accessToken');

  const fetchHrProfiles = async () => {
    try {
      setIsLoading(true)
      const response = await fetch(`${host}/orgadmin/get_hrprofiles`,{
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();
      setHrProfiles(data);
      setIsLoading(false)
    } catch (error) {
      console.error('Error fetching HR profiles:', error);
      setIsLoading(false)
    }
  };

  useEffect(() => {
    fetchHrProfiles();    // eslint-disable-next-line
  }, []);

  const handleAddHR = () => {
    setModalTitle('Add HR');
    setModalContent(
      <form onSubmit={handleAddHRSubmit}>
        <TextField
          label="Name"
          name="name"
          required
          fullWidth
          margin="normal"
          variant="outlined"
          sx={{ mt: 2 }}
        />
        <TextField
          label="Email"
          name="email"
          type="email"
          required
          fullWidth
          margin="normal"
          variant="outlined"
          sx={{ mt: 2 }}
        />
        <TextField
          label="Contact Number"
          name="contactNumber"
          required
          fullWidth
          margin="normal"
          variant="outlined"
          sx={{ mt: 2 }}
        />
        <TextField
          label="Department"
          name="department"
          required
          fullWidth
          margin="normal"
          variant="outlined"
          sx={{ mt: 2 }}
        />
        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
          Add HR
        </Button>
      </form>
    );
    setShowModal(true);
  };

  const handleEditHR = (hrProfile) => {
    setModalTitle('Edit HR');
    setModalContent(
      <form onSubmit={(event) => handleEditHRSubmit(event, hrProfile.hrId)}>
        <TextField
          label="Name"
          name="name"
          defaultValue={hrProfile.hrName}
          required
          fullWidth
          margin="normal"
          variant="outlined"
          sx={{ mt: 2 }}
        />
        <TextField
          label="Email"
          name="email"
          type="email"
          defaultValue={hrProfile.hremail}
          required
          fullWidth
          margin="normal"
          variant="outlined"
          sx={{ mt: 2 }}
        />
        <TextField
          label="Contact Number"
          name="contactNumber"
          defaultValue={hrProfile.contactNumber}
          required
          fullWidth
          margin="normal"
          variant="outlined"
          sx={{ mt: 2 }}
        />
        <TextField
          label="Department"
          name="department"
          defaultValue={hrProfile.HRDepartment}
          required
          fullWidth
          margin="normal"
          variant="outlined"
          sx={{ mt: 2 }}
        />
        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
          Save Changes
        </Button>
      </form>
    );
    setShowModal(true);
  };

  const handleAddHRSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const newHR = {
      hrName: formData.get('name'),
      hremail: formData.get('email'),
      contactNumber: formData.get('contactNumber'),
      HRDepartment: formData.get('department')
    };
    console.log(newHR)
    try {
      const response = await fetch(`${host}/orgadmin/addhrprofile`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(newHR)
      });
      await response.json();
      setShowModal(false);
      await fetchHrProfiles();
    } catch (error) {
      console.error('Error adding HR profile:', error);
    }
  };

  const handleEditHRSubmit = async (event, hrId) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const updatedHR = {
      hrName: formData.get('name'),
      hremail: formData.get('email'),
      contactNumber: formData.get('contactNumber'),
      HRDepartment: formData.get('department')
    };
    try {
      await axios.put(`${host}/orgadmin/updatehrprofile`, updatedHR, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          hrId: hrId,
        },
      });
    
      alert('HR Profile Updated Successfully');
      setShowModal(false);
      await fetchHrProfiles();
    } catch (error) {
      console.error('Error updating HR profile:', error);
    }
  };

  if (isLoading) {
    return <div class="loader">
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
</div>
  }

  return (
        <Authenticator>
          <div className="hr-profiles">
          <Button 
    variant="contained" 
    color="primary" 
    onClick={handleAddHR} 
    style={{ float: 'right', marginBottom: '16px' }}
  >
    Add HR
  </Button>
            <h1>HR Profiles</h1>
            <div className="table-container">
              <div className="table-header">
                <Table>
                  <Thead>
                    <Tr>
                      <Th>HR Name</Th>
                      {isSmallScreen ? (
                        <Th>Email</Th>
                      ):(
                        <Th style={{ width: '400px' }}>Email</Th>
                      )}
                      <Th>Contact</Th>
                      <Th>Department</Th>
                      <Th>Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {hrProfiles.length > 0 ? (
                      hrProfiles.map((hr) => (
                        <Tr key={hr.hrId}>
                          <Td style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{hr.hrName}</Td>
                          {isSmallScreen ? (
                          <Td style={{ whiteSpace: 'normal', wordWrap: 'break-word'}}>{hr.hremail}</Td>
                        ):(
                        <Td style={{ whiteSpace: 'normal', wordWrap: 'break-word', width: '400px' }}>{hr.hremail}</Td>
                      )}
                          <Td>{hr.contactNumber}</Td>
                          <Td>{hr.HRDepartment}</Td>
                          <Td>
                            <Button variant="contained" onClick={() => handleEditHR(hr)}>Edit HR</Button>
                          </Td>
                        </Tr>
                      ))
                    ) : (
                      <Tr>
                        <Td colSpan="5">No HR profiles found.</Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </div>
            </div>
            {/* Material-UI Dialog for Modals */}
            <Dialog open={showModal} onClose={() => setShowModal(false)}>
              <DialogTitle>{modalTitle}</DialogTitle>
              <DialogContent>
                {modalContent}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setShowModal(false)} color="primary">
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Authenticator>
  );
};

export default HrProfile;
