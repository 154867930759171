import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button,IconButton } from "@mui/material";
import { AccountCircle, Logout } from "@mui/icons-material";
import host from "./../../global";
import "../../css/HRPortal.css";
import { signOut } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import { ArrowBack } from "@mui/icons-material";
import Cookies from 'js-cookie'
import { getCurrentUser } from "aws-amplify/auth";

const Sidebar = ({isOpen, toggleSidebar }) => {
  const [adminData, setAdminData] = useState([]);
  const navigate = useNavigate();
  const profile_photos_host = process.env.REACT_APP_PROFILE_PHOTOS_HOST;
  const accessToken = Cookies.get("accessToken")
  const [userId,setUserId] = useState('');

  useEffect(() => {
    const fetchProfileData = async() =>{
    try {
      const { userId } = await getCurrentUser();
      setUserId(userId);
      const response = await axios.get(`${host}/getadmindetails`,{
        headers:{
          Authorization: `Bearer ${accessToken}`
        }
      });
      setAdminData(response.data);
    } catch (error) {
      console.error('Error fetching Data', error);
    }
  }

  fetchProfileData();
  },[accessToken]);

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate("/");
    } catch (error) {
      console.error("Error signing out", error);
      alert("Error signing out");
    }
  };

  return (
    <aside className={`sidebar ${isOpen ? 'open' : ''}`}>
            {isOpen && 

      <div style={{display:'flex',justifyContent:'flex-start',marginBottom:'10px',marginLeft:'-10px'}}>

            <IconButton className="close-btn" onClick={toggleSidebar}>
            <ArrowBack />
          </IconButton>
          </div>
}
          <img src="/GenieHire-logo-black.png" alt="Logo" />
      <div className="sidebar-profile">
        {adminData.profile ? (
          <img src={`${profile_photos_host}/OrganizationAdmins/${userId}_${adminData.profile}`} alt="Profile" className="profile-avatar" />
        ) : (
          <AccountCircleRoundedIcon
            style={{
              width: "100px",
              height: "100px",
              borderRadius: "50%",
              fill:"#0073e6"
            }}
          />
        )}
        <h3 className="profile-name">{adminData.name}</h3>
        <p className="profile-role">Organization Admin</p>
      </div>
      <nav className="sidebar-nav">
        <Button
          startIcon={<AccountCircle />}
          onClick={() => navigate(`/org-admin/editprofile`)}
        >
          Profile
        </Button>

        {/* <Button
        startIcon={<Group />}
        onClick={() => navigate('/recent-activities')} 
      >
        Recent Activities
      </Button>

      <Button
        startIcon={<Settings />}
        onClick={() => navigate('/settings')} 
      >
        Settings
      </Button> */}
      </nav>
      <Button
        startIcon={<Logout />}
        onClick={handleSignOut}
        className="sidebar-logout"
      >
        Logout
      </Button>
    </aside>
  );
};

export default Sidebar;
