import React, { useState } from 'react';
import { Editor } from '@monaco-editor/react';

const HostCodingProblem = ({
  formData,
  setFormData,
  selectedLanguage,
  setSelectedLanguage,
  code,
  setCode,
  handleBoilerplateCodeChange,
  handleSubmit,
  handleExamplesChange,
  handleTestCasesChange,
  handleFinalTestCasesChange
}) => {

  const [isEditorVisible, setIsEditorVisible] = useState(false);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const toggleEditorVisibility = () => {
    setIsEditorVisible(prevState => !prevState);
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="createProblemForm createOwnProblem p-6 max-w-3xl mx-auto bg-white rounded-lg shadow-md space-y-6">
        <div>
          <label className="block text-lg font-semibold text-gray-700 mb-1">
            <h2>Title:</h2>
          </label>
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
            placeholder="Title of the problem"
            required
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
          />
        </div>
  
        <div>
          <label className="block text-lg font-semibold text-gray-700 mb-1">
            <h2>Description:</h2>
          </label>
          <textarea
            name="description"
            value={formData.description}
            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
            placeholder="Description of the problem"
            rows={1}
            required
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
          />
        </div>
  
        <div>
          <label className="block text-lg font-semibold text-gray-700 mb-1">
            <h2>Input Format:</h2>
          </label>
          <textarea
            name="input_format"
            value={formData.input_format}
            onChange={(e) => setFormData({ ...formData, input_format: e.target.value })}
            placeholder="Input Format"
            rows={1}
            required
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
          />
        </div>
  
        <div>
          <label className="block text-lg font-semibold text-gray-700 mb-1">
            <h2>Output Format:</h2>
          </label>
          <textarea
            name="output_format"
            value={formData.output_format}
            onChange={(e) => setFormData({ ...formData, output_format: e.target.value })}
            placeholder="Output Format"
            rows={1}
            required
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
          />
        </div>
  
        <div>
          <label className="block text-lg font-semibold text-gray-700 mb-1">
            <h2>Constraints:</h2>
          </label>
          <textarea
            name="constraints"
            value={formData.constraints}
            onChange={(e) => setFormData({ ...formData, constraints: e.target.value })}
            placeholder="Constraints"
            rows={1}
            required
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
          />
        </div>
  
        <div>
          <h2 className="text-xl font-semibold text-gray-800">Examples</h2>
          {formData.examples.map((example, index) => (
            <div key={index} className="example-item space-y-4 bg-gray-50 p-4 rounded-lg shadow-inner">
              <label className="block text-sm font-medium text-gray-700">Example Input:</label>
              <textarea
                name={`example_input_${index}`}
                value={example.input}
                onChange={(e) => handleExamplesChange(index, 'input', e.target.value)}
                placeholder="Example input"
                rows={1}
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              />
              <label className="block text-sm font-medium text-gray-700">Example Output:</label>
              <textarea
                name={`example_output_${index}`}
                value={example.output}
                onChange={(e) => handleExamplesChange(index, 'output', e.target.value)}
                placeholder="Example output"
                rows={1}
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              />
              <label className="block text-sm font-medium text-gray-700">Explanation:</label>
              <textarea
                name={`example_explanation_${index}`}
                value={example.explanation}
                onChange={(e) => handleExamplesChange(index, 'explanation', e.target.value)}
                placeholder="Explanation"
                rows={1}
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>
          ))}
          <button type="button" onClick={() => setFormData({ ...formData, examples: [...formData.examples, { input: '', output: '', explanation: '' }] })} className="mt-3 p-2 w-full bg-blue-500 text-white rounded-lg hover:bg-blue-600">
            Add Example
          </button>
        </div>
  
        <div>
          <h2 className="text-xl font-semibold text-gray-800">Test Cases</h2>
          {formData.test_cases.map((testcase, index) => (
            <div key={index} className="testcase-item space-y-4 bg-gray-50 p-4 rounded-lg shadow-inner">
              <input
                type="text"
                name={`test_input_${index}`}
                value={testcase.input}
                onChange={(e) => handleTestCasesChange(index, 'input', e.target.value)}
                placeholder="Test case input"
                required
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              />
              <input
                type="text"
                name={`test_output_${index}`}
                value={testcase.output}
                onChange={(e) => handleTestCasesChange(index, 'output', e.target.value)}
                placeholder="Expected output"
                required
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>
          ))}
          <button type="button" onClick={() => setFormData({ ...formData, test_cases: [...formData.test_cases, { input: '', output: '' }] })} className="mt-3 p-2 w-full bg-blue-500 text-white rounded-lg hover:bg-blue-600">
            Add Test Case
          </button>
        </div>
  
        <div>
          <h2 className="text-xl font-semibold text-gray-800">Final Test Cases</h2>
          {formData.final_test_cases.map((testcase, index) => (
            <div key={index} className="testcase-item space-y-4 bg-gray-50 p-4 rounded-lg shadow-inner">
              <input
                type="text"
                name={`final_test_input_${index}`}
                value={testcase.input}
                onChange={(e) => handleFinalTestCasesChange(index, 'input', e.target.value)}
                placeholder="Final test case input"
                required
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              />
              <input
                type="text"
                name={`final_test_output_${index}`}
                value={testcase.output}
                onChange={(e) => handleFinalTestCasesChange(index, 'output', e.target.value)}
                placeholder="Expected output"
                required
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              />
            </div>
          ))}
          <button type="button" onClick={() => setFormData({ ...formData, final_test_cases: [...formData.final_test_cases, { input: '', output: '' }] })} className="mt-3 p-2 w-full bg-blue-500 text-white rounded-lg hover:bg-blue-600">
            Add Final Test Case
          </button>
        </div>
  
        <div className="boilerplate-section">
          <button type="button" onClick={toggleEditorVisibility} className="w-full p-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 mb-4">
            {isEditorVisible ? 'Hide Boiler Plate' : 'Add Boiler Plate'}
          </button>
  
          {isEditorVisible && (
            <>
              <div className="testcase-item">
                <select id="language" value={selectedLanguage} onChange={(e) => setSelectedLanguage(e.target.value)} className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500">
                  <option value="javascript">JavaScript</option>
                  <option value="python">Python</option>
                  <option value="java">Java</option>
                  <option value="cpp">C++</option>
                  <option value="c">C</option>
                  <option value="go">Go</option>
                  <option value="cs">C#</option>
                </select>
              </div>
  
              <div className="editor bg-gray-100 p-4 rounded-lg shadow-inner mt-4">
                <Editor
                  height="60vh"
                  language={selectedLanguage}
                  value={code}
                  onChange={(value) => {
                    setCode(value);
                    handleBoilerplateCodeChange(selectedLanguage, value);
                  }}
                />
              </div>
            </>
          )}
        </div>
  
        <button type="submit" onClick={handleScrollToTop} className="submitButton w-full p-3 bg-green-500 text-white rounded-lg hover:bg-green-600 font-semibold">
          Create Problem
        </button>
      </form>
    </>
  );  
};

export default HostCodingProblem;