import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import host from "../../global";
import Modal from "react-modal";
import { Button } from "@mui/material";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Dialog, DialogTitle, DialogContent,DialogActions } from "@mui/material";

Modal.setAppElement("#root");

function App() {
  const [shortlisted, setShortlisted] = useState([]);
  const [retrofit, setRetrofit] = useState([]);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [invalidData, setInvalidData] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [newEmail, setNewEmail] = useState("");
  const { upload_id } = useParams();
  const navigate = useNavigate();
  const [nameFilter, setNameFilter] = useState("");
  const [minScoreFilter, setMinScoreFilter] = useState(0);
  const [maxScoreFilter, setMaxScoreFilter] = useState(100);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentReport, setCurrentReport] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const accessToken = Cookies.get("accessToken");

  useEffect(() => {
    const fetchCandidates = async () => {
      try {
        const response = await axios.get(`${host}/resumes`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            upload_id: upload_id,
          },
        });

        const fetchedCandidates = response.data.data;

        if (Array.isArray(fetchedCandidates)) {
          let filteredCandidates = fetchedCandidates;

          if (nameFilter) {
            filteredCandidates = filteredCandidates.filter((candidate) =>
              candidate.name.toLowerCase().includes(nameFilter.toLowerCase())
            );
          }

          if (minScoreFilter > 0) {
            filteredCandidates = filteredCandidates.filter(
              (candidate) => candidate.score >= minScoreFilter
            );
          }

          if (maxScoreFilter < 100) {
            filteredCandidates = filteredCandidates.filter(
              (candidate) => candidate.score <= maxScoreFilter
            );
          }

          const sortedCandidates = filteredCandidates.sort(
            (a, b) => b.score - a.score
          );

          setShortlisted(sortedCandidates.filter((c) => c.score >= 60));
          setRetrofit(sortedCandidates.filter((c) => c.score < 60 && c.emails));
          setInvalidData(
            sortedCandidates.filter((c) => !c.emails || c.score === null)
          );
          setIsLoading(false);
        } else {
          console.error("Unexpected data format:", fetchedCandidates);
        }
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          const detail = error.response.data.detail;
          if (status === 401) {
            await new Promise((resolve) => {
              Cookies.remove("accessToken");
              resolve();
            });
            toast.error(`Unauthorized: ${detail}`);
          } else if (status === 404) {
            toast.warning(`Not Found: ${detail}`);
          } else {
            toast.error(`Error: ${detail}`);
          }
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
      }
    };

    fetchCandidates();
  }, [upload_id,nameFilter,minScoreFilter,maxScoreFilter,accessToken,navigate,]);

  const scheduleInterviewForAll = async () => {
    try {
      await axios.post(
        `${host}/schedule_interviews`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            upload_id: upload_id,
          },
        }
      );
      toast.success("Interviews have been scheduled for all candidates.");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  };

  const handleReportClick = (candidate) => {
    setCurrentReport(candidate.report);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentReport(null);
  };

  const validateData = (email) => {
    console.log(`Validating data for candidate with email: ${email}`);
  };

  const startEditingEmail = (index, currentEmail) => {
    setEditingIndex(index);
    setNewEmail(currentEmail);
  };

  const saveEmailChange = async (index) => {
    const updatedCandidates = shortlisted.map((candidate, idx) =>
      idx === index ? { ...candidate, emails: newEmail } : candidate
    );
    setShortlisted(updatedCandidates);
    setEditingIndex(null);
    setNewEmail("");

    try {
      await axios.put(
        `${host}/updatecandidate`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            upload_id: upload_id,
            email: newEmail,
            index: index,
          },
        }
      );

      toast.success("Email updated successfully!");
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  };

  const handleCheckboxChange = (candidate) => {
    setSelectedCandidates((prevSelected) => {
      if (prevSelected.includes(candidate)) {
        return prevSelected.filter((c) => c !== candidate);
      } else {
        return [...prevSelected, candidate];
      }
    });
  };

  const deleteSelectedCandidates = async () => {
    const selectedIndices = selectedCandidates.map(
      (candidate) => candidate.index_number
    );

    try {
      await axios.delete(`${host}/deletecandidateBulkUpload`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          upload_id: upload_id,
          indices: selectedIndices.join(","),
        },
      });

      toast.success("Selected candidates deleted successfully");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        const detail = error.response.data.detail;
        if (status === 401) {
          await new Promise((resolve) => {
            Cookies.remove("accessToken");
            resolve();
          });
          toast.error(`Unauthorized: ${detail}`);
        } else if (status === 404) {
          toast.warning(`Not Found: ${detail}`);
        } else {
          toast.error(`Error: ${detail}`);
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  };

  const shortlistSelectedCandidates = () => {
    const candidatesToShortlist = selectedCandidates.filter(
      (candidate) => candidate.score < 60
    );

    setRetrofit(retrofit.filter((c) => !candidatesToShortlist.includes(c)));
    setShortlisted([...shortlisted, ...candidatesToShortlist]);
    setSelectedCandidates([]);
  };

  if (isLoading) {
    return <div class="loader">
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
</div>
  }

  return (
    <Authenticator>
      <div className="company-admin-container">
        <div className="content">
          <div className="maincontent">
            <div className={"showFull"}>
              <h1>Candidate Management</h1>
              <div className="filters">
                <div className="filter-group">
                  <label htmlFor="nameFilter">Name:</label>
                  <input
                    id="nameFilter"
                    type="text"
                    placeholder="Search by name"
                    value={nameFilter}
                    onChange={(e) => setNameFilter(e.target.value) || ""}
                  />
                </div>
                <div className="filter-group">
                  <label htmlFor="minScoreFilter">Min Score:</label>
                  <input
                    id="minScoreFilter"
                    type="number"
                    placeholder="0"
                    value={minScoreFilter}
                    onChange={(e) =>
                      setMinScoreFilter(Number(e.target.value) || 0)
                    }
                  />
                </div>
                <div className="filter-group">
                  <label htmlFor="maxScoreFilter">Max Score:</label>
                  <input
                    id="maxScoreFilter"
                    type="number"
                    placeholder="100"
                    value={maxScoreFilter}
                    onChange={(e) =>
                      setMaxScoreFilter(Number(e.target.value)) || 0
                    }
                  />
                </div>
                <div className="filter-group">
                  <label htmlFor="maxScoreFilter"></label>
                  <Button
                    className="app-button"
                    style={{ marginTop: "23px" }}
                    onClick={() => {
                      setNameFilter("");
                      setMinScoreFilter(0);
                      setMaxScoreFilter(100);
                    }}
                  >
                    Clear Filters
                  </Button>
                </div>
              </div>
              {/* </div> */}
              {(nameFilter.length > 0 ||
                minScoreFilter.length > 0 ||
                maxScoreFilter.length > 0) && (
                <div className="filter-summary">
                  <h4>Filters applied:</h4>
                  <ul>
                    {nameFilter && <li>Name: {nameFilter}</li>}
                    {minScoreFilter > 0 && <li>Min Score: {minScoreFilter}</li>}
                    {maxScoreFilter < 100 && (
                      <li>Max Score: {maxScoreFilter}</li>
                    )}
                  </ul>
                </div>
              )}
              {shortlisted.length > 0 && (
                <div className="table-container">
                  <div className="table-header">
                    <h2>Shortlisted Candidates</h2>
                    <table className="responsive-table">
                      <thead>
                        <tr>
                          <th style={{ width: "80px" }}>Sr. No</th>
                          <th style={{ width: "150px" }}>Name</th>
                          <th style={{ width: "300px" }}>Email</th>
                          <th style={{ textAlign: "center", width: "80px" }}>
                            Score
                          </th>
                          <th style={{ width: "100px" }}>Report</th>
                          <th style={{ width: "100px" }}>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {shortlisted.map((candidate, index) => (
                          <tr key={index}>
                            <td style={{ width: "80px" }}>{index + 1}</td>
                            <td style={{ width: "150px" }}>{candidate.name}</td>
                            <td style={{ width: "300px" }}>
                              {editingIndex === index ? (
                                <input
                                  type="text"
                                  value={newEmail}
                                  onChange={(e) => setNewEmail(e.target.value)}
                                />
                              ) : (
                                candidate.emails
                              )}
                            </td>
                            <td style={{ textAlign: "center", width: "80px" }}>
                              {candidate.score}
                            </td>
                            <td style={{ width: "100px" }}>
                              <div
                                className="tooltip-icon"
                                onClick={() => handleReportClick(candidate)}
                              >
                                <VisibilityRoundedIcon
                                  style={{ height: "30px", width: "30px" }}
                                />
                                <span className="tooltip-text">
                                  View Report
                                </span>
                              </div>
                            </td>

                            <td style={{ width: "100px" }}>
                              {editingIndex === index ? (
                                <Button
                                  className="app-button"
                                  onClick={() => saveEmailChange(index)}
                                >
                                  Save
                                </Button>
                              ) : (
                                <Button
                                  className="app-button"
                                  onClick={() =>
                                    startEditingEmail(index, candidate.emails)
                                  }
                                >
                                  Edit
                                </Button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    {/* Conditionally render the Schedule Interviews for All button */}
                    {invalidData.length === 0 && retrofit.length === 0 && (
                      <Button
                        className="app-button"
                        onClick={scheduleInterviewForAll}
                      >
                        Schedule Interviews for All
                      </Button>
                    )}

                    <Dialog
                      open={modalIsOpen}
                      onClose={closeModal}
                    >
                      <DialogTitle>
                        Candidate Report
                      </DialogTitle>
                      <DialogContent dividers>
                        {currentReport ? (
                          <p>{currentReport}</p>
                        ) : (
                          <p>No report available</p>
                        )}
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={closeModal} color="primary">
                          Close
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                </div>
              )}

              {retrofit.length > 0 && (
                <div className="table-container">
                  <div className="table-header">
                    <h2>RetroFit Candidates</h2>
                    {selectedCandidates.length > 0 && (
                      <div style={{ display: "flex", gap: "10px" }}>
                        <Button
                          className="app-button"
                          onClick={deleteSelectedCandidates}
                        >
                          Delete Selected Candidates
                        </Button>
                        <Button
                          className="app-button"
                          onClick={shortlistSelectedCandidates}
                        >
                          Shortlist Selected Candidates
                        </Button>
                      </div>
                    )}
                    <table className="responsive-table">
                      <thead>
                        <tr>
                          <th style={{ width: "80px" }}>Sr. No</th>
                          <th style={{ width: "150px" }}>Name</th>
                          <th style={{ width: "300px" }}>Email</th>
                          <th style={{ textAlign: "center", width: "80px" }}>
                            Score
                          </th>
                          <th style={{ width: "100px" }}>Report</th>
                          <th style={{ width: "100px" }}>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {retrofit.map((candidate, index) => (
                          <tr key={index}>
                            <td style={{ width: "80px" }}>{index + 1}</td>
                            <td style={{ width: "150px" }}>{candidate.name}</td>
                            <td style={{ width: "300px" }}>
                              {candidate.emails}
                            </td>
                            <td style={{ textAlign: "center", width: "80px" }}>
                              {candidate.score}
                            </td>
                            <td style={{ width: "100px" }}>
                              <div
                                className="tooltip-icon"
                                onClick={() => handleReportClick(candidate)}
                              >
                                <VisibilityRoundedIcon
                                  style={{ height: "30px", width: "30px" }}
                                />
                                <span className="tooltip-text">
                                  View Report
                                </span>
                              </div>
                            </td>

                            <td style={{ width: "100px" }}>
                              <input
                                type="checkbox"
                                checked={selectedCandidates.includes(candidate)}
                                onChange={() => handleCheckboxChange(candidate)}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              {invalidData.length > 0 && (
                <div className="table-container">
                  <div className="table-header">
                    <h2>Invalid Data Candidates</h2>
                    <table className="responsive-table">
                      <thead>
                        <tr>
                          <th style={{ width: "80px" }}>Sr. No</th>
                          <th style={{ width: "150px" }}>Name</th>
                          <th style={{ width: "300px" }}>Email</th>
                          <th style={{ textAlign: "center", width: "80px" }}>
                            Score
                          </th>
                          <th style={{ width: "100px" }}>Report</th>
                          <th style={{ width: "100px" }}>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {invalidData.map((candidate, index) => (
                          <tr key={index}>
                            <td style={{ width: "80px" }}>{index + 1}</td>
                            <td style={{ width: "150px" }}>{candidate.name}</td>
                            <td style={{ width: "300px" }}>
                              {candidate.emails}
                            </td>
                            <td style={{ textAlign: "center", width: "80px" }}>
                              {candidate.score}
                            </td>
                            <td style={{ width: "100px" }}>
                              <div
                                className="tooltip-icon"
                                onClick={() => handleReportClick(candidate)}
                              >
                                <VisibilityRoundedIcon
                                  style={{ height: "30px", width: "30px" }}
                                />
                                <span className="tooltip-text">
                                  View Report
                                </span>
                              </div>
                            </td>
                            <td style={{ width: "100px" }}>
                              <Button
                                className="app-button"
                                onClick={() => validateData(candidate.emails)}
                              >
                                Validate
                              </Button>
                              <Button
                                className="app-button"
                                onClick={() =>
                                  deleteSelectedCandidates(
                                    candidate.index_number
                                  )
                                }
                              >
                                Delete
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Authenticator>
  );
}

export default App;
